import React, { useEffect, useMemo, useState } from "react";
import { List as ListMui, IconButton, ListItemButton, ListItemText, ListSubheader, Typography, ListItem } from "@mui/material";
import { useAppContext } from "../../App";
import * as actions from "./actions";
import "./Utils.css";

export const dataToView = ({item, label, data, level = 0, isAction = false}) => {
  if(isAction){
    if(Array.isArray(data)){
      return (
        <p>
          {
            data.map(action => {
              const ActionComponent = action && actions[action.key];
              return (
                <>
                  {ActionComponent && action?.label && <span className="text-capitalize">{action.label}</span>}
                  {ActionComponent && <ActionComponent params={action?.params} iconButton/>}
                </>
              )
            })
          }
        </p>
      )
    }else{
      const ActionComponent = data && actions[data.key];
      return (
        <p>
          {ActionComponent && data?.label && <span className="text-capitalize"><b>{data.label}:</b></span>}
          {ActionComponent && <ActionComponent params={data?.params} iconButton/>}
        </p>
      )
    }
  }
  if(data === null){
    return  label ? <p><span className="text-capitalize"><b>{label}:</b></span> sin datos</p> : "";
  }
  if(typeof data === "object" && !Array.isArray(data)){ //si es un objeto
    return Object.keys(data).map(key => dataToView({item, isAction: key==="_action_", label: key, data: data[key], level}))
  }
  if(typeof data === "object" && Array.isArray(data)){ //si es un array
    const columns = data.length > 0 && level < 2 
      ? Object.keys(data[0]).map(key => ({ 
        key:`item${key}`, 
        className: "text-capitalize", 
        label: key === "_action_" ? "Acciones" : key.replaceAll("_", " ")
      }))
      : [];
    
    const rows = data.map((item, index) => ({
      key: `item${index}`,
      cells: Object.keys(item).map(itemKey => ({
        key: itemKey,
        content: dataToView({item, isAction: itemKey==="_action_", label: "", data: item[itemKey], level: level + 1})
      }))
    }))

    return data.length === 0 
      ? <>{label ? <p><span className="text-capitalize"><b>{label}:</b></span> sin datos</p> : "sin datos" }</>
      : <>
        {label && <span className="text-capitalize"><b>{label.replaceAll("_", " ")}:</b></span>}
        <Table columns={columns} rows={rows} className={level === 0 ? "table table-striped table-hover table-index-" : "table table-sm"}/>
      </>
  }
  //otro valor
  return label ? <p key={label}><span className="text-capitalize"><b>{label}:</b></span> {data}</p> : (data || '-');
}


const Table = ({columns, rows, className}) => {

  const { smMode } = useAppContext();
  if(smMode) {
    return <List columns={columns} rows={rows}/>; 
  } 
  return (
    <table className={`table-utils ${className}`}>
      <thead>
        <tr>
          {columns.map(column =>          
            <th key={column.key} className={column.className}>
              {column.label}
            </th>
          )}
        </tr>
      </thead>   
      <tbody>
        {rows.map((row) =>
          <tr key={row.key}>
            {row.cells.map(cell =>
              <td key={cell.key} style={{verticalAlign: "midle"}}>
                <CellContent content={cell.content} />
              </td>
            )}
          </tr>                            
        )}
      </tbody>
    </table>
  )
}

const CellContent = ({content}) => {
  const [processedContent, SetProcessedContent] = useState();
  useEffect(()=>{
    if (typeof content === "string" ){
      let style = {width: "100%", height: "100%"};
      let newContent = content;
      if(newContent.startsWith(">>") && newContent.endsWith(">>")){
        newContent = newContent.substring(2, newContent.length-2);
        style.textAlign = "right";
      }
      if(newContent.startsWith(">>") && newContent.endsWith("<<") ){
        newContent = newContent.substring(2, newContent.length-2);
        style.textAlign = "center";
      }
      SetProcessedContent(<div style={style}>{newContent}</div>);
      return;
    }
    SetProcessedContent(content);
  }, [content])

  return !!processedContent 
    ? (typeof processedContent === "string" 
      ? <div dangerouslySetInnerHTML={{__html: processedContent}}></div>
      : processedContent)
    : <></>;
}
const List = ({columns, rows}) => {

  return (
    <ListMui
      sx={{ width: '100%', bgcolor: 'background.paper' }}
    >
      {rows && rows.map((row, index) => {
        const values = columns.slice(0, 3)
          .map((column, index) => ({caption: column.label, text: row.cells[index].content}));
        const primaryText = <Typography noWrap>{ values[0] && values[0].text }</Typography>;
        const secondaryText = values[1] && `${values[1].caption}: ${values[1].text}`;
        const actionsColumn = row.cells.find(cell => cell.key === "_action_");
        const actions = actionsColumn && actionsColumn.content;
        return (
          <ListItemButton>
            <ListItemText  id="switch-list-label-bluetooth" primary={primaryText} secondary={secondaryText}/>
            {actions}
          </ListItemButton>
        )
      })}
      {(!rows || rows.length === 0) && 
        <ListItem>
          <ListItemText id="switch-list-label" primary={"No hay datos para mostrar"} />
        </ListItem>
      }


    </ListMui>
  )
}