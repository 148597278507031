import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { CancelIcon } from "../../../icons";

export const RechazarDesignacion = ({params}) => {
  const { setShowDesignacionRechazo } = useAbmContext();
  const { claims: { rol } } = useAppContext();

  if(!authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR]})){
    return <></>
  }
  const enabled = rol === roles.LIQUIDADOR || rol === roles.DIRECTOR;

  const handleClick = () => {
    setShowDesignacionRechazo(true);
  }
  return <ActionButton iconButton enabled={enabled} Icon={CancelIcon} onClick={handleClick}/>
}
