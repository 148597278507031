import { useAppContext } from "../../../App";
import { authorize } from "../../../Core";
import { roles } from "../../../config/constants"; 
import { useAbmContext } from "../../../views/abm/useAbmComponent";
import { ActionButton } from "../../helpers";
import { LockOutlinedIcon } from "../../icons";
import { useLock } from "../../hooks";
import { ModalObservacion } from "../../modals";
import { useMemo, useState } from "react";

export const Lock = ({params}) => {

  const { loadDetails, selectedItem, abmActions} = useAbmContext();
  const { lock } = useLock({entity: params.entity || abmActions.entity, ...(params || {})});
  const { claims: { rol } } = useAppContext();
  const [ showMotivo, setShowMotivo ] = useState(false);
  const data = useMemo(()=>({text: params.observacion}))
 
  const handleClick = () => {
    console.log(params)
    if(!!params?.conMotivo){
      setShowMotivo(true);
    }else{
      lock()
        .then(result => {
          loadDetails(selectedItem.id);
        })
    }
  }
  
  const handleSendData = (motivo) => {
    lock({motivo})
      .then(result => {
        loadDetails(selectedItem.id);
      })
  }

  return (
    <>
      <ModalObservacion title="" caption="Ingrese una observación" onConfirm={handleSendData} showState={[ showMotivo, setShowMotivo ]} data={data}/>
      <ActionButton iconButton enabled={true} Icon={LockOutlinedIcon} onClick={handleClick}/>
    </>
  );

}