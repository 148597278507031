import Swal from "sweetalert2";

export const useItemsEliminar = ({selectedItem, abmActions, showNotification, updateItem}) => {
  const fire = ({title, text}) => {
    Swal.fire({
      icon: 'question',
      title,
      html: `Realmente desea <b>${text}</b> el item.<br/>Esta operación no podrá deshacerse.`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirm();
      }
    }) 
  }

  const handleConfirm = () => {
    abmActions.remove(selectedItem.id, "items").then(result => {
      updateItem();
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return fire;
}