import { useAbmContext } from "../../views/abm/useAbmComponent"
import { DeleteIcon, SaveIcon } from "../icons";

export const AbmFormEditComponent = () => {
  const {
    form: {
      formData, 
      formItem, 
      schemaEdit, 
      FormComponent, 
      handleEditConfirm, 
      handleFormCancel
    }, 
    itemDescription, 
    selectedItem
  } = useAbmContext();
  return <FormComponent
    data={formData}
    item={formItem}
    schema={schemaEdit} 
    title={`Editar ${itemDescription(selectedItem)}`}
    onConfirm={handleEditConfirm} 
    onCancel={handleFormCancel} 
    confirmLabel={<><SaveIcon size={18} color="white"/>Guardar</>} 
    cancelLabel={<><DeleteIcon size={18} color="white"/>Cancelar</>} 
  />
}