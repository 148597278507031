import { useAppContext } from "../../../App";
import { authorize } from "../../../Core";
import { estadosItem, roles } from "../../../config/constants";
import { useAbmContext } from "../../../views/abm/useAbmComponent";
import { useDashboardContext } from "../../../views/Dashboard";
import { ActionButton } from "../../helpers";
import { CancelIcon } from "../../icons";

//LIQUIDADOR pueden rechazar Facturas que esten en estado PRE-APROBADAS

export const DashboardRechazarFactura = ({params}) => {
  const { refresh } = useDashboardContext();
  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.LIQUIDADOR]})){
    return <></>
  }

  const enabled = rol === roles.LIQUIDADOR;

  const handleClick = () => {
    alert(params.id)
  }
  return <ActionButton iconButton enabled={enabled} Icon={CancelIcon} onClick={handleClick}/>
}
