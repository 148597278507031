import React, { memo, useEffect, useState } from "react";
import { ControlText } from "../helpers";
import { arrayEmpty, objectEmpty } from "../../utils/Helpers";
import { SearchFormWrapper } from "./SearchFormWrapper";

export const SearchForm = ({onSearchChange, initSearch = objectEmpty}) => {
  const [ searchData, setSearchData ] = useState({term: "", ...initSearch}); 
 
  useEffect(()=>{
    setSearchData(initSearch)
  }, [initSearch])

  const handleControlChange = ({target: {name, value}}) => {
    const newData = {...searchData, [name]: value};
    setSearchData(newData)
    onSearchChange(newData);
  }

  return (
    <>
      <ControlText autoFocus name="term" label="" placeholder="Buscar" errors={arrayEmpty} data={searchData} onChange={handleControlChange} Wrapper={SearchFormWrapper}  />
    </>
  )
}

