import React, { useRef } from "react";
import { ControlWrapper } from "./ControlWrapper";
import DatePicker from "react-datepicker";
import moment from 'moment';

const objectPath = require("object-path");

export const ControlDatePicker = ({name, wrapperName = name, label, placeholder, onChange, data, start, end, errors = [], Wrapper = ControlWrapper, ...options }) => {
  
  const getValue = (prev) => {
    if(!prev){
      return null;
    } 
    return moment( moment.utc(prev).format('YYYY/MM/DD HH:mm:ss')).toDate();
  }
  
  const value = getValue(objectPath(data).get(name, null));

  return (
    <Wrapper name={wrapperName} errors={errors} end={end} start={start} label={label}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        className="h-100 w-100"
        selected={value}
        onChange={(value)=>onChange({target: {name, value}})}
        isClearable
        placeholderText={placeholder}
        withPortal
        
      />
    </Wrapper>
  )
}