import { Form } from "../../components/abm/centros/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { useMemo } from "react";

export const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().label("Nombre").required("El nombre es un campo requerido").default(""),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().label("Nombre").required("El nombre es un campo requerido"),
  })
})

const entity = "centros";
const titleTable = "Centros";
const itemName = "Centro";
const itemDescription = item => `${itemName} ${item?.nombre || ''}`;
const columns = [
  {field: "nombre", caption: "Nombre"},
  {field: "usuarios", caption: "Usuarios", width: 80},
  {field: "ambitos", caption: "Ambitos"},  
  {field: "_actions_", caption: "Acciones", width: 80}
];

export const Centros = ({abmKey}) => {
  
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}