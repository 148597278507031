import React, { useEffect, useMemo } from "react";
import { Popover, Typography } from "@mui/material"
import { useAbmFormContext } from "../abm/AbmForm";


export const ErrorPopover = ({anchorEl, name}) => {
  
  const { entityErrors = [], clearErrors } = useAbmFormContext();

  const handleClose = () => {
    clearErrors();
  }

  const error = useMemo(()=>{
    return entityErrors.find(e => e.path === name);
  }, [entityErrors, name]);

  /*useEffect(()=>{
    console.log(name, {error})
  }, [error]);*/

  return (
    <>
      <Popover
        open={!!error?.message}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handleClose}
      ><Typography sx={{ p: 2 }}>{error?.message}</Typography></Popover>
    </>
  )
}