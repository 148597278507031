import { useCallback, useMemo, useState } from "react";
import { useAppContext } from "../App";
import { server } from "../config/constants";

const controller = new AbortController();
const signal = controller.signal;


const baseUrl = server;

export const abm = (abmEntity, options = {onError: (reason)=>{}}, token = "", setWriteFetching, setFetching) => {
  const { onError } = options;

  const withTimestamp = (url) => url.includes("?") ? `${url}&timestamp=${Date.now()}` : `${url}?timestamp=${Date.now()}`;

  const cancel = () => {
    //controller.abort();
  }

  const processResponse = ({res, resolve, reject, toJson = true}) => {
    if(res.ok){
      resolve(toJson ? res.json() : res);
    }else{
      processError({error: res, reject})
    }
  }

  const processError = ({error, reject}) => {
    onError(error);
    reject(error);  
  }


  const fetchData = (url, options) => new Promise((resolve, reject) => {
    if(options.method === "POST" || options.method === "PUT"  || options.method === "DELETE" )
      setWriteFetching(true);
    setFetching(true);
    fetch(url, options)
      .then(res => {
        if(options.method === "POST" || options.method === "PUT"  || options.method === "DELETE" )
          setWriteFetching(false);
        setFetching(false);
        resolve(res);
      })
      .catch(error => {
        if(options.method === "POST" || options.method === "PUT"  || options.method === "DELETE" )
          setWriteFetching(false);
        setFetching(false);
        reject(error);
      });
  });

  const getAll = (query = {page: 1, id: "", term: ""}, entity = abmEntity) => new Promise((resolve, reject)=>{

    const clearQuery = Object.keys(query).reduce((prev, curr) => query[curr] ? {...prev, [curr]: query[curr]} : prev, {});
    const queryString = new URLSearchParams(clearQuery).toString();
    const url = withTimestamp(`${baseUrl}${entity}?${queryString}`);
    fetchData(url, { signal, headers: {'Authorization': `Bearer ${token}`} })
      .then(res => {
        processResponse({res, resolve, reject})
      })
      .catch(error => {
        processError({error, reject})
      });
  });

  const getOne = (id, entity = abmEntity, toJson = true) => new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${entity}/${id}`);
    fetchData(url, { signal, headers: {'Authorization': `Bearer ${token}`} })
      .then(res => {
        processResponse({res, resolve, reject, toJson})
      })
      .catch(error => {
        processError({error, reject})
      });
  });

  
  const getDocument = (id, entity = abmEntity) => new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${entity}/${id}`);
    fetchData(url, { signal, headers: {'Authorization': `Bearer ${token}`} })
      .then(res => {
        res.blob().then(blob => {
          var reader = new FileReader();
          reader.onload = function(event){
            resolve(reader.result);
          };
          var source = reader.readAsDataURL(blob);
        })
      })
      .catch(error => {
        processError({error, reject})
      });
  });

  const getDetails = (id, entity = abmEntity) => new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${entity}/details/${id}`);
    fetchData(url, { signal, headers: {'Authorization': `Bearer ${token}`} })
      .then(res => {
        processResponse({res, resolve, reject})
      })
      .catch(error => {
        processError({error, reject})
      });
  });

  const create = (body, entity = abmEntity) =>  new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${entity}`);
    fetchData(url, {
      method: "POST",
      signal, 
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body),
    })
      .then(res => {
        processResponse({res, resolve, reject})
      })
      .catch(error => {
        processError({error, reject})
      });
  });

  const update = async (body, id, entity = abmEntity, others) => new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${entity}/${id}`);
    fetchData(url, {
      method: "PUT",
      signal, 
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body),
    })
    .then(res => {
      processResponse({res, resolve, reject})
    })
    .catch(error => {
      processError({error, reject})
    });   
  });

  const lock = async (lock, id, entity = abmEntity, others) => new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${entity}/lock/${id}`);
    fetchData(url, {
      method: "PUT",
      signal, 
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({lock, id, ...(others || {})}),
    })
    .then(res => {
      processResponse({res, resolve, reject})
    })
    .catch(error => {
      processError({error, reject})
    });   
  });

  const remove = async (id, entity = abmEntity) => new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${entity}/${id}`);
    fetchData(url, {
      method: "DELETE",
      signal, 
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    })    
    .then(res => {
      processResponse({res, resolve, reject})
    })
    .catch(error => {
      processError({error, reject})
    }); 
  });

  const uploadImage = async (data, id, entity = abmEntity) => {
    const url = withTimestamp(`${baseUrl}${entity}/${id}`);
    const formData = new FormData();
    formData.append("image", data);
    const response = await fetchData(url, {
      signal, 
      method: "POST",
      credentials: "include",
      body: formData
    })
    return response;
  };

  const download = (endPoint, fileName, type = 'application/pdf') => new Promise((resolve, reject)=>{
    const url = withTimestamp(`${baseUrl}${endPoint}`);

    fetchData(url, { signal, headers: {'Authorization': `Bearer ${token}`, 'Content-Type': type} })
      .then(res => {
        console.log(res)
        return res.blob()
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob], { type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Nombre del archivo que se descargará
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        resolve({ok: true});
      })
      .catch(error => {
        processError({error, reject})
      });
 
  });

  return {getAll, getOne, create, update, remove, uploadImage, cancel, getDetails, lock, getDocument, download}

}


export const useAbm = ({entity}) => {
  const appContext = useAppContext();
  const handleError = useCallback((reason) => {
    //TODO: si el error es 401 desloguear
    //appContext.singOut();
    console.log(reason);
  }, [])
  const abmResult = useMemo(() => ({entity, ...abm(entity, {onError: handleError}, appContext.auth, appContext.setWriteFetching, appContext.setFetching)}), [entity, handleError, appContext?.auth]);
  return abmResult;
}

//export const {getAll, getOne, create, update, remove, uploadImage, cancel, getDetails} = abm();
