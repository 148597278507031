import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { EditIcon } from "../../../icons";

//GESTOR Y GESTOR CENTRAL pueden editar Items que esten en estado RECHAZADOS o PENDIENTES DE APROBACION
//al guardar el item pasa a estado PENDIENTE DE APROBACION si es un GESTOR y a PRE-APROBADO si es un GESTOR CENTRAL

export const EditarItemGeneral = ({params}) => {
  const { setShowItemGeneralEdit } = useAbmContext();
  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.GESTOR, roles.GESTOR_CENTRAL, roles.LIQUIDADOR, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]})){
    return <></>
  }
  const enabled = true;
  const handleClick = () => {
    setShowItemGeneralEdit(params);
  }
  return <ActionButton iconButton enabled={enabled} Icon={EditIcon} onClick={handleClick}/>
}
