import { Form } from "../../components/abm/proyectos/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { SearchForm } from "../../components/abm/ambitos/SearchForm";
import { useMemo } from "react";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción"),
    id_programa: yup.number().label("Programa").required(),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción"),
    id_programa: yup.number().label("Programa").required(),
  })
});
const entity = "proyectos";
const titleTable = "Proyectos";
const itemName = "Proyecto";
const itemDescription = item => `${itemName} ${item?.nombre || ''}`;
const columns = [
  {field: "nombre", caption: "Nombre"},
  {field: "descripcion", caption: "Descripción", width: 150},
  {field: "centro", caption: "Centro", width: 150},
  {field: "ambito", caption: "Ambito", width: 150},
  {field: "programa", caption: "Programa", width: 150},
  {field: "importe_asignado", caption: "Imp. asignado", width: 130},
  {field: "importe_final", caption: "Imp. final", width: 130},
  {field: "disponible", caption: "Imp. Disponible", width: 130},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "programas/options")
    .then( data => {
      resolve({...data, name: "programas"});
    })
    .catch(reason => {
      reject(reason);
    })
  })
];

export const Proyectos = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, loads, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, SearchFormComponent: SearchForm})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}