import { Collapse, Grid } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { dataToView } from "../../details/Utils";
import { ControlDatePicker, ControlText, ControlTextArea, ControlSelect2, ControlUploadFile } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";

export const Form = React.forwardRef(({actionsBottom = true, item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}, ref) => {


  return (
    <AbmForm ref={ref} title={title} actionsTop={false} actionsBottom={actionsBottom} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <AbmFormContent {...{onControlChange, entity, entityErrors, data}}/>
      )}
    </AbmForm>
  );
})
const AbmFormContent = ({onControlChange, entity, entityErrors, data}) => {

  const factura = !!entity && !!entity.id_factura && entity.id_factura !== 0 && data.facturas && data.facturas.find(factura => factura.value === entity.id_factura);

  useEffect(()=>{
    const importe = !!factura ? factura.importe_convertido : "";
    const tipoCambio = !!factura ? factura.cotizacion : 0;
    const id_mpj = !!factura ? factura.id_mpj : null;
    onControlChange({target: {value: importe, name: "importe"}});
    onControlChange({target: {value: tipoCambio, name: "tipoCambio"}});
    onControlChange({target: {value: id_mpj, name: "id_mpj"}});
  }, [factura]);

  const handleTipoCambioChange = ({target: {name, value}}) => {
    onControlChange({target: {value: value, name: "tipoCambio"}});
    try{
      const tipoCambio = parseFloat(value);
      const nuevoImporte = Number((factura.importe_valor / tipoCambio).toFixed(2));
      onControlChange({target: {value: nuevoImporte, name: "importe"}});  
    }catch(e){}
  }

  return (
    <>
      <Grid item xs={12}>
        <ControlSelect2 name="id_factura" label="Factura" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} options={data.facturas || []} isMulti={false} closeMenuOnSelect={true} Wrapper={ControlSmallWrapper}/>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={!!factura} unmountOnExit={true}>
          <Grid container paddingLeft={5} paddingY={2}>
            <Grid item xs={12}>
              {
                dataToView({data: {
                  fecha: factura?.fecha_documento,
                  concepto: factura?.concepto,
                  importe: factura?.importe
                }})
              }
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <ControlDatePicker name="fecha_pago" label="Fecha de pago" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlText name="tipoCambio" label="Tipo de cambio" errors={entityErrors} data={entity} onChange={handleTipoCambioChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      <Grid item xs={12}>
        <ControlText name="importe" label="Importe en U$S" readOnly errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="id_mpj" label="MPJ" errors={entityErrors} data={entity} onChange={onControlChange} options={data.mpjs?.filter(mpj => !mpj.cerrado) || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlUploadFile name="comprobante" label="Comprobante de pago" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
    </>
  )
}