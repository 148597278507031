import { useAppContext } from "../App";
import { server } from "../config/constants";

const baseUrl = server;

export const login = ({options = {}}) => {
  const { onError } = options;

  const processResponse = ({res, resolve, reject}) => {
    if(res.ok){
      resolve(res.json());
    }else{
      processError({error: res, reject})
    }
  }

  const processError = ({error, reject}) => {
    onError && onError(error);
    reject(error);  
  }

  const send = (body) =>  new Promise((resolve, reject)=>{
    const url = `${baseUrl}auth`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json", 
        "Content-Type": "application/json",
      },
      mode: 'cors',
      body: JSON.stringify(body),
    })
      .then(res => {
        processResponse({res, resolve, reject})
      })
      .catch(error => {
        processError({error, reject})
      });
  });

  return {send}

}


export const useLogin = () => {
  const appContext = useAppContext();
  const handleError = (reason) => {
    //TODO: si el error es 401 desloguear
    //appContext.singOut();
    console.log(reason);
  }
  return {...login({onError: handleError})}
}
