import React, { useContext, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../App";
import { Link, Divider, Drawer as MuiDrawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import styled from "@emotion/styled";
import { ChevronLeftIcon, HomeIcon, LogoutIcon } from "../icons";
import { PrivateLayoutContext } from "./PrivateLayout";
import { useTheme } from "@emotion/react";
import { roles } from "../../config/constants";
import { authorize } from "../../Core";

const drawerWidth = 240;

export const SideDrawer = ({}) => {

  const { claims, smMode, core } = useAppContext();
  const { toggleMenu, setOpenMenu, openMenu } = useContext(PrivateLayoutContext);
  const theme = useTheme();

  const ambsActivos = useMemo(()=>{
    return Object.keys(core) 
     .filter(key => core[key].visible)
  }, [core])
  
  useEffect(()=>{
    if(smMode){
      setOpenMenu(false);
    }
  }, [smMode])

  return (
    <Drawer variant="permanent" open={openMenu} style={{zIndex: 1000}}>
      <Toolbar
        variant="regular"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
          backgroundColor: theme.palette.background.paper
        }}
      >
        <IconButton onClick={toggleMenu}>
          <ChevronLeftIcon color={"#FFFFFF"} />
        </IconButton>
      </Toolbar>
      {authorize({rol: claims.rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO_CENTRAL, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.DIRECTOR_CENTRO, roles.ADMINISTRATIVO]}) &&
        <>
          <div style={{height: 40}}></div>
          <ListItem label="Inicio" Icon={HomeIcon} href={"home"} sx={{maxHeight:40}} title={!openMenu ? 'Inicio' : '' }/>
        </>
      }
      <Divider />
      <List style={{overflow: "auto", maxHeight: "calc(100vh - 190px)"}}>
      {
        ambsActivos.map(key => 
          <ListItem key={key} label={core[key].label} Icon={core[key].icon} href={key} title={!openMenu ? core[key].label : '' }/>
        )
      }        
      </List>

      <Divider />
      <div style={{flex: 1}}></div>
      <ListItem selected label="Cerrar Sesión" Icon={LogoutIcon} href={"logout"} sx={{maxHeight:40}} title={!openMenu ? 'Cerrar Sesión' : '' }/>
      <div style={{height: 40}}></div>
    </Drawer>
  )

}

const ListItem = ({label, Icon, ...props}) => {
  return (
    <ListItemButton href={props.href} {...props} component={CustomNavLink}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
      }),
    },
  }),
);

const CustomLink = React.forwardRef(({ href, children, ...props}, forwardedRef) => {
  return (
    <Link {...props} component={CustomNavLink} to={href} href={href} ref={forwardedRef} >
      {children}
    </Link>
  )
})

const CustomNavLink = React.forwardRef(({ href, children, ...props}, forwardedRef) => {
  return <NavLink {...props} to={href} ref={forwardedRef} className={({isActive}) => `${props.className} ${isActive ? "Mui-selected" : ""}`}>{children}</NavLink>
})
