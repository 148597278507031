import React from "react";
import { Grid } from "@mui/material";
import { ControlText, ControlSelect2, ControlCheckbox } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";

export const Form = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}) => {
  return (
    <AbmForm title={title} actionsTop={false} actionsBottom={true} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity}) => (
        <>
          <Grid item xs={12}>
            <ControlText name="nombre" label="Nombre" data={entity} autoFocus onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="apellido" label="Apellido" data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="email" label="Email" data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="password" label="Password" data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlCheckbox name="activo" label="Activo" data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlSelect2 name="id_rol" label="Rol" data={entity} onChange={onControlChange} options={data.roles || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
          </Grid>
          <Grid item xs={12}>
            <ControlSelect2 name="id_centros" label="Centros" data={entity} onChange={onControlChange} options={data.centros || []} isMulti={true} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
          </Grid>
        </>
      )}
    </AbmForm>
  );
}