import React, { useRef } from "react";
import { Grid, Popover, Typography } from "@mui/material";
import { useAbmFormContext } from "../abm/AbmForm";
import { ErrorPopover } from "./ErrorPopover";

export const ControlSmallWrapper = ({children, name, label, start, end, className, errorPlacement = "right", borderColor = "grey", backgroundColor = "white", containerInputProps = {} }) => {
  const target = useRef();
  return (
    <>
      <Grid container ref={target} minHeight={50} marginY={0} paddingX={2} paddingY={1} style={{
                      backgroundColor: "#00000005",
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      border: "solid 1px #0000000f",
                }}>
        {label &&
          <Grid item xs={12} md={2} lg={3} display="flex" alignItems="center">
            <label htmlFor={name}>{label}</label>
          </Grid>
        }
        <Grid item xs={12} md={!!label ? 10 : 12} lg={!!label ? 9 : 12} display="flex" minHeight={30} {...containerInputProps}>
            {start}
            {children}
            {end}
        </Grid>
      </Grid>  
      <ErrorPopover anchorEl={target.current} name={name}/>
    </>
  )
}