import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMemo, useRef } from "react";
import { getSchemas } from "../../views/abm/Perceptores";
import { FormFromItemPresupuesto } from "../abm/perceptores/FormFromItemPresupuesto";


export const ModalPerceptorAdd = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, updateItem}) => {
  const { schemaAdd } = useMemo(() => getSchemas(), []);
  const [show, setShow] = showState;
  const formRef = useRef();

  const handleCancel = (event) => {
    setShow(false);
    event?.preventDefault();
    event?.stopPropagation();
  }
  
  const handleConfirm = (data) => {
    abmActions.create(data, "perceptores").then(result => {
      updateItem(result);
      handleCancel();
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return (
    <Dialog open={show} onClose={handleCancel}>
      <DialogTitle>Agregar perceptor</DialogTitle>
      <DialogContent>
        <FormFromItemPresupuesto 
          ref={formRef}
          item={{}}
          schema={schemaAdd}
          onConfirm={handleConfirm}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={e => formRef.current.submit(e)}>
          Guardar
        </Button>      
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancelar
        </Button>   
      </DialogActions>
    </Dialog>
  )
}