import { Form } from "../../components/abm/usuarios/Form";
import * as yup from 'yup';
import { Abm } from "./_Abm";
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { useMemo } from "react";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().label("Nombre").required().default(""),
    apellido: yup.string().label("Apellido").required().default(""),
    email: yup.string().label("Email").email().required().default(""),
    activo: yup.mixed().label("Activo").default(true),
    id_rol: yup.number().label("Rol").required().default(null),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().label("Nombre").required().default(""),
    apellido: yup.string().label("Apellido").required().default(""),
    email: yup.string().label("Email").email().required().default(""),
    activo: yup.mixed().label("Activo").default(true),
    id_rol: yup.number().label("Rol").required().default(null),
  })
});
const entity = "usuarios";
const titleTable = "Usuarios";
const itemName = "Usuario";
const itemDescription = (item) => `${itemName} ${item?.nombre_completo || ''}`;
const columns = [
  {field: "nombre_completo", caption: "Nombre"},
  {field: "email", caption: "Email", width: 220},
  {field: "activo", caption: "Activo", render: (row) => row.activo ? "SI" : "NO", width: 80},
  {field: "rol", caption: "Rol", render: (row) => row.rol, width: 120},
  {field: "centros", caption: "Centros", render: (row) => row.centros.length, width: 80},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "roles/options")
    .then( data => {
      resolve({...data, name: "roles"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "centros/options")
    .then( data => {
      resolve({...data, name: "centros"});
    })
    .catch(reason => {
      reject(reason);
    })
  })
];

export const Usuarios = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, loads, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}/>
    </>
  );
}