import * as yup from 'yup';
import { FormItemPresupuesto } from "../../components/abm/actividades/FormItemPresupuesto";
import { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


const schemaItemPresupuestoAdd = yup.object().shape({
  importe: yup.number().positive().required().label("Importe"),
  concepto: yup.string().required().label("Concepto"),
  id_perceptor: yup.number().required().label("Perceptor"),
  id_moneda: yup.number().required().label("Moneda"),
  id_item_general: yup.number().nullable().optional().label("Item general")
});

export const ModalActividadesEditItem = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, loadDetails, idItemPresupuesto}) => {

  const [data, setData] = useState({});
  const [item, setItem] = useState({});
  const [show, setShow] = showState;
  const formRef = useRef();

  const loads = () => {
    abmActions.getAll({}, "perceptores/options")
      .then(response => {
        setData(data => ({...data, perceptores: response.results}));
      })
      .catch(reason => {
        console.warn(reason);
      });
    abmActions.getAll({}, "monedas/options")
      .then(response => {
        setData(data => ({...data, monedas: response.results}));
      })
      .catch(reason => {
        console.warn(reason);
      });
    abmActions.getOne(idItemPresupuesto, "items")
      .then(response => {
        setItem(response);
      })
      .catch(reason => {
        console.warn(reason);
      });
    abmActions.getAll({}, `items_generales/options_actividad/${selectedItem?.id || 0}`)
      .then(response => {
        setData(data => ({...data, items_generales: response.results}));
      })
      .catch(reason => {
        console.warn(reason);
      });
  }
  
  useEffect(()=>{
    show && loads();
  }, [show])


  const handleCancel = () => {
    setShow(false);
  }

  const handleConfirm = (data) => {
    abmActions.update(data, idItemPresupuesto, "items").then(result => {
      loadDetails(selectedItem.id);
      setShow(false);
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return (
    <>
      <Dialog open={show} onClose={handleCancel} keepMounted={false} PaperProps={{sx: {overflow: "visible"}}}>
        <DialogTitle>Editar item de presupuesto</DialogTitle>
        <DialogContent sx={{overflow: "visible"}}>
          <FormItemPresupuesto 
            ref={formRef}
            item={item}
            data={data}
            schema={schemaItemPresupuestoAdd} 
            onConfirm={handleConfirm} 
            loads={loads}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
            Guardar
          </Button>      
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancelar
          </Button>   
        </DialogActions>
      </Dialog>
    </>
  )
}