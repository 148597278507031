import React from "react";
import { Grid } from "@mui/material";
import { ControlTextArea } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";

export const FormDesignacionEnviarRevision = React.forwardRef(({
  item, 
  schema, 
  onCancel, 
  onConfirm, 
  confirmLabel = "Guardar", 
  cancelLabel = "Cancelar", 
  title, 
  errorState, 
  data = {}, 
  loads}, ref) => {

  return (
    <>
      <AbmForm ref={ref} title={title} actionsBottom={false} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
        {({onControlChange, entity, entityErrors}) => {
          return (
            <>
              <Grid item xs={12}>
                <ControlTextArea name="observacion" label="Observación (opcional)" placeholder="Breve observación para ser tenida en cuenta al momento de la revisión" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
              </Grid>
            </>
          )
        }}
      </AbmForm>
    </>
  );
})