import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAppContext } from "../../../App";
import { roles } from "../../../config/constants";
import { abm } from "../../../services/abm";
import { ControlArrayDatePicker, ControlDatePicker, ControlRangeDatePicker, ControlText, ControlTextArea } from "../../helpers";
import { ControlSelect2 } from "../../helpers/ControlSelect2";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";

const tiposFechaEvento = [
  {label: "Una fecha", value: "ONE"},
  {label: "Varias fechas", value: "SOME"},
  {label: "Rango de fechas", value: "RANGE"},
]

export const Form = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}) => {
  return (
    <AbmForm title={title} actionsTop={false} actionsBottom={true} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <AbmFormContent {...{onControlChange, entity, entityErrors, data}}/>
      )}
    </AbmForm>
  );
}
const AbmFormContent = ({onControlChange, entity, entityErrors, data}) => {

  const [ dateCount, setDateCount ] = useState(1);
  const handleControlChangeCentro = (event) => {
    onControlChange(event);
    onControlChange({target: {name: "id_ambito", value: null}});
    onControlChange({target: {name: "id_programa", value: null}});
    onControlChange({target: {name: "id_proyecto", value: null}});
    onControlChange({target: {name: "id_sub_proyecto", value: null}});
    onControlChange({target: {name: "id_mpj", value: null}});
  }

  const handleControlChangeAmbito = (event) => {
    onControlChange(event);
    if(event.target.value !== entity.id_ambito){
      onControlChange({target: {name: "id_programa", value: null}});
      onControlChange({target: {name: "id_proyecto", value: null}});
      onControlChange({target: {name: "id_sub_proyecto", value: null}});
      onControlChange({target: {name: "id_mpj", value: null}});
    }
  }

  const handleControlChangePrograma = (event) => {
    onControlChange(event);
    if(event.target.value !== entity.id_programa){
      onControlChange({target: {name: "id_proyecto", value: null}});
      onControlChange({target: {name: "id_sub_proyecto", value: null}});
    }
  }

  const handleControlChangeProyecto = (event) => {
    onControlChange(event);
    if(event.target.value !== entity.id_proyecto){
      onControlChange({target: {name: "id_sub_proyecto", value: null}});
    }
  }

  const handleControlChangeTipoFechaEvento = (event) => {
    onControlChange(event);
    //console.log(event)
  }

  const ambitos = data.ambitos?.filter(ambito => parseInt(ambito.id_centro) === entity?.id_centro);
  const programas = ambitos?.find(ambito => entity?.id_ambito == ambito.value)?.programas;
  const proyectos = programas?.find(programa => entity?.id_programa == programa.value)?.proyectos;
  const sub_proyectos = proyectos?.find(proyecto => entity?.id_proyecto == proyecto.value)?.sub_proyectos;
  const mpjs = ambitos?.find(ambito => entity?.id_ambito == ambito.value)?.mpjs;

  return (
    <>
      <Grid item xs={12}>
        <ControlText name="nombre" label="Nombre" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      <Grid item xs={12}>
        <ControlTextArea name="descripcion" label="Breve descripción" maxLength={200} rows={3} errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="fecha_evento.type" label="Duración" errors={entityErrors} data={entity} onChange={handleControlChangeTipoFechaEvento} options={tiposFechaEvento || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper} type="string"/>
      </Grid>  
      {entity?.fecha_evento?.type === "ONE" &&
        <Grid item xs={12}>
          <ControlDatePicker name="fecha_evento.value.0" wrapperName="fecha_event.value" label="Fecha" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
        </Grid>  
      }
      {entity?.fecha_evento?.type === "SOME" &&
        <Grid item xs={12}>
          <ControlArrayDatePicker name="fecha_evento.value" wrapperName="fecha_event.value" label="Fechas" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
        </Grid>  
      }
      {entity?.fecha_evento?.type === "RANGE" &&
        <Grid item xs={12}>
          <ControlRangeDatePicker nameStart="fecha_evento.value.0" nameEnd="fecha_evento.value.1" label="Rango" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
        </Grid>  
      }
      <Grid item xs={12}>
        <ControlText name="marco" label="Marco" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>    
      <Grid item xs={12}>
        <ControlText name="lugar" label="Lugar" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>              
      <Grid item xs={12}>
        <ControlSelect2 name="id_centro" label="Centro" errors={entityErrors} data={entity} onChange={handleControlChangeCentro} options={data.centros || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>              
      <Grid item xs={12}>
        <ControlSelect2 name="id_ambito" label="Ambito" errors={entityErrors} data={entity} onChange={handleControlChangeAmbito} options={ambitos || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>              
      <Grid item xs={12}>
        <ControlSelect2 name="id_programa" label="Programa" errors={entityErrors} data={entity} onChange={handleControlChangePrograma} options={programas || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="id_proyecto" label="Proyecto" errors={entityErrors} data={entity} onChange={handleControlChangeProyecto} options={proyectos || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid> 
      <Grid item xs={12}>
        <ControlSelect2 name="id_sub_proyecto" label="Sub-Proyecto" errors={entityErrors} data={entity} onChange={onControlChange} options={sub_proyectos || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid> 
      <Grid item xs={12}>
        <ControlSelect2 name="id_mpj" label="MPJ" errors={entityErrors} data={entity} onChange={onControlChange} options={mpjs || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
    </>
  )
}