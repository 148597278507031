import { Form } from "../../components/abm/mpjs/Form";
import * as yup from 'yup';
import { Abm } from "./_Abm";
import { AbmContext, useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { SearchForm } from "../../components/abm/mpjs/SearchForm";
import { useMemo } from "react";

export const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    numero: yup.string().required().default(""),
    cerrado: yup.bool().required().default(false).label("Cerrado")
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    numero: yup.string().required(),
    cerrado: yup.bool().required().default(false).label("Cerrado")
  })
});

const entity = "mpjs";
const titleTable = "MPJs";
const itemName = "MPJ";
const itemDescription = item => `${itemName} ${item?.numero || ''}`;
const columns = [
  {field: "concepto", caption: "Concepto"},
  {field: "fecha", caption: "Fecha", width: 120},
  {field: "planeamiento", caption: "Planeamiento", width: 120},
  {field: "numero", caption: "Número", width: 120},
  {field: "importe_asignado", caption: "Importe €", width: 120},
  {field: "importe_final", caption: "Importe USD", width: 120},
  {field: "disponible", caption: "Disponible USD", width: 120},
  {field: "fecha_vencimiento", caption: "Vencimiento", width: 120},
  {field: "liquidaciones", caption: "Liquidaciones", width: 120},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "planeamientos/options")
    .then( data => {
      resolve({...data, name: "planeamientos"});
    })
    .catch(reason => {
      reject(reason);
    })
  })
];

export const Mpjs = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, loads, SearchFormComponent: SearchForm})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}