import { Form } from "../../components/abm/ambitos/Form";
import * as yup from 'yup';
import { AbmWithContext } from "./_AbmWithContext";
import { useAbmComponent } from "./useAbmComponent";
import { SearchForm } from "../../components/abm/ambitos/SearchForm";
import { useMemo } from "react";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción").optional(),
    id_centro: yup.number().label("Centro").required(),
    id_planeamiento: yup.number().label("Planeamiento").required()
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción").optional(),
    id_centro: yup.number().label("Centro").required(),
    id_planeamiento: yup.number().label("Planeamiento").required()
  })
});

const entity = "ambitos";
const titleTable = "Ambitos";
const itemName = "Ambito";
const itemDescription = item => `${itemName} ${item?.nombre || ''}`;
const columns = [
  {field: "nombre", caption: "Nombre"},
  {field: "descripcion", caption: "Descripción", width: 150},
  {field: "centro", caption: "Centro", width: 150},
  {field: "programas", caption: "Programas", width: 80},
  {field: "planeamiento", caption: "Planeamiento", width: 150},
  {field: "importe_asignado", caption: "Imp. asignado", width: 130},
  {field: "importe_final", caption: "Imp. final", width: 130},
  {field: "disponible", caption: "Imp. Disponible", width: 130},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "centros/options")
    .then( data => {
      resolve({...data, name: "centros"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "planeamientos/options")
    .then( data => {
      resolve({...data, name: "planeamientos"});
    })
    .catch(reason => {
      reject(reason);
    })
  })
];

export const Ambitos = ({abmKey}) => {

  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, loads, SearchFormComponent: SearchForm})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}