import React from "react";
import { Grid } from "@mui/material";
import { ControlCheckbox, ControlDatePicker, ControlSelect2, ControlText, ControlTextArea } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";
import { tiposMpj } from "../../../config/constants";

const tiposMpjOptions = [
  {value: tiposMpj.PRIMER_ENVIO, label: tiposMpj.PRIMER_ENVIO},
  {value: tiposMpj.SEGUNDO_ENVIO, label: tiposMpj.SEGUNDO_ENVIO},
  {value: tiposMpj.OTRO, label: tiposMpj.OTRO},
]

export const Form = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}) => {

  return (
    <AbmForm title={title} actionsTop={false} actionsBottom={true} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <>
          <Grid item xs={12}>
            <ControlDatePicker name="fecha" label="Fecha" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
          </Grid>  
          <Grid item xs={12}>
            <ControlText name="numero" label="Número" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlDatePicker name="fecha_vencimiento" label="Vencimiento" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
          </Grid>  
          <Grid item xs={12}> 
            <ControlText name="importe_asignado" label="Importe asignado EUR" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
          </Grid> 
          <Grid item xs={12}> 
            <ControlText name="importe_final" label="Importe final USD" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
          </Grid> 
          <Grid item xs={12}> 
            <ControlText name="concepto" label="Concepto" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
          </Grid>  
          <Grid item xs={12}>
            <ControlCheckbox name="cerrado" label="Cerrado" data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlSelect2 name="tipo" label="Tipo" type="string" errors={entityErrors} data={entity} onChange={onControlChange} options={tiposMpjOptions} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
          </Grid>
          <Grid item xs={12}>
            <ControlSelect2 name="id_planeamiento" label="Planeamiento" errors={entityErrors} data={entity} onChange={onControlChange} options={data?.planeamientos?.filter(p => !p.cerrado) || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
          </Grid>
        </>
      )}
    </AbmForm>
  );
}