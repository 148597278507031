import "./App.css";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AppRoutes } from "./AppRoutes";
import { useJwt } from "react-jwt";
import { useMediaQuery } from "@mui/material";
import { core } from "./Core";
import { objectEmpty } from "./utils/Helpers";
import { setLocale } from "yup";

setLocale({
  mixed: {
    default: '${label}: Inválido',
    required: '${label}: Es un dato requerido',
    notType: '${label}: El dato no es válido',
  },
  string:{
    min: '${label}: El texto debe contener al menos ${min} caracteres',
    max: '${label}: El texto excedió el límite de ${max} caracteres'
  },
  number: {
    min: '${label}: El número debe ser mayor o igual que ${min}',
    max: '${label}: El número debe ser menor o igual que ${max}'
  },
  array: {
    min: '${label}: La lista debe tener al menos ${min} items',
    max: '${label}:La lista debe como máximo ${max} items',
  }
});

const defAppContext = ({tokenState}) => {
  const [token, setToken] = tokenState || [];

  const load = () => {
    setToken(localStorage.getItem("auth"));
  }

  const singOut = () => {
    setToken(null);
    localStorage.removeItem("auth");
  }

  const singIn = ({token}) => {
    setToken(token);
    localStorage.setItem("auth", token);
  }

  return {auth: token, isAuth: token, singOut, singIn, load};
}

const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

const App = () => {
  //console.log("render app")
  const [writeFetching, setWriteFetching] = useState(false);
  const [fetching, setFetching] = useState(false);
  const context = defAppContext({tokenState: useState(null)});
  const { isExpired, decodedToken } = useJwt(context.auth);
  const smMode = !useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const claims = useMemo(()=> ({...decodedToken, centros: decodedToken?.centros?.split(",").map(c => parseInt(c))}), [decodedToken]);
  const appCore = useMemo(() => core({claims}), [claims]);

  const [globalQuery, setGlobalQuery] = useState(objectEmpty);

  const setPropGlobalQuery = (prop, value) => {
    setGlobalQuery(current => ({...current, [prop]: value}));
  }

  useEffect(()=>{
    context.load();
  }, []);
  
  return (
    <AppContext.Provider value={{...context, setWriteFetching, writeFetching, setFetching, fetching, smMode, isExpired, claims, core: appCore, globalQuery, setPropGlobalQuery}}>
      <AppRoutes />
    </AppContext.Provider>
  );
}

export default App;