import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppContext } from "../../App";
import { ModalLogin } from "../modals/ModalLogin";
import { Header } from "./Header";
import { SideDrawer } from "./SideDrawer";
import { useJwt } from "react-jwt";
import { Box, Container, CssBaseline, Toolbar } from "@mui/material";
import { useNotificationsContext } from "./Notifications";

export const PrivateLayoutContext = createContext({
  headerContent: "", 
  setHeaderContent: () => {}, 
  subHeaderActions: [], 
  setSubHeaderActions: () => {}, 
  showDrawer: (content)=>{},
  setDrawerContent: (content)=>{},
  hideDrawer: ()=>{},
  drawerState: {show: false, content: <></>},
  showNotification: ({type = "error", message = ""})=>{}
});

export const PrivateLayout = ({children}) =>{
  const [ headerContent, setHeaderContent ] = useState("");
  const [ subHeaderActions, setSubHeaderActions ] = useState([]);
  const [ drawerState, setDrawerState ] = useState({show: false, content: null})
  const [ openMenu, setOpenMenu ] = useState(true);
  const { showNotification } = useNotificationsContext();
  const appContext = useAppContext();
  const jwt = useJwt(appContext.auth);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(()=>{
    const timer = setInterval(()=>{jwt.reEvaluateToken(appContext.auth)}, 3 * 60 * 1000 );
    return ()=>{
      clearInterval(timer);
    }
  }, [jwt, appContext.auth])

  const showDrawer = (content)=>{
    setDrawerState(state => ({...state, show: true, content}))
  }

  const hideDrawer = ()=>{
    setDrawerState(state => ({...state, show: false}))
  }

  const setDrawerContent = (content)=>{
    setDrawerState(state => ({...state, content}))
  }

  return (
    <PrivateLayoutContext.Provider value={{toggleMenu, setOpenMenu, openMenu, showNotification, headerContent, setHeaderContent, subHeaderActions, setSubHeaderActions, drawerState, showDrawer, hideDrawer, setDrawerContent  }}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <ModalLogin show={jwt.isExpired} />
        <SideDrawer/>
        <Header/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container style={{maxWidth: "100%"}} sx={{ mt: 4, mb: 4 }}>
            <Outlet />
          </Container>
        </Box>
      </Box>

    </PrivateLayoutContext.Provider>
  );
}