import { useContext } from "react";
import { useAbm } from "../../services"
import { PrivateLayoutContext } from "../layout-mui";
import { objectEmpty } from "../../utils/Helpers";

export const useLock = ({entity, id, ...others}) => {
  const abm = useAbm({entity});
  const { showNotification } = useContext(PrivateLayoutContext);

  const lock = (params = objectEmpty) => new Promise((resolve, reject) => {
    abm.lock(true, id, undefined, {...others, ...params}).then(result => {
      resolve(result);
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error al bloquear"});
      })
    });
  });

  const unlock = (params = objectEmpty) => new Promise((resolve, reject) => {
    abm.lock(false, id, undefined, {...others, ...params}).then(result => {
      resolve(result);
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error al desbloquear"});
      })
    });
  });


  return { lock, unlock };
}