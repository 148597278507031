import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import Select from 'react-select';
import { ControlWrapper } from "./ControlWrapper";

const objectPath = require("object-path");
const toString = (value) => {
  return typeof value === "string" ? value : value?.toString();
}

export const ControlSelect2 = ({name, label, placeholder = "Seleccione...", onChange, options, data, start, end, errors = [], isMulti = true, Wrapper = ControlWrapper, isClearable=true, type = "number", ...moreOptions }) => {
  
  const getOption = useCallback((value) => {
    if(value && options){
      return options.find(o => toString(o.value) ===  toString(value))
    }
  }, [options]);
  
  const getValue = useCallback((prev) => {
    //console.log(prev)
    if(!prev || !options){
      return isMulti ? [] : null;
    }
    
    const prevValue = isMulti 
    ? (!!prev.map ? prev : prev.split(","))
    : prev;

    return isMulti 
      ? (prevValue.map && prevValue.map(v => options.find(o => toString(o.value) === toString(v))))
      : getOption(data ? prevValue : null);
  }, [isMulti, getOption, data, options]);


  const value = useMemo(() => getValue(objectPath(data).get(name, null)), [getValue, data, name]) ;

  const handleChange = useCallback((value) => {
    onChange({
      target: {
        name, 
        value: value?.map 
          ? value.map(v => type === "number" ? parseInt(v.value || 0) : v.value)
          : (type === "number" ? parseInt(value?.value || 0) : value?.value)
      }
    })
  }, [onChange, type, name]);
  
  return (
    <Wrapper name={name} errors={errors} end={end} start={start} label={label}>
      <Select
        onChange={handleChange}
        closeMenuOnSelect={false}
        value={value}
        isMulti={isMulti}
        options={options}      
        className="flex-grow-1 h-100 w-100"
        isClearable={isClearable}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        {...moreOptions}
        styles={{
          menu:(baseStyles)=>{
            //console.log(baseStyles) 
            return{
            ...baseStyles,
            zIndex: 50000
          }}
        }}
      />
    </Wrapper>
  )
}

const formatOptionLabel = ({ value, label }) => {
  return <Typography color="black">{label}</Typography>
};
