import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { AddCircleIcon } from "../../../icons";

export const AgregarItemGeneralActividad = ({params}) => {
  const { setShowItemGeneralAdd } = useAbmContext();
  const { claims: { rol, centros } } = useAppContext();

  if(!authorize({rol, inRoles: [roles.GESTOR_CENTRAL, roles.LIQUIDADOR, roles.GESTOR, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]})){
    return <></>
  }
  const enabled = rol === roles.GESTOR || rol === roles.GESTOR_CENTRAL || rol === roles.LIQUIDADOR || rol === roles.COORDINADOR || rol === roles.COORDINADOR_CENTRAL;

  const handleClick = () => {
    setShowItemGeneralAdd({fromDetails: true});
  }
  return <ActionButton iconButton enabled={enabled} Icon={AddCircleIcon} onClick={handleClick}/>
}
