import React, { createContext, useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAbm } from "../services/abm";
import { dataToView } from "../components/details/Utils";
import { Card, CardActions, CardContent, Container, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import { ActionButton } from "../components/helpers";
import { CancelIcon, CheckIcon, DetailsIcon } from "../components/icons";
import { PrivateLayoutContext } from "../components/layout-mui/PrivateLayout";
import * as Actions from "../components/details/actions";

const DashboardContext = createContext({refresh: () => {}})
export const useDashboardContext = () => useContext(DashboardContext);

export const Dashboard = () => {
  
  const api = useAbm({entity: "dashboard"});
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { setHeaderContent } = useContext(PrivateLayoutContext);

  useEffect(()=>{
    setHeaderContent("Dashboard");
    loadData();
  }, [])


  const loadData = () => {
    api.getAll({})
      .then(result => {
        setData(result);
      })
  }
  /*la api va a responder con tarjetas que contienen tareas, segun el rol
  * 
  
    DIRECTOR:
      aprobar items de presupuesto que estan en estado pre-aprobados
      -> una tarjeta por cada actividad que tenga items pre-aprobados de todo el sistema
    
    DIRECTOR CENTRO:
      aprobar items de presupuesto que estan en estado pendientes de aprobacion
      -> una tarjeta por cada actividad que tenga items pendientes de aprobacion de su centro

    GESTOR y GESTOR CENTRAL
      carga los items de presupuesto y tiene que corregir problemas en los items rechazados
      -> una tarjeta de acceso directo a actividades
      -> una tarjeta por cada item de presupuesto rechazado

    ADMINISTRATIVO 
      carga las facturas al sistema, y corrige problemas en las facturas rechazadas
      -> una tarjeta de acceso directo a añadir factura
      -> una tarjeta por cada factura rechazada de su centro

    ADMINISTRATIVO CENTRAL
      carga facturas del centro caba y tiene que dar asistencia a los ADMINISTRATIVOS de otros centros para corregir facturas rechazadas
      -> una tarjeta de acceso directo a añadir factura
      -> una tarjeta por cada factura rechazada en el sistema
    
    LIQUIDADOR
      paga las facturas aprobadas, aprueba facturas en estado pre-aprobadas 
      -> una tarjeta de acceso directo a liquidacion
      -> una tarjeta por cada factura en estado pre-aprobada
  */

  const goTo = (to) => {
    navigate(to);
  }

  return (
    <DashboardContext.Provider value={{refresh: loadData}}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {
            data.map(({type, ...item}, index) => 
                <Grid item xs={12} md={6} lg={4} key={`card-${index}`}>
                  <Card>
                    {type === "link" && 
                      <CardLink {...item} goTo={goTo}/>
                    }
                    {type === "task" && 
                      <CardTask {...item} goTo={goTo}/>
                    }
                  </Card>
              </Grid>
            )
          }
        </Grid>
      </Container>
    </DashboardContext.Provider>
  );
};

const CardTask = ({title, subtitle, view, actions, details, goTo}) => {
  return (
    <>
      <CardContent>
        <Typography component="h2" variant="h5" color="primary" gutterBottom>
          {title || "Tarea"}
        </Typography>
        <Typography component="p" variant="h6">
          {subtitle}
        </Typography>
        <div>
          {details && dataToView({data: details})}
        </div>
      </CardContent>
      <CardActions>
        {
          actions.map(action => {
            const ActionComponent = Actions[action.key];
            return <ActionComponent params={action.params} iconButton/>
          })
        }
      </CardActions>
    </>
  )
}

const CardLink = ({title, view, details, goTo}) => {

  return (
    <>
      <CardContent>

        <Typography component="h2" variant="h5" color="primary" gutterBottom>
          Acceso rápido
        </Typography>
        <Typography component="p" variant="h6">
          {title}
        </Typography>
        <div>
          <IconButton onClick={()=>goTo(view)}>
            <ActionButton enabled={true} Icon={DetailsIcon}/>
          </IconButton>
        </div>
      </CardContent>
    </>
  )
}