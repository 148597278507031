import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useAbmContext } from "../../../views/abm/useAbmComponent";
import { ControlText, ControlTextArea } from "../../helpers";
import { ControlSelect2 } from "../../helpers/ControlSelect2";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";

export const FormItemDesignacion = React.forwardRef(({
    item, 
    schema, 
    onCancel, 
    onConfirm, 
    confirmLabel = "Guardar", 
    cancelLabel = "Cancelar", 
    title, 
    errorState, 
    data = {}, 
    loads}, ref) => {

  return (
    <>
      <AbmForm ref={ref} title={title} actionsTop={false} actionsBottom={false} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
        {({onControlChange, entity, entityErrors}) => {
          return (
            <>
              <Grid item xs={12}>
                <ControlText name="concepto" label="Actividad" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
              </Grid>
              <Grid item xs={12}>
                <ControlText name="horas" label="Horas" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
              </Grid>
              <Grid item xs={12}>
                <ControlText name="retribucion_hora" label="Retribución por hora" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
              </Grid>
            </>
          )
        }}
      </AbmForm>
    </>
  );
})