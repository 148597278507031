import { Abm } from "./_Abm";
import { AbmContext } from "./useAbmComponent";

export const AbmWithContext = ({abmComponent, children}) => {
  const {keyState, tabs, loaded, titleTable} = abmComponent;
  return (
    <AbmContext.Provider value={abmComponent}>
      {children}
      <Abm
        keyState={keyState}
        tabs={tabs}
        title={titleTable}
        loading={!loaded} 
      ></Abm>
    </AbmContext.Provider>
  );
}
