import { 
  Centros, Planeamientos,
  Usuarios, Roles, Mpjs,
  Monedas, Perceptores, Actividades,
  Ambitos, Programas, Proyectos,
  Facturas, Liquidaciones, Periodos
} from "./views";
import { AccountBalanceIcon, TheaterComedyIcon, RequestPageIcon, WorkIcon, FilePresentIcon, SchemaIcon, LocalAtmIcon, AddBusinessIcon, GroupsIcon, PersonIcon, PaidIcon, AccountTreeIcon, FeaturedPlayListIcon, ExploreIcon, StyleIcon, CalendarMonthIcon} from "./components/icons";
import { estadosDesignacion, estadosFactura, roles } from "./config/constants";
import { SubProyectos } from "./views/abm/SubProyectos";
import { objectEmpty } from "./utils/Helpers";
import { Designaciones } from "./views/abm/Designaciones";

export const authorize = ({rol, inRoles = []}) => {
  return [...inRoles].some(r => r === rol);
}


const getDefaultPermissions = ({row, rol}) => {
  return {
    add: true,
    details: !!row,
    edit: !!row,
    del: !!row,
  }
} 

export const tabs = ["", ":tab", ":tab/:id"];
export const core = ({claims}) => {

  const rol = claims?.rol;

  const getDefaultPermissionsRestrict = ({row, acceptedRoles}) => {
    return {
      add: authorize({rol, inRoles: [...acceptedRoles]}),
      details: !!row,
      edit: !!row && authorize({rol, inRoles: [...acceptedRoles]}),
      del: !!row && authorize({rol, inRoles: [...acceptedRoles]}),
    }
  }   

  return {
    centros: {
      label: 'Centros',
      component: Centros,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.INVITADO]}),
      icon: AddBusinessIcon,
      permissions: ({row}) => getDefaultPermissionsRestrict({row, acceptedRoles: [roles.LIQUIDADOR, roles.DIRECTOR]})
    },
    actividades: {
      label: 'Actividades',
      component: Actividades,
      visible: authorize({rol, inRoles: [roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.LIQUIDADOR, roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.INVITADO]}),
      icon: TheaterComedyIcon,
      permissions: ({row}) => {
        return {
          add: authorize({rol, inRoles: [roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.LIQUIDADOR, roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]}),
          agregar_item: !!row && authorize({...claims, inRoles: [roles.GESTOR, roles.GESTOR_CENTRAL, roles.LIQUIDADOR, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]}),
          details: !!row,
          edit: !!row && authorize({...claims, inRoles: [roles.GESTOR, roles.GESTOR_CENTRAL, roles.LIQUIDADOR, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]}),
          del: !!row && authorize({...claims, inRoles: [roles.GESTOR, roles.GESTOR_CENTRAL, roles.LIQUIDADOR, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]}),
        }
      }
    },
    facturas: {
      label: 'Facturas',
      component: Facturas,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.DIRECTOR_CENTRO, roles.INVITADO]}),
      icon: RequestPageIcon,
      permissions: ({row}) => {
        return {
          add: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.DIRECTOR_CENTRO]}),
          liquidar: (row?.estado === estadosFactura.APROBADA),
          details: true,
          edit: (row?.estado === estadosFactura.PENDIENTE_APROBACION || row?.estado === estadosFactura.OBSERVADA)
                || (row?.estado === estadosFactura.PRE_APROBADA && claims.rol === roles.ADMINISTRATIVO_CENTRAL),
          del: (row?.estado === estadosFactura.PENDIENTE_APROBACION || row?.estado === estadosFactura.OBSERVADA),
          preaprobar: (row?.estado === estadosFactura.PENDIENTE_APROBACION),
          aprobar: row?.estado === estadosFactura.PRE_APROBADA,
          rechazar: (claims.rol === roles.LIQUIDADOR && row?.estado === estadosFactura.PRE_APROBADA) 
                  || (claims.rol === roles.ADMINISTRATIVO_CENTRAL && row?.estado === estadosFactura.PENDIENTE_APROBACION),
        }
      }
    },
    liquidaciones: {
      label: 'Liquidaciones',
      component: Liquidaciones,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.INVITADO]}),
      icon: PaidIcon,
      permissions: ({row}) => getDefaultPermissionsRestrict({row, acceptedRoles: [roles.LIQUIDADOR, roles.DIRECTOR]})
    },
    perceptores: {
      label: 'Perceptores',
      component: Perceptores,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.INVITADO]}),
      icon: WorkIcon,
      permissions: ({row}) => getDefaultPermissionsRestrict({row, acceptedRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]})
    },
    designaciones: {
      label: 'Designaciones',
      component: Designaciones,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO_CENTRAL, roles.GESTOR, roles.GESTOR_CENTRAL, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.DIRECTOR_CENTRO, roles.INVITADO]}),
      icon: FilePresentIcon,
      permissions: ({row}) => {
        return {
          details: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]}),
          del: !!row && (row.estado === estadosDesignacion.OBSERVADA || row.estado === estadosDesignacion.PENDIENTE_APROBACION),
          add:  authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]}),
          edit: !!row && (row.estado === estadosDesignacion.OBSERVADA || row.estado === estadosDesignacion.PENDIENTE_APROBACION),
        }
      },
    },
    periodos: {
      label: 'Períodos',
      component: Periodos,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.INVITADO]}),
      icon: CalendarMonthIcon,
      permissions: ({row}) => {
        return {
          details: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.INVITADO, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL, roles.COORDINADOR_CENTRAL, roles.COORDINADOR]}),
          del: !!row && claims.rol === roles.LIQUIDADOR,
          add: claims.rol === roles.LIQUIDADOR,
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      }    
    },
    planeamientos: {
      label: 'Planeamientos',
      component: Planeamientos,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.ADMINISTRATIVO_CENTRAL, roles.ADMINISTRATIVO, roles.COORDINADOR_CENTRAL, roles.COORDINADOR, roles.INVITADO]}),
      icon: ExploreIcon,
      permissions: ({row}) => {
        return {
          details: !!row && authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.ADMINISTRATIVO_CENTRAL, roles.ADMINISTRATIVO, roles.COORDINADOR_CENTRAL, roles.COORDINADOR, roles.INVITADO]}),
          del: !!row && claims.rol === roles.LIQUIDADOR,
          add: claims.rol === roles.LIQUIDADOR,
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      }    
    },
    ambitos: {
      label: 'Ambitos',
      component: Ambitos,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.DIRECTOR_CENTRO, roles.INVITADO]}),
      icon: SchemaIcon,
      permissions: ({row}) => {
        return {
          details: true,
          del: !!row && claims.rol === roles.LIQUIDADOR,
          add: claims.rol === roles.LIQUIDADOR,
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      }
    },
    programas: {
      label: 'Programas',
      component: Programas,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.DIRECTOR_CENTRO, roles.INVITADO]}),
      icon: AccountTreeIcon,
      permissions: ({row}) => {
        return {
          details: true,
          del: !!row && claims.rol === roles.LIQUIDADOR,
          add: claims.rol === roles.LIQUIDADOR,
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      }
    },
    proyectos: {
      label: 'Proyectos',
      component: Proyectos,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.DIRECTOR_CENTRO, roles.INVITADO]}),
      icon: FeaturedPlayListIcon,
      permissions: ({row}) => {
        return {
          details: true,
          del: !!row && claims.rol === roles.LIQUIDADOR,
          add: claims.rol === roles.LIQUIDADOR,
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      }
    },
    sub_proyectos: {
      label: 'Sub-Proyectos',
      component: SubProyectos,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.DIRECTOR_CENTRO, roles.INVITADO]}),
      icon: StyleIcon,
      permissions: ({row}) => {
        return {
          details: true,
          del: !!row && claims.rol === roles.LIQUIDADOR,
          add: claims.rol === roles.LIQUIDADOR,
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      }
    },
    monedas: {
      label: 'Monedas',
      component: Monedas,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO_CENTRAL, roles.INVITADO]}),
      icon: LocalAtmIcon,
      permissions: ({row}) => {
        return {
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      } 
    },
    mpjs: {
      label: 'Mpjs',
      component: Mpjs,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.ADMINISTRATIVO_CENTRAL, roles.ADMINISTRATIVO, roles.COORDINADOR_CENTRAL, roles.COORDINADOR, roles.INVITADO]}),
      icon: AccountBalanceIcon,
      permissions: ({row}) => {
        return {
          details: !!row && authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.ADMINISTRATIVO_CENTRAL, roles.ADMINISTRATIVO, roles.COORDINADOR_CENTRAL, roles.COORDINADOR, roles.INVITADO]}),
          del: !!row && claims.rol === roles.LIQUIDADOR,
          add: claims.rol === roles.LIQUIDADOR,
          edit: !!row && claims.rol === roles.LIQUIDADOR,
        }
      }
    },
    usuarios: {
      label: 'Usuarios',
      component: Usuarios,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.INVITADO]}),
      icon: PersonIcon,
      permissions: ({row}) => getDefaultPermissionsRestrict({row, acceptedRoles: [roles.LIQUIDADOR, roles.DIRECTOR]})
    },
    roles: {
      label: 'Roles',
      component: Roles,
      visible: authorize({rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.INVITADO]}),
      icon: GroupsIcon,
      permissions: ({row}) => {
        return {
          edit: false,
          add: false,
          details: true,
          del: false,
        }
      } 
    },
  }
}

export const getQueryData = (searchParams, globalQuery = objectEmpty) => {
  return {
    term: "", 
    ...Array.from(searchParams.entries()).reduce((prev, curr) => ({...prev, [curr[0]]: curr[1]}), {}),
    ...globalQuery
  };
};

// return Object.keys(globalPermissions.actividades).reduce((prev, key) => {
//   return {
//     ...prev,
//     [key]
//   }
// }, {})