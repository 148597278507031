import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { EditIcon } from "../../../icons";

//GESTOR Y GESTOR CENTRAL pueden editar Items que esten en estado RECHAZADOS o PENDIENTES DE APROBACION
//al guardar el item pasa a estado PENDIENTE DE APROBACION si es un GESTOR y a PRE-APROBADO si es un GESTOR CENTRAL

export const EditarItem = ({params}) => {
  const { setShowItemPresupuestoEdit } = useAbmContext();
  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.GESTOR, roles.GESTOR_CENTRAL, roles.ADMINISTRATIVO_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]})){
    return <></>
  }
  const enabled = !params.id_factura && (
       (rol === roles.GESTOR || rol === roles.GESTOR_CENTRAL || rol === roles.ADMINISTRATIVO_CENTRAL || rol === roles.COORDINADOR || rol === roles.COORDINADOR_CENTRAL) && (params.estado === estadosItem.PENDIENTE_APROBACION || params.estado === estadosItem.OBSERVADO || params.estado === estadosItem.OBSERVADO_LOCAL) 
       || (rol === roles.GESTOR_CENTRAL || rol === roles.ADMINISTRATIVO_CENTRAL || rol === roles.COORDINADOR_CENTRAL ) && (params.estado === estadosItem.PRE_APROBADO) 
  );
  const handleClick = () => {
    setShowItemPresupuestoEdit(params.id);
  }
  return <ActionButton iconButton enabled={enabled} Icon={EditIcon} onClick={handleClick}/>
}
