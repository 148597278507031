import * as yup from 'yup';
import { FormItemPresupuesto } from "../../components/abm/actividades/FormItemPresupuesto";
import { Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DeleteIcon, SaveIcon } from '../icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


const schemaItemPresupuestoAdd = yup.object().shape({
  importe: yup.number().positive().required().label("Importe"),
  concepto: yup.string().required().label("Concepto"),
  id_perceptor: yup.number().required().label("Perceptor"),
  id_moneda: yup.number().required().label("Moneda"),
  id_item_general: yup.number().nullable().optional().label("Item general")
});

export const ModalActividadesCreateItem = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, updateItem, loadDetails, params}) => {

  const [data, setData] = useState({});
  const [show, setShow] = showState;
  const formRef = useRef();

  const loads = () => {
    abmActions.getAll({}, "perceptores/options")
    .then(response => {
      setData(data => ({...data, perceptores: response.results}));
    })
    .catch(reason => {
      console.warn(reason);
    });
    abmActions.getAll({}, "monedas/options")
    .then(response => {
      setData(data => ({...data, monedas: response.results}));
    })
    .catch(reason => {
      console.warn(reason);
    });
    abmActions.getAll({}, `items_generales/options_actividad/${selectedItem?.id || 0}`)
    .then(response => {
      setData(data => ({...data, items_generales: response.results}));
    })
    .catch(reason => {
      console.warn(reason);
    });
  }
  
  useEffect(()=>{
    show && loads();
  }, [show])


  const handleCancel = () => {
    setShow(false);
  }
  
  const handleConfirm = (data) => {
    abmActions.create(data, "items").then(result => {
      loadDetails(selectedItem.id);
      handleCancel();
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }


  return (
    <>      
      <Dialog open={show} onClose={handleCancel} maxWidth="xl" PaperProps={{sx: {overflow: "visible"}}}>
        <DialogTitle>Agregar item de presupuesto</DialogTitle>
        <DialogContent sx={{padding: 0, overflow: "visible"}}>
          <FormItemPresupuesto 
            onConfirm={handleConfirm}
            ref={formRef}
            item={{
              id_presupuesto: selectedItem?.id_presupuesto,
            }}
            data={data}
            schema={schemaItemPresupuestoAdd} 
            loads={loads}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
            Guardar
          </Button>      
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancelar
          </Button>   
        </DialogActions>
      </Dialog>
    </>
  )
}