import React, { useEffect, useRef } from "react";
import { ControlWrapper } from "./ControlWrapper";
const objectPath = require("object-path");

export const ControlCheckbox = ({name, label, placeholder, onChange, data, start, end, errors = [], Wrapper = ControlWrapper, errorPlacement = "right", ...options }) => {
  const inputRef = useRef();
  const value = data ? objectPath(data).get(name, false) : false;

  useEffect(()=>{
    if(options && options.autoFocus){
      inputRef.current.focus();
    }
  }, []);

  const handleChange = () => {
    onChange({target:{name, value: !value}})
  }

  return (
    <Wrapper name={name} errors={errors} end={end} start={start} label={label} errorPlacement={errorPlacement}>
      <input ref={inputRef} id={name} name={name} checked={value} value={1} onChange={handleChange} type="checkbox" className="form-check-input" />
    </Wrapper>
  )
}