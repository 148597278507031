import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { CancelIcon } from "../../../icons";

//DIRECTOR y LIQUIDADOR pueden rechazar Items que esten en estado PRE-APROBADOS de sus centros o APROBADOS de cualquier centro
//DIRECTOR DE CENTRO pueden rechazar Items que esten en estado PENDIENTES_APROBACION o PRE-APROBADOS del Centro que dirigen

export const RechazarItem = ({params}) => {
  const { setShowItemPresupuestoRechazo } = useAbmContext();
  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.DIRECTOR, roles.DIRECTOR_CENTRO, roles.LIQUIDADOR]})){
    return <></>
  }
  //console.log({centros})
  const enabled = !params.id_factura && (
       (rol === roles.DIRECTOR_CENTRO && (params.estado === estadosItem.PENDIENTE_APROBACION || params.estado === estadosItem.PRE_APROBADO) && centros.some(c => c === params.id_centro)) 
    || ((rol === roles.DIRECTOR || rol === roles.LIQUIDADOR) && (params.estado === estadosItem.APROBADO || (params.estado === estadosItem.PRE_APROBADO)))
  );
  const handleClick = () => {
    setShowItemPresupuestoRechazo(params.id);
  }
  return <ActionButton iconButton enabled={enabled} Icon={CancelIcon} onClick={handleClick}/>
}
