import * as yup from 'yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormItemDesignacion } from '../abm/designaciones/FormItemDesignacion';




export const ModalDesignacionesCreateItemDesignacion = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, updateItem, loadDetails, params}) => {

  const [data, setData] = useState({});
  const [show, setShow] = showState;
  const formRef = useRef();

  const schema = useMemo(() => yup.object().shape({
    concepto: yup.string().required().label("Actividad"),
    horas: yup.number().positive().required().label("Horas"),
    retribucion_hora: yup.number().positive().required().label("Retribución por hora")
  }), []);

  const handleCancel = () => {
    setShow(false);
  }
  
  const handleConfirm = (data) => {
    updateItem(data);
  }


  return (
    <>      
      <Dialog open={show} onClose={handleCancel} maxWidth="xl" PaperProps={{sx: {overflow: "visible"}}}>
        <DialogTitle>Agregar item de designación</DialogTitle>
        <DialogContent sx={{padding: 0, overflow: "visible"}}>
          <FormItemDesignacion
            onConfirm={handleConfirm}
            ref={formRef}
            item={{
              concepto: "", 
              horas: "", 
              retribucion_hora: "", 
              remove: false, 
              id: 0, 
              id_designacion: 0
            }}
            data={data}
            schema={schema} 
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
            Guardar
          </Button>      
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancelar
          </Button>   
        </DialogActions>
      </Dialog>
    </>
  )
}