import { Collapse, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { abm } from "../../../services/abm";
import { ControlSelect, ControlText, Decorator, ControlTags, ControlCheckbox, ControlUploadFile, ControlDatePicker, ErrorPopover, ControlTextArea } from "../../helpers";
import { ControlSelect2 } from "../../helpers/ControlSelect2";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";
import * as immutable from 'object-path-immutable'
import { ModalDesignacionesCreateItemDesignacion } from "../../modals";
import { arrayEmpty, objectEmpty } from "../../../utils/Helpers";
const isEqual = require("react-fast-compare");

const tiposRetencionesOptions = [
  {value: 7, label: "7%"},
  {value: 15, label: "15%"},
  {value: 0, label: "Sin retención"},
]

export const Form = React.forwardRef(({
  item, 
  actionsBottom = true, 
  schema, 
  onCancel, 
  onConfirm, 
  confirmLabel = "Guardar", 
  cancelLabel = "Cancelar", 
  title, 
  errorState, 
  data = {}}, ref) => {
  return (
    <AbmForm ref={ref} title={title} actionsTop={false} actionsBottom={actionsBottom} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <AbmFormContent {...{onControlChange, entity, entityErrors, data}}/>
      )}
    </AbmForm>
  );
})

const AbmFormContent = ({onControlChange, entity, entityErrors, data}) => {

  const [showItemDesignacion, setShowItemDesignacion] = useState(false);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({concepto: "", horas: "", retribucion_hora: "", remove: false, id: 0, id_designacion: 0});
  const itemsRef = useRef();
  
  useEffect(()=>{
    if(!!entity?.items_designacion){
      setItems(current => isEqual(entity?.items_designacion, current) ? current : entity?.items_designacion);
    }
  }, [entity?.items_designacion])

  useEffect(()=>{
    onControlChange({target: {name: "items_designacion", value: items}});
  }, [items]);

  const addItem = (event) => {
    setItems(current => [...current, newItem])
    setTimeout(() => {
      setNewItem({concepto: "", horas: "", retribucion_hora: "", remove: false, id: 0, id_designacion: 0});
    }, 10);
  }

  const handleUpdateItem = (data) => {
    setItems(current => [...current, data]);
    setShowItemDesignacion(false);
  }

  const removeItem = (event, item) => {
    event.preventDefault();
    const i = items.indexOf(item);
    if(item.id === 0){
      setItems(current => current.filter((item, index) => index !== i));
    }else{
      setItems(current => current.map((item, index) => index !== i ? item : {...item, remove: true}));
    }
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    //console.log({name, value});
    setNewItem(current => immutable.set(current, name, value));
  }

  const perceptor = useMemo(() => {
    if(!data?.perceptores || !entity){
      return objectEmpty;
    }
    return data.perceptores.find(perceptor => parseInt(perceptor.value) === entity.id_perceptor);
  }, [entity?.id_perceptor, data?.perceptores])

  useEffect(()=>{
    onControlChange({target: {name: "cuit", value: perceptor ? perceptor.cuit : null}});
  }, [entity?.id_perceptor])

  const totalBruto = useMemo(() => items.reduce((prev, item) => prev + parseFloat(item.horas) * parseFloat(item.retribucion_hora), 0), [items]);
  const retencionTotal = useMemo(() => entity ? totalBruto * (entity?.retencion) / 100 : 0, [totalBruto, entity?.retencion]);
  const totalNeto = useMemo(() => totalBruto - retencionTotal, [totalBruto, retencionTotal]);

  return (

    <>
      <ModalDesignacionesCreateItemDesignacion showState={[showItemDesignacion, setShowItemDesignacion]} selectedItem={null} updateItem={handleUpdateItem}/>

      <Grid item xs={12}>
        <ControlSelect2 name="id_perceptor" autoFous label="Perceptor" errors={entityErrors} data={entity} onChange={onControlChange} options={data?.perceptores || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12} marginLeft={10} marginY={2}>
        <Collapse in={!!entity?.id_perceptor}>
          <Grid container>
            <Grid item xs={12}>  
              <ControlText name="cuit" label="CUIT" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
            </Grid>  
          </Grid>
        </Collapse>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="id_actividad" label="Actividad" isDisabled={entity?.revision} errors={entityErrors} data={entity} onChange={onControlChange} options={data?.actividades || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlDatePicker name="fecha" label="Fecha del documento" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlText name="lugar" label="Lugar" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>
      <Grid item xs={12}>
        <ControlText name="fechas" label="Fechas" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      <Grid item xs={12}>
        <ControlText name="objeto" label="Objeto" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>
      <Grid item xs={12}>
        <ControlSelect2 name="retencion" label="Retención" type="number" errors={entityErrors} data={entity} onChange={onControlChange} options={tiposRetencionesOptions} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>
      <Grid item xs={12} ref={itemsRef}>
        <ControlSmallWrapper label="Items">

          {items && 

            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Actividad</TableCell>
                    <TableCell>Horas</TableCell>
                    <TableCell>Retribución hora</TableCell>
                    <TableCell>Sub-total</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.length > 0 && items.filter(item => !item.remove).map(item => 
                    <TableRow>
                      <TableCell>{item.concepto}</TableCell>
                      <TableCell align="right">{item.horas}</TableCell>
                      <TableCell align="right">{item.retribucion_hora}</TableCell>
                      <TableCell align="right">{parseFloat(item.horas) * parseFloat(item.retribucion_hora)}</TableCell>
                      <TableCell><a href="javascript:" onClick={event => removeItem(event, item)}>Eliminar</a></TableCell>
                    </TableRow>
                  )}
                  {items.length > 0 && 
                    <TableRow>
                      <TableCell colSpan="5">
                        <a href="javascript:" onClick={event => setShowItemDesignacion(true)}>Agregar item</a>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
                <TableFooter>
                  {items.length === 0 && 
                    <TableRow>
                      <TableCell colSpan="5">
                        No se cargaron items aun, haga <a href="javascript:" onClick={event => setShowItemDesignacion(true)}>click aquí</a> para agregar uno.
                      </TableCell>
                    </TableRow>
                  }
                  {items.length > 0 &&
                    <>
                      <TableRow>
                        <TableCell align="right" colSpan="3">Total bruto</TableCell>
                        <TableCell align="right">{totalBruto}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right" colSpan="3">Total retención</TableCell>
                        <TableCell align="right">{retencionTotal}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right" colSpan="3">Total neto</TableCell>
                        <TableCell align="right">{totalNeto}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  }
                </TableFooter>
              </Table>
            </TableContainer>
          }
        </ControlSmallWrapper>
      </Grid>
      <ErrorPopover anchorEl={itemsRef.current} name="items_designacion" />
      <Grid item xs={12} marginY={1}>
        IMPORTE MAXIMO TOTAL AUTORIZADO PARA GASTOS DE VIAJE EN EUROS
      </Grid>
      <Grid item xs={12}>
        <ControlText name="maximo_avion" label="En concepto de gastos de transporte en avión" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>
      <Grid item xs={12}>
        <ControlText name="maximo_alojamiento" label="En concepto de alojamiento" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>
      <Grid item xs={12}>
        <ControlText name="maximo_taxi" label="En concepto de taxi (hasta / desde aeropuerto)" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>
      <Grid item xs={12}>
        <ControlText name="maximo_manutencion" label="En concepto de manutención" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      {entity?.id && entity?.revision && 
        <Grid item xs={12}>
          <ControlText name="observacion" label="Observacion" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
        </Grid>  
      }
    </>
  )
}