import React from "react";
import { Grid } from "@mui/material";
import { ControlText, ControlTextArea, ControlSelect2 } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";


export const Form = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}) => {

  return (
    <AbmForm title={title} actionsTop={false} actionsBottom={true} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <>
          <Grid item xs={12}>
            <ControlText name="nombre" label="Nombre" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlTextArea name="descripcion" label="Descripcion" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlSelect2 name="id_ambito" label="Ambito" errors={entityErrors} data={entity} onChange={onControlChange} options={data.ambitos || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
          </Grid>
        </>
      )}
    </AbmForm>
  );
}