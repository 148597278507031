import React, { useState, useContext, useEffect, createContext, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as colors from './colors';
import { CssBaseline } from '@mui/material';

/*
    --bs-primary: #5cb8b2;
    --bs-secondary: #363b3f;
    --bs-success: green;
    --bs-info: #0dcaf0;
    --bs-warning: #e09244;
    --bs-danger: #e14d43;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    */
const fnTheme = (mode, color) => ({
  palette: {
    mode: mode,
    primary: {
      ...color,
      ...(mode === 'dark' && {
        main: color[100],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: color[300],
        paper: color[800],
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: colors.grey[900],
            secondary: colors.grey[800],
          }
        : {
            primary: '#fff',
            secondary: colors.grey[500],
          }),
    },
    oddTr: {
      main: mode === "light" ? colors.grey[100] : color[600],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    evenTr: {
      main: mode === "light" ? colors.grey[200] : color[500],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    selectedTr: {
      main: mode === "light" ? colors.grey[500] : color[700],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    bgForm: {
      main: mode === "light" ? colors.grey[100] : color[400],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
})

/**
 * 
 * @param {("amber","blue","blueGrey","brown","common","cyan","deepOrange","deepPurple","green","grey","indigo","lightBlue","lightGreen","lime","orange","pink","purple","red","teal","yellow")} themeName 
 * @param {("dark"|"light")} themeMode 
 * @returns 
 */
const getThemeMode = (themeColor, themeMode) => {
  const color = colors[themeColor];
  const theme = fnTheme(themeMode, color);
  return theme;
};

const themeColors = Object.keys(colors).map(key => ({name: key, color: colors[key][900]}));
const ThemeProviderContext = createContext({selectThemeMode: (mode = "") => {}, themeMode: "", selectThemeColor: (color = "") => {}, themeColor: {name: "", color: ""}, themeColors});
export const useThemeProviderContext = () => useContext(ThemeProviderContext);

export const Theme = ({children}) => {
  const [ themeMode, setThemeMode ] = useState("dark");
  const [ themeColor, setThemeColor ] = useState({name: "blueGrey", color: colors.blueGrey[900]});

  useEffect(()=>{
    const storageThemeMode = localStorage.getItem("themeMode");
    if(storageThemeMode){
      setThemeMode(storageThemeMode);
    }
    const storageThemeColor = localStorage.getItem("themeColor");
    if(storageThemeColor && themeColors.some(item => item.name === storageThemeColor)){
      setThemeColor(themeColors.find(item => item.name === storageThemeColor));
    }
  }, []);
  
  const theme = useMemo(() => createTheme(getThemeMode(themeColor.name, themeMode)), [themeMode, themeColor]);

  /**
   * @param {("light"|"dark")} themeName 
   */
  const selectThemeMode = (mode) => {
    localStorage.setItem("themeMode", mode);
    setThemeMode(mode);
  }

  const selectThemeColor = (color) => {
    localStorage.setItem("themeColor", color);
    setThemeColor(themeColors.find(item => item.name === color));
  }

  return (
    <ThemeProviderContext.Provider value={{selectThemeMode, selectThemeColor, themeMode, themeColor, themeColors}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeProviderContext.Provider>
  );
}