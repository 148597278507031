import { useNavigate } from "react-router-dom";
import { ActionButton } from "../../helpers";
import { LaunchIcon, DownloadIcon } from "../../icons";
import { Badge } from "@mui/material";
import React, { useMemo } from "react";
import { useAbmContext } from "../../../views/abm/useAbmComponent";

export const Navigate = ({params, ...props}) => {
  const navigate = useNavigate();
  const { abmActions } = useAbmContext();
  const icons = {
    download: DownloadIcon,
    link: LaunchIcon
  }
  const handleClick = () => {
    if(params.download){
      abmActions.download(params.endPoint, params.fileName);
    }else{
      navigate(params.to)
    }
  }

  const Root = useMemo(() => {
    return params?.badge 
      ? ({children})=><Badge sx={{mx: 1}} badgeContent={params.badge} color="primary">{children}</Badge>
      : React.Fragment;
  }, [])
  
  return (
    <Root>
      <ActionButton caption={params.caption} iconButtonSize={params.size || "small"} {...props} enabled={true} Icon={icons[params.type || 'link']} onClick={handleClick }/>
    </Root>
  );
}