import { useAppContext } from "../../../App";
import { useAbmContext } from "../../../views/abm/useAbmComponent";
import { ActionButton } from "../../helpers";
import { LockOpenOutlinedIcon } from "../../icons";
import { useLock } from "../../hooks";
import { ModalObservacion } from "../../modals";
import { useState } from "react";
import { useMemo } from "react";

export const Unlock = ({params}) => {

  const { loadDetails, selectedItem, abmActions } = useAbmContext();
  const { unlock } = useLock({entity: params.entity || abmActions?.entity || "", ...(params || {})});
  const { claims: { rol } } = useAppContext();
  const [ showMotivo, setShowMotivo ] = useState(false);
  const data = useMemo(()=>({text: params.observacion}))

  const handleClick = () => {
    if(!!params?.conMotivo){
      setShowMotivo(true);
    }else{
      unlock()
        .then(result => {
          loadDetails(selectedItem.id);
        })
    }
  }
  
  const handleSendData = (motivo) => {
    unlock({motivo})
      .then(result => {
        loadDetails(selectedItem.id);
      })
  }

  return (
    <>
      <ModalObservacion title="" caption="Ingrese una observación" onConfirm={handleSendData} showState={[ showMotivo, setShowMotivo ]} data={data}/>
      <ActionButton iconButton enabled={true} Icon={LockOpenOutlinedIcon} onClick={handleClick }/>
    </>
  );

}