import { Form } from "../../components/abm/actividades/Form";
import * as yup from 'yup';
import { Abm } from "./_Abm";
import { AbmContext, useAbmComponent } from "./useAbmComponent";
import { useMemo, useState } from "react";
import { ModalActividadesCreateItem } from "../../components/modals/ModalActividadesCreateItem";
import { ActionButton } from "../../components/helpers";
import { ModalItemPresupuestoRechazo } from "../../components/modals/ModalItemPresupuestoRechazo";
import { ModalActividadesEditItem } from "../../components/modals/ModalActividadesEditItem";
import { AddIcon, DeleteIcon, DetailsIcon, EditIcon } from "../../components/icons";
import { ModalActividadesCreateDesignacion, ModalActividadesCreateFactura } from "../../components/modals";
import { ModalActividadesCreateItemGeneral } from "../../components/modals/ModalActividadesCreateItemGeneral";
import { ModalActividadesEditItemGeneral } from "../../components/modals/ModalActividadesEditItemGeneral";
import { SearchForm } from "../../components/abm/actividades/SearchForm";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().max(150).label("Nombre").required(), 
    descripcion: yup.string().label("Descripción").required(), 
    marco: yup.string().max(150).label("Marco"),
    lugar: yup.string().max(255).label("Lugar").required(),
    id_mpj: yup.number().label("MPJ").nullable().required().default(null),
    id_centro: yup.number().label("Centro").nullable().required().default(null),
    id_ambito: yup.number().label("Ambito").nullable().required().default(null),
    id_programa: yup.number().label("Programa").nullable().required().default(null),
    id_proyecto: yup.number().label("Proyecto").nullable().required().default(null),
    id_sub_proyecto: yup.number().label("Sub-Proyecto").nullable().required().default(null),
    fecha_evento: yup.object().shape({
      type: yup.string().label("Duración").required(),
      value: yup.array().label("Fecha").of(yup.string().nullable()).nullable().required()
    }).nullable().required()
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().max(150).label("Nombre").required(), 
    descripcion: yup.string().label("Descripción").required(), 
    marco: yup.string().max(150).label("Marco"),
    lugar: yup.string().max(255).label("Lugar").required(),
    id_mpj: yup.number().label("MPJ").nullable().required().default(null),
    id_centro: yup.number().label("Centro").nullable().required().default(null),
    id_ambito: yup.number().label("Ambito").nullable().required().default(null),
    id_programa: yup.number().label("Programa").nullable().required().default(null),
    id_proyecto: yup.number().label("Proyecto").nullable().required().default(null),
    id_sub_proyecto: yup.number().label("Sub-Proyecto").nullable().required().default(null),
    fecha_evento: yup.object().shape({
      type: yup.string().label("Duración").required(),
      value: yup.array().label("Fecha").of(yup.string().nullable()).nullable().required()
    }).nullable().required()
  })
});
const entity = "actividades";
const titleTable = "Actividades";
const itemName = "Actividad";
const itemDescription = item => `${itemName} ${item?.nombre || ''}`;

const columns = [
  {field: "nombre", caption: "Nombre"},
  {field: "responsable", caption: "Responsable", width: 150},
  {field: "fecha", caption: "Fecha", width: 150},
  {field: "centro", caption: "Centro Cultural", width: 150},
  {field: "lugar", caption: "Lugar", width: 150},
  {field: "no_asignado", caption: "Compr. no asig.", width: 100, align: "right"},
  {field: "asignado", caption: "Compr. asig.", width: 100, align: "right"},
  {field: "liquidado", caption: "Liquidado", width: 100, align: "right"},
  {field: "items", caption: "Items", width: 80},
  {field: "_actions_", caption: "Acciones", width: 120}
];

const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "centros/options")
    .then( data => {
      resolve({...data, name: "centros"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "ambitos/options_actividades")
    .then( data => {
      resolve({...data, name: "ambitos"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  /*({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "mpjs/options")
    .then( data => {
      resolve({...data, name: "mpjs"});
    })
    .catch(reason => {
      reject(reason);
    })
  })*/
];

export const Actividades = ({abmKey}) => {

  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const [showItemPresupuestoAdd, setShowItemPresupuestoAdd] = useState(false);
  const [showItemPresupuestoRechazo, setShowItemPresupuestoRechazo] = useState(false);
  const [showItemPresupuestoEdit, setShowItemPresupuestoEdit] = useState(false);
  const [showItemGeneralAdd, setShowItemGeneralAdd] = useState(false);
  const [showItemGeneralEdit, setShowItemGeneralEdit] = useState(false);
  const [showCargarFacturaItem, setShowCargarFacturaItem] = useState(false); 
  const [idItemPresupuesto, setIdItemPresupuesto] = useState(0);
  const [params, setParams] = useState({});

  const [showDesignacionAdd, setShowDesignacionAdd] = useState(false);
  const [showDesignacionEdit, setShowDesignacionEdit] = useState(false);


  const fnActions = ({goTo, handleActionRemove, getPermissions}) => ({row, isSelected}) => {
    const {details, edit, del} = getPermissions({row});
    return [
      {name: "details", hide: !details, caption: "", icon: <ActionButton enabled={isSelected && details} Icon={DetailsIcon} onClick={()=>goTo(`details/${row.id}`)}/>},
      {name: "edit", hide: !edit, caption: "", icon: <ActionButton enabled={isSelected && edit} Icon={EditIcon} onClick={()=>goTo(`edit/${row.id}`)}/>},
      {name: "del", hide: !del, caption: "", icon: <ActionButton enabled={isSelected && del} Icon={DeleteIcon} colorEnabled={"var(--bs-red)"} onClick={handleActionRemove} />},    
    ]
  }
  
  const handleClickItemPresupuestoAdd = (params) => {
    setParams(params);
    setShowItemPresupuestoAdd(true);
  }

  const handleClickItemPresupuestoRechazo = (id) =>{
    setShowItemPresupuestoRechazo(true);
    setIdItemPresupuesto(id);
  }

  const handleClickItemPresupuestoEdit = (id) =>{
    setShowItemPresupuestoEdit(true);
    setIdItemPresupuesto(id);
  }
  
  const handleClickCargarFacturaItem = (params) =>{
    setParams(params);
    setShowCargarFacturaItem(true);
  }

  const handleClickItemGeneralAdd = () => {
    setShowItemGeneralAdd(true);
  }

  const handleClickItemGeneralEdit = (params) =>{
    setShowItemGeneralEdit(true);
    setParams(params)
  }

  const handleClickDesignacionAdd = (params) => {
    setShowDesignacionAdd(true);
    setParams(params)
  }

  const handleClickDesignacionEdit = (params) =>{
    setShowDesignacionEdit(true);
    setParams(params)
  }

  const abmComponent = useAbmComponent({abmKey, fnActions, loads, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, SearchFormComponent: SearchForm});
  const { keyState, tabs, loaded, selectedItem, abmActions, showNotification, updateItem, loadDetails } = abmComponent;

  return (
    <AbmContext.Provider value={{
      ...abmComponent, 
      setShowItemPresupuestoRechazo: handleClickItemPresupuestoRechazo, 
      setShowItemPresupuestoEdit: handleClickItemPresupuestoEdit,
      setShowCargarFacturaItem: handleClickCargarFacturaItem,
      setShowItemPresupuestoAdd: handleClickItemPresupuestoAdd,
      setShowItemGeneralAdd: handleClickItemGeneralAdd,
      setShowItemGeneralEdit: handleClickItemGeneralEdit,
      setShowDesignacionAdd: handleClickDesignacionAdd,
      setShowDesignacionEdit: handleClickDesignacionEdit,
    }}>
      <ModalActividadesCreateItem showState={[showItemPresupuestoAdd, setShowItemPresupuestoAdd]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} loadDetails={loadDetails} updateItem={updateItem} params={params}/>
      <ModalItemPresupuestoRechazo showState={[showItemPresupuestoRechazo, setShowItemPresupuestoRechazo]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} loadDetails={loadDetails} idItemPresupuesto={idItemPresupuesto}/>
      <ModalActividadesEditItem showState={[showItemPresupuestoEdit, setShowItemPresupuestoEdit]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} loadDetails={loadDetails} idItemPresupuesto={idItemPresupuesto}/>
      <ModalActividadesCreateFactura  showState={[showCargarFacturaItem, setShowCargarFacturaItem]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} loadDetails={loadDetails} updateItem={updateItem} params={params} />
      <ModalActividadesCreateItemGeneral showState={[showItemGeneralAdd, setShowItemGeneralAdd]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} loadDetails={loadDetails}/>
      <ModalActividadesEditItemGeneral showState={[showItemGeneralEdit, setShowItemGeneralEdit]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} loadDetails={loadDetails} idItem={params?.id}/>
      <ModalActividadesCreateDesignacion showState={[showDesignacionAdd, setShowDesignacionAdd]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} loadDetails={loadDetails} updateItem={updateItem} params={params} />
      
      <Abm 
        keyState={keyState}
        tabs={tabs}
        title={titleTable}
        loading={!loaded}
      />
    </AbmContext.Provider>
  );
}