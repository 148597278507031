import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { abm } from "../../../services/abm";
import { ControlSelect, ControlText, Decorator, ControlTags, ControlCheckbox, ControlUploadFile } from "../../helpers";
import { ControlSelect2 } from "../../helpers/ControlSelect2";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";


export const Form = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}) => {

  return (
    <AbmForm title={title} actionsTop={false} actionsBottom={true} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <>
          <Grid item xs={12}>
            <ControlText name="identificacion" label="Identificacion" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="cuit" label="CUIT" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="cbu_alias" label="CBU / Alias" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="domicilio" label="Domicilio" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="telefono" label="Teléfono" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="celular" label="Celular" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlText name="email" label="Email" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlUploadFile name="constancia_cbu" label="Constancia de CBU" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}>
            <ControlUploadFile name="constancia_fiscal" label="Constancia de Identificación Fiscal" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
        </>
      )}
    </AbmForm>
  );
}