import { Form } from "../../components/abm/liquidaciones/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { SearchForm } from "../../components/abm/liquidaciones/SearchForm";
import { useMemo } from "react";

export const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    fecha_pago: yup.date().label("Fecha de pago").required(),
    id_factura: yup.number().label("Factura").required(),
    id_mpj: yup.number().label("MPJ").required(),
    importe: yup.number().label("Importe").required().positive(),
    tipoCambio: yup.number().label("Tipo de cambio").required().positive(),
    comprobante: yup.object().label("Comprobante").nullable().shape({}).required(),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    fecha_pago: yup.date().label("Fecha de pago").required(),
    id_factura: yup.number().label("Factura").required(),
    id_mpj: yup.number().label("MPJ").required(),
    importe: yup.number().label("Importe").required().positive(),
    tipoCambio: yup.number().label("Tipo de cambio").required().positive(),
    comprobante: yup.object().label("Comprobante").nullable().shape({}).required(),
  })
});

const entity = "liquidaciones";
const titleTable = "Liquidaciones";
const itemName = "Liquidación";
const itemDescription = item => `${itemName} de factura ${item?.factura || ''}`;
const columns = [
  {field: "concepto", caption: "Concepto"},
  {field: "factura", caption: "Factura", width: 200},
  {field: "fecha_pago", caption: "Fecha de pago", width: 100},
  {field: "mpj", caption: "MPJ", width: 120},
  {field: "perceptor", caption: "Perceptor", width: 120},
  {field: "importe_factura", caption: "Importe factura", width: 120},
  {field: "importe", caption: "Importe", render: (row) => `${row.importe} USD`, width: 120},
  {field: "_actions_", caption: "Acciones", width: 80}
];

export const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "mpjs/options")
    .then( data => {
      resolve({...data, name: "mpjs"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, `facturas/options${(id ? `/${id}` : "")}`)
    .then( data => {
      resolve({...data, name: "facturas"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
];

export const Liquidaciones = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, loads, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, SearchFormComponent: SearchForm});
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}