import { useTheme } from "@emotion/react";
import { Avatar, Box, Grid, IconButton, Paper, Popover } from "@mui/material"
import { useEffect, useState } from "react";
import { useAppContext } from "../../App";
import { useAbm } from "../../services/abm";
import { dataToView } from "../details/Utils";

export const UserProfile = ({anchorEl, onClose}) => {
  
  const { claims } = useAppContext();
  const { palette } = useTheme();
  const [ userData, setUserData ] = useState();
  
  const userId = claims?.id;
  const abm = useAbm({entity: "usuarios"});
  const open = Boolean(anchorEl);

  useEffect(() => {
    if(userId){
      abm.getAll({id: userId})
        .then(data => {
          //console.log(data.results[0])
          setUserData(data.results[0])
        })
    }
  }, [userId])

  return (
    <Popover
      id="user-profile"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box padding={1} maxWidth={300}>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12} display="flex" alignItems="center">
            <Avatar sx={{bgcolor: palette.primary.main, marginX: 2}}>{userData?.nombre_completo?.split(" ").slice(0, 2).map(s => s.substring(0,1))}</Avatar>
            <Box flex={1}>{userData?.nombre_completo}</Box>
          </Grid>
          <Grid item xs={12} display="flex">
            Email: {userData?.email}
          </Grid>
          <Grid item xs={12} display="flex">
            Rol: {userData?.rol}
          </Grid>
          <Grid item xs={12} display="flex">
            Centros: {userData?.centros && userData?.centros.join(", ")}
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}