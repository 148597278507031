import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { estadosFactura } from "../../../config/constants";
import { useAbm } from "../../../services/abm";
import { ControlSelect2, ControlText } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { SearchFormWrapper } from "../SearchFormWrapper";

const estados = Object.keys(estadosFactura).map(key => ({value: estadosFactura[key], label: estadosFactura[key]}));
const getQueryData = (searchParams) => {
  return {term: "", ...Array.from(searchParams.entries()).reduce((prev, curr) => ({...prev, [curr[0]]: curr[1]}), {})};
};

export const SearchForm = ({initSearch, onSearchChange}) => {
  const [data, setData] = useState({perceptores: []}); 
  const { getAll } = useAbm({entity: ""});

  const [searchParams] = useSearchParams(); 
  const [searchData, setSearchData] = useState(() => ({term: "", ...getQueryData(searchParams)})); 

  //console.log(searchData)
  useEffect(()=>{
    setSearchData(initSearch)
  }, [initSearch])
  
  useEffect(()=>{
    getAll({}, "perceptores/options")
      .then(response => {
        setData(data => ({...data, perceptores: response.results}));
      })
    getAll({}, "centros/options")
      .then(response => {
        setData(data => ({...data, centros: response.results}));
      })
    getAll({}, "periodos/options")
      .then(response => {
        setData(data => ({...data, periodos: response.results}));
      })
  }, [])
  
  const handleControlChange = ({target: {name, value}}) => {
    const newData = {...searchData, [name]: value};
    setSearchData(newData)
    onSearchChange(newData);
  }

  return (
    <>
      <ControlText name="term" label="" placeholder="Buscar" errors={[]} data={searchData} onChange={handleControlChange} Wrapper={SearchFormWrapper}  />
      <ControlSelect2 name="id_perceptor" placeholder="Perceptor" data={searchData} onChange={handleControlChange} options={data.perceptores} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>}/>    
      <ControlSelect2 name="id_centro" placeholder="Centro" data={searchData} onChange={handleControlChange} options={data.centros} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>}/>    
      <ControlSelect2 name="estados" placeholder="Estados" data={searchData} onChange={handleControlChange} options={estados} isMulti={true} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>} type="string"/>    
      <ControlSelect2 name="id_periodo" placeholder="Período" data={searchData} onChange={handleControlChange} options={data.periodos} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>}/>    
    </>
  )
}