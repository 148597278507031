import { useContext } from "react";
import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbm } from "../../../../services/abm";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { useItemsEliminar } from "../../../abm/items/useItemsEliminar";
import { useItemsGeneralesEliminar } from "../../../abm/itemsGenerales/useItemsGeneralesEliminar";
import { ActionButton } from "../../../helpers";
import { DeleteIcon } from "../../../icons";
import { PrivateLayoutContext } from "../../../layout-mui/PrivateLayout";

export const EliminarItemGeneral = ({params}) => {

  const { showNotification } = useContext(PrivateLayoutContext);
  const { loadDetails, selectedItem } = useAbmContext();
  const abmActions = useAbm({entity: "items"});

  const updateItem = () => {
    loadDetails(selectedItem.id);
  }

  const fireEliminar = useItemsGeneralesEliminar({selectedItem: {id: params.id}, abmActions, showNotification, updateItem});
   
  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.GESTOR, roles.GESTOR_CENTRAL, roles.LIQUIDADOR, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]})){
    return <></>
  }

  const enabled = true;

  const handleClick = () => {
    fireEliminar({title: "Eliminar Item del Presupuesto General", text: "eliminar"});
  }
  return <ActionButton iconButton enabled={enabled} Icon={DeleteIcon} colorEnabled="var(--bs-danger)" onClick={handleClick }/>
}