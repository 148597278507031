import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { CoinIcon } from "../../../icons";

export const PagarFactura = ({params}) => {
  const { setShowLiquidacionesPagarFactura } = useAbmContext();
  const { claims: { rol } } = useAppContext();

  if(!authorize({rol, inRoles: [roles.LIQUIDADOR]})){
    return <></>
  }
  const enabled = rol === roles.LIQUIDADOR;

  const handleClick = () => {
    setShowLiquidacionesPagarFactura(true);
  }
  return <ActionButton iconButton enabled={enabled} Icon={CoinIcon} onClick={handleClick}/>
}
