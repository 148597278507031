import React, {useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Card, CardContent, Grid, IconButton, ListItemButton, Typography } from "@mui/material";
import { Loading } from "../Loading";
import { PaginationMui } from "./PaginationMui";
import { SearchForm } from "./SearchForm";
import { default as ListMui } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { MenuIcon } from "../icons";
import { useAppContext } from "../../App";
import { objectEmpty } from "../../utils/Helpers";


export const List = ({
  onSearchChange = ()=>{}, 
  isFetching = false, 
  selectedId, 
  onSelect = (row)=>{}, 
  rows = [], 
  columns = [], 
  actions = ({event, row}) => [], 
  noItemsMessage = "No se encontraron items para mostrar", 
  maxPages, 
  currentPage, 
  onPage = (page)=>{},
  SearchFormComponent = SearchForm,
  search = objectEmpty
}) => {
  
  const [selId, setSelId] = useState(selectedId); 
  const timer = useRef(null);

  const handleItemClick = (event, row) => {
    setSelId(row.id);
    onSelect({event, row});
  }
  const { palette } = useTheme();
  const { smMode } = useAppContext();
  const handleSearchChange = (searchData) => {
    if(timer.current){
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      onSearchChange(searchData);
      timer.current = null;
    }, 1000);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Grid container flexDirection="row" padding={0}>
            <Grid item flex={1}>
              <Typography variant="h5" component="div">
                Datos
              </Typography>
            </Grid>
            <Grid item display="flex" alignItems="center" flexWrap="wrap">
              <SearchFormComponent onSearchChange={handleSearchChange} />
            </Grid>
          </Grid>
          <Box padding={smMode ? 0 : 1}>
          <ListMui
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            subheader={<ListSubheader>Datos {isFetching && <Loading/>}</ListSubheader>}
          >
                {!isFetching && rows && rows.map((row, index) => {
                  
                  const values = columns.slice(0, 3)
                    .filter(column => column.field !== "_actions_")
                    .map(column => ({caption: column.caption, text: column.render ? column.render(row) : row[column.field]}));

                  const primaryText = <Typography noWrap>{ values[0] && values[0].text }</Typography>;
                  const secondaryText = values[1] && `${values[1].caption}: ${values[1].text}`;
                  const selected = row.id === selId;
                  const rowActions = actions({row, isSelected: selected});
                  const visibleActions = rowActions.filter(a => !a.hide);
                    //? <TableActions key={`cell${column.field}${row.id}`} actions={actions({row, isSelected: row.id === selId})} row={row}/> 
                  return (
                    <ListItemButton selected={selected} onClick={event => handleItemClick(event, row)} key={`row${row.id}`}>
                      <ListItemText  id="switch-list-label-bluetooth" primary={primaryText} secondary={secondaryText}/>
                      {
                        visibleActions.map(({onClick, icon}) =>
                          <IconButton edge="end" 
                            onClick={(event) => {
                              event.preventDefault();
                              onClick && onClick({event, row});
                            }}
                          >
                            {icon}
                          </IconButton>
                        )
                      }
                    </ListItemButton>
                  )

                })}
                {!isFetching && (!rows || rows.length === 0) && 
                  <ListItem>
                    <ListItemText id="switch-list-label" primary={noItemsMessage} />
                  </ListItem>
                }


          </ListMui>
          </Box>
          <PaginationMui pages={{maxPages, currentPage}} onPage={onPage} />
        </CardContent>
      </Card>
    </>

  );
};

const newArray = (length) => {
  const arr = [];
  for(var i = 0; i < length; i++){
    arr.push(i+1);
  }
  return arr;
}
