import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useThemeProviderContext } from '../../mui/Theme';
import { Box, IconButton, Popover, Avatar } from '@mui/material';
import { FormatPaintIcon } from '../icons';

export const ThemePicker = ({anchorEl, onCancel, onConfirm}) => {

  const { selectThemeColor, themeColor, themeColors } = useThemeProviderContext();

  const open = Boolean(anchorEl);
  const handleConfirm = (theme) => {
    onConfirm(theme);
  };

  return (
    <Popover
      id="color-picker"
      anchorEl={anchorEl}
      open={open}
      onClose={onCancel}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box display="flex" maxWidth={160} flexWrap="wrap" style={{backgroundColor: "#FFFFFF"}}>
        {themeColors.map(item => 
          <IconButton key={item.name} onClick={() => handleConfirm(item.name)}>
            <Avatar sx={{bgcolor: item.color, width: 24, height: 24}} variant="rounded"> </Avatar>
          </IconButton>
        )}
      </Box>
    </Popover>
  );
}