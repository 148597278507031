import { Box, Tabs, Tab } from "@mui/material";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { useAppContext } from "../../App";

export const Sections = ({tabs = [], activeKey, onSelectTab}) => {

  const { smMode } = useAppContext();
  const visibleTabs = useMemo(()=>tabs.filter(tab => tab.visible), [tabs]);
  /*const value = useMemo(()=>{
    const index = visibleTabs.indexOf(tabs.find(tab => tab.key === activeKey));
    return index === -1 ? 0 : index;
  }, [visibleTabs, activeKey]);*/

  const value = useMemo(()=>{
    const index = visibleTabs.indexOf(tabs.find(tab => tab.key === activeKey));
    return index === -1 ? 0 : index;
  }, [tabs, activeKey, visibleTabs]);

  const handleChange = (event, value) => {
    onSelectTab(visibleTabs[value].key)
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs de acciones" scrollButtons allowScrollButtonsMobile> 
          {visibleTabs.map(tab => (
            <Tab  sx={{minWidth: 50}} key={tab.key} icon={smMode ? tab.icon : undefined} label={(smMode && tab.icon) ? "" : tab.title} id={tab.key} aria-controls={`tabpanel-${tab.key}`} />
          ))}
        </Tabs>
      </Box>
      {visibleTabs.map((tab, index) => (
        <TabPanel key={tab.key} value={value} index={index} >
            {tab.content()}
        </TabPanel>
      ))}
    </>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const { smMode } = useAppContext();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&      
        <Box sx={{ p: smMode ? 0 : 3 }}>
          {children}
        </Box>
      }
    </div>
  );
}
