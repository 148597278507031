import { useEffect, useMemo, useRef, useState } from "react";
import { getSchemas, loads } from "../../views/abm/Designaciones";
import { Form } from "../abm/designaciones/Form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const ModalActividadesCreateDesignacion = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, loadDetails, params}) => {
  const { schemaAdd } = useMemo(() => getSchemas(), []);
  const [ show, setShow ] = showState;
  const [ formData, setFormData ] = useState(null);
  const [ itemData, setItemData ] = useState();
  const formRef = useRef();

  const loadExtraData = (id) => new Promise((resolve, reject) => {
    !loads && resolve({});
    Promise.all(
      loads.map(loadFn => {
        loadFn(abmActions, id)
          .then(data => {
            setFormData(formData => ({...formData, [data.name]: data.results}));
          })
      })
    )
    .then(result => {
      resolve()
    })
    .catch(error=>{
      reject();
    })
  });

  useEffect(() => {
    console.log("selectedItem", params)
    if(!params || !show) return;
    if(!params.id){
      setItemData({id_actividad: params.id_actividad, revision: true});
    }else{
      abmActions.getOne(params.id, "designaciones").then(result => {
        setItemData({...result, revision: true});
      }).catch(error=>{
        error.json().then(result => {
          showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
        })
      });
    }

  }, [params?.id, show]);


  const observacion = useMemo(()=>{
    if(!params?.observaciones) return "";
    const item = params.observaciones.reverse()[0];
    return item ? ` (${item.fecha} ${item.motivo})` : "";
  }, [params?.observaciones])

  useEffect(()=>{
    if(show){
      loadExtraData();
    }else{
      setFormData(null);
    }
  }, [show]);

  const handleCancel = (event) => {
    setShow(false);
    event?.preventDefault();
    event?.stopPropagation();
  }
   
  const handleConfirm = (data) => {

    if(!params.id){
      abmActions.create(data, "designaciones").then(result => {
        loadDetails(selectedItem.id);
        setShow(false);
      }).catch(error=>{
        error.json().then(result => {
          showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
        })
      });
    }else{
      abmActions.update(data, params.id, "designaciones").then(result => {
        loadDetails(selectedItem.id);
        setShow(false);
      }).catch(error=>{
        error.json().then(result => {
          showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error modificando registro"});
        })
      });

    }

  }

  return (
    <Dialog open={show} onClose={handleCancel} keepMounted={false} maxWidth="lg">
      <DialogTitle>{(!!params && !params.id) ? "Crear designación" : "Editar designación"}{observacion}</DialogTitle>
      <DialogContent>
        <Form 
          ref={formRef}
          item={itemData}
          data={formData}
          schema={schemaAdd} 
          onConfirm={handleConfirm}
          actionsBottom={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
          Guardar
        </Button>      
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancelar
        </Button>   
      </DialogActions>
    </Dialog>
  )
}