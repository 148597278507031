import { useContext } from "react";
import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbm } from "../../../../services/abm";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { useItemsCambiarEstado } from "../../../abm/items/useItemsCambiarEstado";
import { ActionButton } from "../../../helpers";
import { PrivateLayoutContext } from "../../../layout-mui/PrivateLayout";
import { CheckIcon } from "../../../icons";

export const AprobarItem = ({params}) => {

  const { showNotification } = useContext(PrivateLayoutContext);
  const { loadDetails, selectedItem } = useAbmContext();
  const abmActions = useAbm({entity: "items"});

  const updateItem = () => {
    loadDetails(selectedItem.id);
  }

  const fireCambiarEstado = useItemsCambiarEstado({selectedItem: {id: params.id}, abmActions, showNotification, updateItem});
   
  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.DIRECTOR, roles.LIQUIDADOR]})){
    return <></>
  }

  const enabled = params.estado === estadosItem.PRE_APROBADO;

  const handleClick = () => {
    fireCambiarEstado({nuevoEstado: estadosItem.APROBADO, title: "Aprobar Item", text: "aprobar"});
    //alert(JSON.stringify(params))
  }
  return <ActionButton iconButton enabled={enabled} Icon={CheckIcon} onClick={handleClick }/>
}