import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { FilePresentIcon } from "../../../icons";

export const CargarFacturaItem = ({params}) => {
  const { setShowCargarFacturaItem } = useAbmContext();
  const { claims: { rol, centros } } = useAppContext();
  if(!!centros && centros.length > 0 && !centros.some(c => c === params.id_centro)){
    return <></>
  }
  if(!authorize({rol, inRoles: [roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL]})){
    return <></>
  }
  const enabled = !params.id_factura && 
       (rol === roles.ADMINISTRATIVO || rol === roles.ADMINISTRATIVO_CENTRAL) && (params.estado === estadosItem.APROBADO);

  const handleClick = () => {
    setShowCargarFacturaItem(params);
  }
  return <ActionButton iconButton enabled={enabled} Icon={FilePresentIcon} onClick={handleClick}/>
}
