import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { AddCircleIcon } from "../../../icons";

export const AgregarDesignacionActividad = ({params}) => {
  const { setShowDesignacionAdd, selectedItem } = useAbmContext();
  const { claims: { rol, centros } } = useAppContext();

  const enabled = rol === roles.GESTOR || rol === roles.GESTOR_CENTRAL || rol === roles.LIQUIDADOR || rol === roles.COORDINADOR || rol === roles.COORDINADOR_CENTRAL || rol === roles.ADMINISTRATIVO || rol == roles.ADMINISTRATIVO_CENTRAL;

  const handleClick = () => {
    setShowDesignacionAdd({id_actividad: selectedItem.id});
  }
  return <ActionButton iconButton enabled={enabled} Icon={AddCircleIcon} onClick={handleClick}/>
}
