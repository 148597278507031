import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { CheckIcon } from "../../../icons";

export const AprobarFactura = ({params}) => {
  const { fireCambiarEstado } = useAbmContext();
  const { claims: { rol } } = useAppContext();

  if(!authorize({rol, inRoles: [roles.LIQUIDADOR]})){
    return <></>
  }
  const enabled = rol === roles.LIQUIDADOR;

  const handleClick = () => {
    fireCambiarEstado({nuevoEstado: "APROBADA", title: "Aprobar Factura", text: "aprobar"});
  }
  return <ActionButton iconButton enabled={enabled} Icon={CheckIcon} onClick={handleClick}/>
}
