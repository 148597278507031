import React, { useCallback, useMemo, useRef } from "react";
import { ControlWrapper } from "./ControlWrapper";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { IconButton } from "@mui/material";
import { AddIcon } from "../icons";

const objectPath = require("object-path");

const getValues = (prev) => {
  if(!prev){
    return [];
  } 
  const prevValues = prev.map ? prev : Object.values(prev);
  return prevValues.map(p => p && moment(p).toDate());
}

export const ControlArrayDatePicker = ({name, label, placeholder, onChange, data, start, end, errors = [], Wrapper = ControlWrapper, ...options }) => {

  const values = useMemo(()=>getValues(objectPath(data).get(name, null)), [data, name]);
  
  const handleAddDate = () => {
    const index = values.length;
    onChange({target: {name: `${name}.${index}` , value: null}});
  }

  const handleChange = (value, index)=>{
    //console.log({values, value})
    if(!value){
      onChange({target: {name, value: [...values.filter((value, i) => i !== index)]}})
    }else{
      onChange({target: {name: `${name}.${index}` , value}})
    }
  }

  return (
    <>
      <Wrapper name={name} errors={errors} end={end} start={start} label={label} containerInputProps={{flexWrap: "wrap"}}>
        {
          values?.map((value, index) => 
            <DatePicker
              key={`${name}.${index}`}
              dateFormat="dd/MM/yyyy"
              className="h-100 w-100"
              selected={value}
              onChange={value => handleChange(value, index)}
              isClearable
              placeholderText={placeholder}
              excludeDates={values}
              autoFocus={!value && index === values.length-1}
            />
          )
        }
        <IconButton size="small" onClick={handleAddDate}><AddIcon/></IconButton>    
      </Wrapper>
      
    </>

  )
}