import React, { useRef, useState } from "react";
import { ControlWrapper } from "./ControlWrapper";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "./ControlRangeDatePicker.css";

const objectPath = require("object-path");

export const ControlRangeDatePicker = ({nameStart, nameEnd, label, placeholder, onChange, data, start, end, errors = [], Wrapper = ControlWrapper, ...options }) => {
  
  const getValue = (prev) => {
    if(!prev){
      return null;
    } 
    return moment(prev).toDate();
  }
  
  const valueStart = getValue(objectPath(data).get(nameStart, null));
  const valueEnd = getValue(objectPath(data).get(nameEnd, null));

  return (
    <Wrapper name={nameStart} errors={errors} end={end} start={start} label={label} className="control-range-date-picker">
      <DatePicker
      
        className="h-100 w-100"
        onChange={
          ([start, end])=>{
            onChange({target: {name: nameStart, value: start}});
            onChange({target: {name: nameEnd, value: end}});
          }
        }
        renderCustomHeader={CustomHeader}
        placeholderText={placeholder}
        selected={valueStart}
        startDate={valueStart}
        endDate={valueEnd}
        selectsRange
        withPortal
        isClearable
        dateFormat="dd/MM/yyyy"
      />
    </Wrapper>
  )
}
const months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"]
const years = ["2023", "2024", "2025", "2026"]
const CustomHeader = ({
  date,
  monthDate,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {

  const handleMonthChange = ({ target: { value } }) =>{
    changeMonth(value)
  }
  
  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} 
        style={{
          minWidth: 40,
          color: "black",
          borderRadius: 2,
          padding: 4
        }}
      >
        {"<"}
      </button>
      <select
        value={moment(date).year()}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={moment(monthDate).month()}
        onChange={handleMonthChange}
      >
        {months.map((option, index) => (
          <option key={option} value={index}>
            {option}
          </option>
        ))}
      </select>

      <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}
        style={{
          minWidth: 40,
          color: "black",
          borderRadius: 2,
          padding: 4
        }}    
      >
        {">"}
      </button>
    </div>
  )
}