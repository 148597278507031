import { objectEmpty } from "../../utils/Helpers";
import { ControlWrapper } from "./ControlWrapper";
const objectPath = require("object-path");

export const ControlUploadFile = ({name, label, placeholder, onChange, data, start, end, errors = [], Wrapper = ControlWrapper, errorPlacement = "right", accept, multiple}) => {

  const {nombre, contenido} = data ? (objectPath(data).get(name) || objectEmpty) : objectEmpty;

  // On file select (from the pop up) 
  const onFileChange = (event) => { 
    // Update the state 
    if(event.target.files.length > 0) {
      var reader  = new FileReader();
      const file = event.target.files[0];
      reader.onloadend = function () {
        onChange({target: {name, value: {id: 0, nombre: file.name, contenido: reader.result, tipo: file.type}}})
      }
      reader.readAsDataURL(file);
      event.target.value = [ ];
    }
  }; 

  const handleFileCancel = (event) => {
    onChange({target: {name, value: null}})
  }


  return (
    <Wrapper name={name} errors={errors} end={end} start={start} label={label} errorPlacement={errorPlacement}>
      <div className="control-upload-file">
        {!contenido &&
          <>
            <input 
              type="file" 
              name={name} 
              id={name}
              accept={accept}
              style={{display: "none"}} 
              onChange={onFileChange} 
              multiple={multiple}
            /> 
            <label className="button" for={name} >Seleccione un archivo...</label>
          </>
        }
        {contenido &&
          <>
            <a target="file" className="button" download={nombre} href={contenido}>{nombre?.substring(0,40) || "Descargar"}</a>
            <div className="download" onClick={handleFileCancel}>Eliminar</div>
          </>
        }
      </div>
    </Wrapper>
  )
}