import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { CheckIcon } from "../../../icons";

export const PreAprobarFactura = ({params}) => {
  const { fireCambiarEstado } = useAbmContext();
  const { claims: { rol } } = useAppContext();

  if(!authorize({rol, inRoles: [roles.ADMINISTRATIVO_CENTRAL]})){
    return <></>
  }
  const enabled = rol === roles.ADMINISTRATIVO_CENTRAL;

  const handleClick = () => {
    fireCambiarEstado({nuevoEstado: "PRE-APROBADA", title: "Pre-aprobar Factura", text: "pre-aprobar"});
  }
  return <ActionButton iconButton enabled={enabled} Icon={CheckIcon} onClick={handleClick}/>
}
