import Swal from "sweetalert2";

export const useFacturasCambiarEstado = ({selectedItem, abmActions, showNotification, updateItem}) => {

  const fire = ({nuevoEstado, title, text}) => {
    Swal.fire({
      icon: 'question',
      title,
      html: `Realmente desea <b>${text}</b> la factura seleccionada.<br/>Esta operación no podrá deshacerse.`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirm({id: selectedItem.id, estado: nuevoEstado});
      }
    }) 
  }

  const handleConfirm = (data) => {
    abmActions.update(data, selectedItem.id, "facturas/estado").then(result => {
      abmActions.getAll({id: selectedItem.id}, "facturas")
        .then(data => {
          if(data.results.length > 0){
            updateItem(data.results[0]);
          }
        })
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return fire;
}