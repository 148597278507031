import React, { useEffect, useRef } from "react";
import { ControlWrapper } from "./ControlWrapper";
const objectPath = require("object-path");

export const ControlText = ({name, label, placeholder, onChange, data, start, end, errors = [], Wrapper = ControlWrapper, errorPlacement = "right", autoFocus, ...options }) => {
  const inputRef = useRef();
  const value = data ? objectPath(data).get(name, "") : "";

  useEffect(()=>{
    if(autoFocus){
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Wrapper name={name} errors={errors} end={end} start={start} label={label} errorPlacement={errorPlacement}>
      <input ref={inputRef} type="text" id={name} placeholder={placeholder} name={name} value={value} onChange={onChange} {...options} className="flex-grow-1 h-100 px-2" style={{border: "1px solid #cccccc", borderRadius: 2, width: "100%", maxWidth: "100%"}}/>
    </Wrapper>
  )
}