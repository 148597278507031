import React, { forwardRef, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { abm } from "../../../services/abm";
import { ControlSelect, ControlText, Decorator, ControlTags, ControlCheckbox, ControlUploadFile } from "../../helpers";
import { ControlSelect2 } from "../../helpers/ControlSelect2";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";


export const FormFromItemPresupuesto = forwardRef(({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}, ref) => {

  return (
    <AbmForm ref={ref} title={title} actionsTop={false} actionsBottom={false} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <>
          <Grid xs={12} width={600}>
            <ControlText name="identificacion" label="Identificacion" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
        </>
      )}
    </AbmForm>
  );
})