import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { Notifications, PrivateLayout, PublicLayout } from "./components/layout-mui";
import React, { useMemo } from "react";
import { useAppContext } from "./App";
import { 
  Logout, Dashboard, LoginSide
} from "./views";
import { roles } from "./config/constants";
import { authorize, tabs } from "./Core";
import { Backdrop, CircularProgress } from "@mui/material";

export const AppRoutes = () => {
  const { isAuth, claims, core, writeFetching } = useAppContext();
  const abmsVisibles = useMemo(() => Object.keys(core).filter(key => core[key].visible), [core]);
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={writeFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notifications>
        <BrowserRouter basename="">
          <Routes>
            {
              isAuth && 
                <Route path="/" element={<PrivateLayout/>}>
                  {authorize({rol: claims.rol, inRoles: [roles.LIQUIDADOR, roles.DIRECTOR, roles.ADMINISTRATIVO_CENTRAL, roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL, roles.DIRECTOR_CENTRO, roles.ADMINISTRATIVO]}) &&
                    <Route path="home" element={<Dashboard />}  />
                  }
                  {
                    abmsVisibles.map(key => {
                      const Component = core[key].component;
                      return tabs.map(tab => 
                        <Route key={`${key}-${tab}`} path={`${key}/${tab}`} element={<Component abmKey={key} />}/>
                      )
                    })
                  }
                  <Route path="logout" element={<Logout />} />
                  <Route path="*" element={<Navigate to={abmsVisibles[0]} />} />
                </Route>
            }
            {
              !isAuth && 
                <Route path="" element={<PublicLayout/>}>
                  <Route path={"login"} element={<LoginSide />}  />
                  <Route path={""} element={<LoginSide />} />
                </Route>
            }
          </Routes>
        </BrowserRouter>
      </Notifications>
    </>
  );
}