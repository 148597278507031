import * as yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormItemGeneral } from '../abm/actividades/FormItemGeneral';


const schema = yup.object().shape({
  importe: yup.number().positive().required().label("Importe"),
  concepto: yup.string().required().label("Concepto"),
  id_moneda: yup.number().required().label("Moneda")
});

export const ModalActividadesCreateItemGeneral = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, updateItem, loadDetails, params}) => {

  const [data, setData] = useState({});
  const [show, setShow] = showState;
  const formRef = useRef();

  const loads = () => {
    abmActions.getAll({}, "monedas/options")
    .then(response => {
      setData(data => ({...data, monedas: response.results}));
    })
    .catch(reason => {
      console.warn(reason);
    });
  }
  
  useEffect(()=>{
    show && loads();
  }, [show])


  const handleCancel = () => {
    setShow(false);
  }
  
  const handleConfirm = (data) => {
    abmActions.create(data, "items_generales").then(result => {
      loadDetails(selectedItem.id);
      handleCancel();
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }


  return (
    <>      
      <Dialog open={show} onClose={handleCancel} maxWidth="xl" PaperProps={{sx: {overflow: "visible"}}}>
        <DialogTitle>Agregar item general</DialogTitle>
        <DialogContent sx={{padding: 0, overflow: "visible"}}>
          <FormItemGeneral 
            onConfirm={handleConfirm}
            ref={formRef}
            item={{
              id_actividad: selectedItem?.id,
            }}
            data={data}
            schema={schema} 
            loads={loads}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
            Guardar
          </Button>      
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancelar
          </Button>   
        </DialogActions>
      </Dialog>
    </>
  )
}