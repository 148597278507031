import { useRef } from "react";
import * as yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { AbmForm } from "../abm";
import { objectEmpty } from "../../utils/Helpers";
import { ControlTextArea } from "../helpers";
import { ControlSmallWrapper } from "../helpers/ControlSmallWrapper";

const schema = yup.object().shape({
  text: yup.string().label("Observación").required(), 
});

export const ModalObservacion = ({showState = [false, ()=>{}], title, caption = "Observación", onConfirm, data = objectEmpty}) => {

  const [show, setShow] = showState;
  const formRef = useRef();

  const handleCancel = (event) => {
    setShow(false);
    event?.preventDefault();
    event?.stopPropagation();
  }
   
  const handleConfirm = (data) => {
    onConfirm && onConfirm(data.text);
  }

  return (
    <Dialog open={show} onClose={handleCancel} PaperProps={{sx: {overflow: "visible"}}}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={{overflow: "visible"}}>
        <AbmForm ref={formRef} title={caption} actionsBottom={false} onSubmit={handleConfirm} schema={schema} item={data} onCancel={handleCancel} confirmLabel={"Aceptar"} cancelLabel={"Cancelar"}>
          {({onControlChange, entity, entityErrors}) => {
            return (
              <>
                <Grid item xs={12}>
                  <ControlTextArea name="text" label="" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
                </Grid>
              </>
            ) 
          }}
        </AbmForm>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
          Guardar
        </Button>      
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancelar
        </Button>   
      </DialogActions>
    </Dialog>
  )
}