import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAbm } from "../../../services/abm";
import { ControlSelect2, ControlText } from "../../helpers";
import { SearchFormWrapper } from "../SearchFormWrapper";
import { objectEmpty } from "../../../utils/Helpers";

const filtroEstadoItems = [
  {value: "CON FACTURA OBSERVADA", label: "con factura observada"},
  {value: "APROBADOS SIN FACTURA", label: "con items aprobados sin factura"},
  {value: "PENDIENTES DE APROBACION", label: "con items pendientes"},
  {value: "OBSERVADOS", label: "con items observados"},
  {value: "OBSERVADOS LOCALES", label: "con items observados por su centro"},
  {value: "PREAPROBADOS", label: "con items preaprobados"},
  {value: "PENDIENTES DE REVISION", label: "pendientes de revisión"},
  {value: "PRESUPUESTO GENERAL OBSERVADO", label: "con presupuesto gral. observado"},
]

const getQueryData = (searchParams) => {
  return {term: "", ...Array.from(searchParams.entries()).reduce((prev, curr) => ({...prev, [curr[0]]: curr[1]}), {})};
};

export const SearchForm = ({initSearch = objectEmpty, onSearchChange}) => {
  const [data, setData] = useState({perceptores: []}); 
  const { getAll } = useAbm({entity: ""});


  const [searchParams] = useSearchParams(); 
  const [searchData, setSearchData] = useState(() => ({term: "", ...getQueryData(searchParams)})); 

  //console.log(searchData)
  useEffect(()=>{
    setSearchData(initSearch)
  }, [initSearch])
  
  useEffect(()=>{
    getAll({}, "perceptores/options")
      .then(response => {
        setData(data => ({...data, perceptores: response.results}));
      })
    getAll({}, "centros/options")
      .then(response => {
        setData(data => ({...data, centros: response.results}));
      })
    getAll({}, "usuarios/options")
      .then(response => {
        setData(data => ({...data, usuarios: response.results}));
      })
    getAll({}, "periodos/options")
      .then(response => {
        setData(data => ({...data, periodos: response.results}));
      })
  }, [getAll])
  
  const handleControlChange = ({target: {name, value}}) => {
    const newData = {...searchData, [name]: value};
    setSearchData(newData)
    onSearchChange(newData);
  }

  return (
    <>
      <ControlText name="term" label="" placeholder="Buscar" errors={[]} data={searchData} onChange={handleControlChange} Wrapper={SearchFormWrapper}  />
      <ControlSelect2 name="id_usuario" placeholder="Responsable" data={searchData} onChange={handleControlChange} options={data.usuarios} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>}/>    
      <ControlSelect2 name="id_centro" placeholder="Centro" data={searchData} onChange={handleControlChange} options={data.centros} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>}/>    
      <ControlSelect2 name="filtro" placeholder="Filtro" data={searchData} onChange={handleControlChange} options={filtroEstadoItems} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>} type="string"/>    
      <ControlSelect2 name="id_periodo" placeholder="Período" data={searchData} onChange={handleControlChange} options={data.periodos} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>}/>    
    </>
  )
}