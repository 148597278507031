import { useContext } from "react";
import { useAppContext } from "../../../App";
import { authorize } from "../../../Core";
import { estadosFactura, estadosItem, roles } from "../../../config/constants";
import { useAbm } from "../../../services/abm";
import { ActionButton } from "../../helpers";
import { PrivateLayoutContext } from "../../layout-mui/PrivateLayout";
import { CheckIcon } from "../../icons";
import { useFacturasCambiarEstado } from "../../abm/facturas/useFacturasCambiarEstado";
import { useDashboardContext } from "../../../views/Dashboard";

export const DashboardAprobarFactura = ({params}) => {

  const { showNotification } = useContext(PrivateLayoutContext);
  const { refresh } = useDashboardContext();
  const abmActions = useAbm({entity: "facturas"});
  const fireCambiarEstado = useFacturasCambiarEstado({selectedItem: {id: params.id}, abmActions, showNotification, updateItem: refresh});

  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.LIQUIDADOR]})){
    return <></>
  }

  const enabled = params.estado === estadosFactura.PRE_APROBADA;

  const handleClick = () => {
    fireCambiarEstado({nuevoEstado: estadosItem.APROBADO, title: "Aprobar Item", text: "aprobar"});
  }
  return <ActionButton iconButton enabled={enabled} Icon={CheckIcon} onClick={handleClick }/>
}