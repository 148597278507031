import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useAbmContext } from "../../../views/abm/useAbmComponent";
import { ControlText, ControlTextArea } from "../../helpers";
import { ControlSelect2 } from "../../helpers/ControlSelect2";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";

export const FormItemGeneral = React.forwardRef(({
    item, 
    schema, 
    onCancel, 
    onConfirm, 
    confirmLabel = "Guardar", 
    cancelLabel = "Cancelar", 
    title, 
    errorState, 
    data = {}, 
    loads}, ref) => {

  const [showPerceptorAdd, setShowPerceptorAdd] = useState(false)
  const { selectedItem, abmActions, showNotification } = useAbmContext();

  const handleAgregarPerceptor = (event) => {
    event.preventDefault();
    setShowPerceptorAdd(true);
  }
  

  return (
    <>
      <AbmForm ref={ref} title={title} actionsTop={false} actionsBottom={false} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
        {({onControlChange, entity, entityErrors}) => {
          return (
            <>
              <Grid item xs={12}> 
                <ControlText name="concepto" label="Concepto" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
              </Grid>  
              <Grid item xs={12}> 
                <ControlText name="importe" label="Importe" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
              </Grid> 
              <Grid item xs={12}> 
                <ControlSelect2 name="id_moneda" label="Moneda" errors={entityErrors} data={entity} onChange={onControlChange} options={data.monedas || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
              </Grid>   
            </>
          )
        }}
      </AbmForm>
    </>
  );
})