import React from 'react';
import { Stack, Box, Pagination } from '@mui/material';

export const PaginationMui = ({ pages, onPage = (page)=>{}}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Pagination style={{alignSelf: "center"}} count={pages.maxPages} page={pages.currentPage || 1} onChange={(event, page) => onPage(page)} />
      </Stack>
    </Box>
  )
}
