const server = window.config.CONSTANTS.SERVER

const roles = {
  DIRECTOR: 'DIRECCION',
  LIQUIDADOR: 'ADMINISTRACION GENERAL',
  DIRECTOR_CENTRO: 'DIRECCION DE CENTRO',
  GESTOR: 'GESTOR',
  ADMINISTRATIVO: 'ADMINISTRATIVO',
  ADMINISTRATIVO_CENTRAL: 'ADMINISTRACION AREAS',
  GESTOR_CENTRAL: 'GESTOR CENTRAL',
  COORDINADOR_CENTRAL: 'COORDINADOR CENTRAL',
  COORDINADOR: 'COORDINADOR',
  INVITADO: 'INVITADO',
}

const estadosItem = {
  PENDIENTE_APROBACION: 'PENDIENTE DE APROBACION',
  PRE_APROBADO: 'PRE-APROBADO',
  APROBADO: 'APROBADO',
  OBSERVADO: 'OBSERVADO',
  OBSERVADO_LOCAL: 'OBSERVADO LOCAL',
}

const estadosFactura = {
  PENDIENTE_APROBACION: 'PENDIENTE DE APROBACION',
  PRE_APROBADA: 'PRE-APROBADA',
  OBSERVADA: 'OBSERVADA',
  APROBADA: 'APROBADA',
  LIQUIDADA: 'LIQUIDADA',
}

const estadosDesignacion = {
  PENDIENTE_APROBACION: 'PENDIENTE DE APROBACION',
  EN_REVISION: 'EN REVISION',
  OBSERVADA: 'OBSERVADA',
  APROBADA: 'APROBADA',
}


const tiposMpj = {
  PRIMER_ENVIO: 'PRIMER ENVIO',
  SEGUNDO_ENVIO: 'SEGUNDO ENVIO',
  OTRO: 'OTROS',
}

export { server, roles, estadosItem, estadosFactura, tiposMpj, estadosDesignacion };
