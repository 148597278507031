import { useTheme } from "@emotion/react";
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { dataToView } from "./Utils";
import { PrintIcon } from "../icons";
import { useReactToPrint } from 'react-to-print';

export const ViewDetails = ({data, title}) => {
  const viewDetails = dataToView({data});
  const { palette } = useTheme();
  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => container.current,
  });

  const handleExportPdf = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <Card ref={container}>
      <CardHeader 
        action={
          <IconButton aria-label="settings" onClick={handlePrint}>
            <PrintIcon/>
          </IconButton>
        }
        title={title}
      />
      <CardContent>
        <Box>
          <Paper paddingX={4} paddingY={1} elevation={1} style={{backgroundColor: palette.bgForm.main}}>
            <Box padding={4}>
              {viewDetails}
            </Box>
          </Paper>
        </Box>
      </CardContent>
    </Card>
  )
}