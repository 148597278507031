import { Button, IconButton, SvgIcon } from "@mui/material"
import { QuestionIcon } from "../icons"

export const ActionButton = ({caption, iconButton = false, iconButtonSize = "small", Icon = QuestionIcon, size = 22, enabled, colorEnabled = "primary", htmlColor, colorDisabled = "disabled", onClick}) => {
  return (
    <>
      {!!caption && 
        <Button size={iconButtonSize} disabled={!enabled} onClick={enabled ? onClick : undefined} variant="outlined">{caption}</Button>
      }
      {!caption && iconButton &&
        <IconButton size={iconButtonSize} disabled={!enabled} onClick={enabled ? onClick : undefined}>
          <SvgIcon 
            component={Icon} 
            color={!htmlColor ? (enabled ? colorEnabled : colorDisabled) : undefined} 
            htmlColor={!!htmlColor ? (enabled ? htmlColor : colorDisabled) : undefined} 
            cursor={enabled ? "pointer" : "default"}
          />
        </IconButton>
      }
      {!caption && !iconButton &&
        <SvgIcon 
          component={Icon} 
          size={size} 
          color={!htmlColor ? (enabled ? colorEnabled : colorDisabled) : undefined} 
          htmlColor={!!htmlColor ? (enabled ? htmlColor : colorDisabled) : undefined} 
          cursor={enabled ? "pointer" : "default"} 
          onClick={enabled ? onClick : undefined}
        />
      }
    </>
  )
}