import { useContext } from "react";
import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbm } from "../../../../services/abm";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { useItemsEliminar } from "../../../abm/items/useItemsEliminar";
import { ActionButton } from "../../../helpers";
import { DeleteIcon } from "../../../icons";
import { PrivateLayoutContext } from "../../../layout-mui/PrivateLayout";

export const EliminarItem = ({params}) => {

  const { showNotification } = useContext(PrivateLayoutContext);
  const { loadDetails, selectedItem } = useAbmContext();
  const abmActions = useAbm({entity: "items"});

  const updateItem = () => {
    loadDetails(selectedItem.id);
  }

  const fireEliminar = useItemsEliminar({selectedItem: {id: params.id}, abmActions, showNotification, updateItem});
   
  const { claims: { rol, centros } } = useAppContext();
  if(!authorize({rol, inRoles: [roles.GESTOR, roles.GESTOR_CENTRAL, roles.COORDINADOR, roles.COORDINADOR_CENTRAL]})){
    return <></>
  }

  const enabled = !params.id_factura && (
    (params.estado === estadosItem.PENDIENTE_APROBACION || params.estado === estadosItem.OBSERVADO)
    || ((rol === roles.GESTOR_CENTRAL || rol === roles.COORDINADOR_CENTRAL) && (params.estado === estadosItem.PRE_APROBADO))
  );

  const handleClick = () => {
    fireEliminar({title: "Eliminar Item", text: "eliminar"});
  }
  return <ActionButton iconButton enabled={enabled} Icon={DeleteIcon} htmlColor="var(--bs-danger)" onClick={handleClick }/>
}