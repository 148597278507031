import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Table } from "../../components/abm/Table";
import { PrivateLayoutContext } from "../../components/layout-mui/PrivateLayout";
import { useAbm } from "../../services/abm";
import Swal from 'sweetalert2'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../App";
import { EditIcon, DetailsIcon, DeleteIcon, ViewListIcon, AddCircleIcon, ModeEditIcon, SummarizeIcon } from "../../components/icons";
import { ActionButton } from "../../components/helpers";
import { arrayEmpty, objectEmpty, toLog } from "../../utils/Helpers";
import { AbmViewDetails } from "../../components/abm/AbmViewDetails";
import { AbmFormEditComponent } from "../../components/abm/AbmFormEditComponent";
import { AbmFormAddComponent } from "../../components/abm/AbmFormAddComponent";
import { AbmIndex } from "../../components/abm";
import { getQueryData } from "../../Core";


export const AbmContext = createContext({


});
export const useAbmContext = () => useContext(AbmContext);

const fnDefaultActions = ({goTo, handleActionRemove, getPermissions, claims}) => ({event, row, isSelected}) => {
  const {details, edit, del} = getPermissions({row, isSelected, claims});
  const result = [
    {name: "details", hide: !details, caption: "", icon: <ActionButton Icon={DetailsIcon} enabled={details && isSelected} onClick={()=>goTo(`details/${row.id}`)}/>},
    {name: "edit", hide: !edit, caption: "", icon: <ActionButton Icon={EditIcon} enabled={edit && isSelected} onClick={()=>goTo(`edit/${row.id}`)}/>},
    {name: "del", hide: !del, caption: "", icon: <ActionButton Icon={DeleteIcon} enabled={del && isSelected} colorEnabled={"error"} onClick={handleActionRemove}/>},    
  ]
  //toLog("usando acciones por defecto", {row, isSelected, result, event})
  !!event && event.preventDefault && event.preventDefault();
  !!event && event.stopPropagation && event.stopPropagation();
  return result;
}

export const useAbmComponent = ({
  abmKey,
  fnActions = fnDefaultActions, 
  schemaAdd, 
  schemaEdit, 
  entity, 
  titleTable, 
  itemName, 
  itemDescription = (item) => "", 
  columns = arrayEmpty, 
  loads = arrayEmpty, 
  TableComponent = Table, 
  FormComponent, 
  SearchFormComponent,
  ViewDetailsComponent = AbmViewDetails,
  //getPermissions = defaultGetPermissions
}) => {
  const [searchParams] = useSearchParams(); 
  const { claims, smMode, core, globalQuery, setPropGlobalQuery } = useAppContext();

  const abmFn = useAbm({entity});
  const { getAll, create, update, remove, getOne, getDetails, cancel, download } = abmFn;
  const [rows, setRows] = useState(() => arrayEmpty);
  const [maxPages, setMaxPages] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeKey, setActiveKey] = useState();
  const [search, setSearch] = useState(() => getQueryData(searchParams, globalQuery));
  const [fetching, setFetching] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [formData, setFormData] = useState(() => {});
  const [detailsData, setDetailsData] = useState(() => {});
  const [formItem, setFormItem] = useState(() => {});
  const { showNotification, setHeaderContent } = useContext(PrivateLayoutContext);

  const { tab, id } = useParams();
  const navigate = useNavigate();

  const getPermissions = useCallback(core[abmKey].permissions, [abmKey]);

  /*useEffect(()=>{
    const newItem = rows.find(row => row.id === parseInt(id));
    toLog("cambio el id en el path, selecciono otro item", {tab, id, newItem});
    !!newItem && setSelectedItem(newItem);
  }, [tab, id])*/

  const fetchData = useCallback(({loaded = false, search = {}}) => {
    toLog("haciendo fetch", {search, loaded})
    if(!loaded) return;
    //if(fetching) cancel();
    setFetching(true);
    //unSelectItem();
    getAll(search)
      .then( data => {
        setRows(data.results);
        setMaxPages(data.maxPages);
      })
      .catch(reason => {
        console.warn(reason);
      })
      .finally(()=>{
        setFetching(false);
      })
  }, [getAll])

  useEffect(()=>{
    toLog("entra a abm component", core[abmKey])
    setHeaderContent(core[abmKey].label)
    setLoaded(true);
    return ()=>{
      unSelectItem();
    }
  }, [])
  
  const unSelectItem = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const selectItem = (item) => {
    setSelectedItem(item);
  }
  
  /* esto no hace falta porque el search cuando se setea provoca una busqueda de todos los items
  useEffect(()=>{
    toLog("entra a loaded y fetchData", {loaded, fetchData})
    if(loaded) {
      toLog("cargo el componente, hago load del index", {loaded})
      fetchData({loaded})
    };
  }, [loaded, fetchData])
  */

  const handleRowSelect = ({event, row}) => {
    //selectItem(row);
    toLog("cambio el item seleccionado en la tabla, navego al item desde admin", {row})
    goTo(`admin${(row ? `/${row.id}` : '')}`)
  }

  useEffect(()=>{
    if(!loaded) return;
    const timer = setTimeout(() => fetchData({loaded, search}), 200);
    return ()=>{
      clearTimeout(timer);
    };
  }, [search, loaded])

  const handleSearchChange = (data) => {
    if(data.id_periodo !== undefined){
      setPropGlobalQuery("id_periodo", data.id_periodo);
    }
    setSearch(search => setSearch({...data, page: 1}));
  }
 
  const loadExtraData = useCallback((id) => new Promise((resolve, reject) => {
    !loads && resolve({});
    Promise.all(
      loads.map(loadFn => {
        loadFn(abmFn, id)
          .then(data => {
            setFormData(formData => ({...formData, [data.name]: data.results}));
          })
      })
    )
    .then(result => {
      resolve()
    })
    .catch(error=>{
      reject();
    })
  }), [loads, abmFn]);

  const loadDetails = useCallback((id) => {
    toLog("loadDetails", {id})
    getDetails(id)
      .then( data => {
        toLog("getDetails.then", {data})
        setDetailsData(data);
      })
      .catch(reason => {
        console.warn(reason);
      })
  }, [getDetails])

  const goTo = useCallback((path, replace = false) => {
    toLog("goto", {path, replace});
    navigate(`/${abmKey}/${path}`, { replace });
  }, [])

  const handleAddConfirm = useCallback((data) => {
    create(data).then(result => {
      setFormItem(null);
      fetchData({search, loaded});
      goTo("admin");
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }, [fetchData, search, loaded, goTo])
  
  const handleEditConfirm = (data) => {
    toLog(data)
    update(data, data.id).then(result => {
      fetchData({loaded, search});
      goTo("admin");
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error editando registro"});
      })
    });
  }

  const handlePageChange = (page) => {
    setSearch(search => setSearch({...search, page}));
  }

  const handleRemoveConfirm = useCallback((id) => {
    remove(id)
    .then(response => {
      unSelectItem();
      fetchData({loaded: true, search});
    })
    .catch(error => {
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error eliminando registro"});
      })
    })
  }, [remove, fetchData, search]);

  const handleActionRemove = useCallback(({event, row}) => {
    Swal.fire({
      icon: 'question',
      title: `Eliminar ${itemName}`,
      html: `Realmente desea eliminar <b>${itemDescription(row)}</b>.<br/>Esta operación no podrá deshacerse.`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Sí, eliminar permanentemente",
      denyButtonText: "No, fue un descuido",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveConfirm(selectedItem.id);
      }
    })
  }, [itemName, itemDescription, selectedItem, handleRemoveConfirm]);

  const handleSelectTab = (key, id = null) => {
    tabs.find(tab => tab.key===activeKey)?.onExit();
    const newTab = tabs.find(tab => tab.key===key);
    if(newTab){
      newTab.onSelect(id);
      setActiveKey(key);
    }
  }
  
  const handleFormCancel = ({event, item})=>{
    //setFormItem(null);
    //setFormData(null);
    goTo(`admin/${selectedItem?.id || ""}`);
    event?.preventDefault();
    event?.stopPropagation();
  }

  const actions = useCallback(fnActions({goTo, handleActionRemove, getPermissions, claims}), [goTo, handleActionRemove, getPermissions, claims])

  const loadItemFromId = (id) => new Promise((resolve, reject) => {
    toLog("leo el item desde la api", {id});
    getAll({id})
      .then( data => {
        resolve(data.results[0]);
      })
      .catch(reason => {
        reject(reason);
      })
  })

  const updateItemFromId = (id) => {
    loadItemFromId(id)
      .then( data => {
        updateItem(data);
      })
  }

  useEffect(()=>{
    if(!loaded) return;
    if(!id){
      handleSelectTab(tab || "admin");
      return;
    }
    toLog("cambio el id, path, loaded o rows", {tab, id, loaded, rows});
    if(!!selectedItem && selectedItem.id === parseInt(id)){
      toLog("es el mismo id al seleccionado, salgo", {selectedItem, id});
      handleSelectTab(tab || "admin", parseInt(id));
      return;
    }
    toLog("intento tomar el item de rows", {selectedItem, id, rows});
    //confirmo que no es el mismo item que esta seleccionado
    const newItem = rows.find(row => row.id === parseInt(id));
    if(!!newItem){
      toLog("lo encontre en rows, lo seteo y salgo", {newItem});
      setSelectedItem(newItem);
      handleSelectTab(tab || "admin", parseInt(id));
      return;
    } 
    //si no pude hacer lo anterior, descargo el item del servidor y lo selecciono:
    if(loaded){
      setTimeout(() => {
        loadItemFromId(id)
          .then(data => {
            toLog("selecciono el item que devuelve la api", {data});
            setSelectedItem(data);
          })
          .catch(error => {
            setSelectedItem(id && {id: parseInt(id)});
          })
        handleSelectTab(tab || "admin", parseInt(id));
      }, 0);
    }
  }, [loaded, id, tab, rows, selectedItem])

  const updateItem = (item) => {
    if(!item) return;
    setRows(rows => rows.map(row => row.id === item.id ? item : row));
  }

  const tabsPermissions = useMemo(() => getPermissions({row: selectedItem}), [selectedItem, getPermissions])
 
  const loadItemData = useCallback((selId) => {
    getOne(selId)
      .then(response => {
        if (response && selId && response.id === selId) {
          setFormItem(response);
        }
      })
      .catch(error => {
        console.warn(error);
      })
  }, [getOne]);

  const tabs = useMemo(() => createTabs({
    titleTable, 
    selectedItem, 
    tabsPermissions,
    loadExtraData, 
    loadItemData, 
    loadDetails, 
    setDetailsData, 
    setFormItem, 
    AbmFormAddComponent,
    AbmFormEditComponent,
    AbmIndex,
    ViewDetailsComponent
  }), [titleTable, selectedItem, tabsPermissions, loadExtraData, loadItemData, loadDetails, setDetailsData, setFormItem, AbmFormAddComponent, AbmFormEditComponent, AbmIndex, ViewDetailsComponent]);

  return {
    abmActions: {getAll, create, update, remove, getOne, cancel, entity: abmFn.entity, download},
    keyState: [activeKey, (tab)=>{ 
      const path = `${tab}${(selectedItem ? `/${selectedItem.id}` : '')}`;
      toLog("click en la pestaña", {tab, selectedItem, path})
      goTo(path); 
    }],
    searchState: [search, setSearch],
    tabs,
    loaded,
    itemName,
    form: {formData, formItem, schemaEdit, schemaAdd, FormComponent, handleEditConfirm, handleAddConfirm, handleFormCancel},
    table: {actions, rows, columns, maxPages, TableComponent, SearchFormComponent, handlePageChange, handleRowSelect, handleSearchChange},
    selectedItem,
    updateItem,
    showNotification,
    loadDetails,
    updateItemFromId,
    itemDescription,
    detailsData,
    fetching,
    smMode,
    schemaAdd, 
    schemaEdit,
  }
  
}


const createTabs = ({AbmIndex, AbmFormAddComponent, AbmFormEditComponent, ViewDetailsComponent, selectedItem, titleTable, loadExtraData, loadItemData, loadDetails, setDetailsData, setFormItem, tabsPermissions}) => {
  return [
    {
      key: "admin", 
      title: titleTable, 
      content: () => <AbmIndex/>,
      onSelect: () => {
      },
      onExit: () => {
      },
      visible: true,
      icon: <ViewListIcon/>
    },
    {
      key: "add", 
      title: "Añadir", 
      content: () => <AbmFormAddComponent/>, 
      onSelect: () => {
        loadExtraData();
      },
      onExit: () => {
      },
      visible: tabsPermissions.add,
      icon: <AddCircleIcon/>,
    }, 
    {
      key: "edit", 
      title: "Editar", 
      content: () => <AbmFormEditComponent/>, 
      onSelect: (id) => {
        const selId = selectedItem?.id || id;
        loadExtraData(selId);
        loadItemData(selId);
      },
      onExit: () => {
        setFormItem(null);
      },
      visible: tabsPermissions.edit,
      icon: <ModeEditIcon/>,
    },
    {
      key: "details", 
      title: "Detalles", 
      content: () => <ViewDetailsComponent />, 
      onSelect: (id) => {
        toLog("entra a la pestaña detalles", {selectedItem, id})
        loadDetails(selectedItem?.id || id);
      },
      onExit: () => {
        setDetailsData(objectEmpty);
      },
      visible: tabsPermissions.details && !!selectedItem,
      icon: <SummarizeIcon/>,
    }
  ];
}