import { Form } from "../../components/abm/perceptores/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { useMemo } from "react";

export const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    identificacion: yup.string().label("Identificación").required(),
    cuit: yup.string().label("CUIT"),
    cbu_alias: yup.string().label("CBU / Alias"),
    domicilio: yup.string().label("Domicilio"),
    telefono: yup.string().label("Teléfono"),
    celular: yup.string().label("Celular"),
    email: yup.string().email().label("Email"),
    constancia_cbu: yup.object().label("Constancia de CBU").nullable().shape({}),
    constancia_fiscal: yup.object().label("Constancia de Identificación Fiscal").nullable().shape({}),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    identificacion: yup.string().label("Identificación").required(),
    cuit: yup.string().label("CUIT").required(),
    cbu_alias: yup.string().label("CBU / Alias"),
    domicilio: yup.string().label("Domicilio").required(),
    telefono: yup.string().label("Teléfono"),
    celular: yup.string().label("Celular"),
    email: yup.string().email().label("Email"),
    constancia_cbu: yup.object().label("Constancia de CBU").nullable().shape({}),
    constancia_fiscal: yup.object().label("Constancia de Identificación Fiscal").nullable().shape({}),
  })
})

const entity = "perceptores";
const titleTable = "Perceptores";
const itemName = "Perceptor";
const itemDescription = item => `${itemName} ${item?.identificacion || ''}`;
const columns = [
  {field: "identificacion", caption: "identificacion"},
  {field: "cuit", caption: "CUIT", width: 120},
  {field: "telefono", caption: "Teléfono", width: 80},
  {field: "celular", caption: "Celular", width: 80},
  {field: "items", caption: "Items", render: (row) => row.items, width: 80},
  {field: "facturas", caption: "Facturas", render: (row) => row.facturas, width: 80},
  {field: "_actions_", caption: "Acciones", width: 80}
];

export const Perceptores = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}