import { useState, useRef, useEffect } from "react";
import { useLogin } from "../../services/login";
import { useAppContext } from "../../App";
import * as yup from 'yup';
import { AbmForm } from "../abm/AbmForm";
import { ControlText } from "../helpers";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().email().required("Este campo es requerido"),
  password: yup.string().required("Este campo es requerido"),
});

export const ModalLogin = ({show = false, onCancel = ()=>{}}) => {
  
  const appContext = useAppContext();
  const [error, setError] = useState(null);
  const login = useLogin();
  const navigate = useNavigate();

  useEffect(()=>{
    if(show){
      setFormData({email: "", password: ""});
    }
  }, [show]);

  const [formData, setFormData] = useState({});

  const handleSubmit = () => {
    login.send(formData)
      .then(response => {
        appContext.singIn(response);
        navigate("home");
      })
      .catch(reason => {
        console.log(`[ERROR - login publico - 105] ${reason}`)
        throw new Error();
      });
  };

  const handleChange = (data) => {
    setFormData({...data});
  }

  const handleConfirm = () => {
    document.getElementById("submitLogin").click();
  }

  return (
    <Dialog open={show} onClose={onCancel}>
      <DialogTitle>Iniciar Sesión</DialogTitle>
      <DialogContent>
        <AbmForm submitId="submitLogin" onSubmit={handleSubmit} schema={schema} item={formData} errorState={[error, setError]} onChange={handleChange} actionsTop={false} actionsBottom={false}>
          {({onControlChange, entity, entityErrors}) => (
            <>
              <Grid item xs={12}>
                <ControlText name="email" label="Email" errors={entityErrors} data={entity} onChange={onControlChange} autoFocus/>
              </Grid>
              <Grid item xs={12}>
                <ControlText name="password" label="Password" errors={entityErrors} data={entity} onChange={onControlChange} type="password" />
              </Grid>
            </>
          )}
        </AbmForm>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={handleConfirm}>
          Iniciar sesión
        </Button>      
 
      </DialogActions>
    </Dialog>
  );
}
