import React, {memo, useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { Loading } from "../Loading";
import { PaginationMui } from "./PaginationMui";
import { SearchForm } from "./SearchForm";
import { TableActions } from "./TableActions";
import { TableCell } from "./TableCell";
import { objectEmpty, toLog } from "../../utils/Helpers";
import "./Table.css";

export const Table = ({
  onSearchChange = ()=>{}, 
  isFetching = false, 
  selectedId, 
  onSelect = (row)=>{}, 
  rows = [], 
  columns = [], 
  actions = ({event, row}) => [], 
  noItemsMessage = "No se encontraron items para mostrar", 
  maxPages, 
  currentPage, 
  onPage = (page)=>{},
  SearchFormComponent = SearchForm,
  search = objectEmpty
}) => {
  
  const [selId, setSelId] = useState(selectedId); 
  const [loaded, setLoaded] = useState(false); 
  const timer = useRef(null);

  const handleTrClick = useCallback((event, row) => {
    toLog("click en un registro", {row});
    setSelId(row.id);
    onSelect({event, row});
    setLoaded(true);
  }, [])

  const handleSearchChange = (searchData) => {
    if(!!timer.current){
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      toLog("buscando", {searchData})
      onSearchChange(searchData);
      timer.current = null;
    }, 1000);
  }

  useEffect(()=>{
    return () => {
      if(!!timer.current){
        clearTimeout(timer.current)
      }
    }
  }, [])

  useEffect(()=>{
    toLog("cambio desde afuere de la tabla", {selectedId})
    if(selectedId !== selId){
      setSelId(selectedId);
    }
  }, [selectedId])

  return (
    <>
      <Card sx={{overflow: "visible"}}>
        <CardContent>
          <Grid container flexDirection="row" padding={1}>
            <Grid item flex={1}>
              <Typography variant="h5" component="div">
                Datos
              </Typography>
            </Grid>
            <Grid item display="flex" alignItems="center" flexWrap="wrap">
              <SearchFormComponent onSearchChange={handleSearchChange} initSearch={search}/>
            </Grid>
          </Grid>
          <Box padding={1} overflow="auto">
            <table className="abm-table">
              <thead>
                <tr>
                  {
                    columns.map(column => 
                      <th key={column.field} style={{paddingLeft: 8, paddingRight: 8, textAlign: column.align || "left", width: column.width || "100%"}}>
                        <Box paddingY={2} width={column.width || "100%"}>{column.caption}</Box>
                      </th>
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {isFetching &&
                  <tr className="text-center">
                    <td colSpan={columns.length + 1}>
                      <Box display="flex" justifyContent="center" overflow="hidden" height={75} alignItems="center">
                        <Loading/>
                      </Box>
                    </td>
                  </tr>
                }
                {!isFetching && rows && rows.map((row, index) => 
                  <TableRow key={`row${row.id}`} row={row} columns={columns} selected={row.id === selId} onClick={(event)=>handleTrClick(event, row)} actions={actions}/>
                )}
                {loaded && !isFetching && (!rows || rows.length === 0) && 
                  <tr className="text-center">
                    <td colSpan={columns.length + 1}>{noItemsMessage}</td>
                  </tr>
                }
              </tbody>
            </table> 
          </Box>
          <PaginationMui pages={{maxPages, currentPage}} onPage={onPage} />
        </CardContent>
      </Card>
    </>

  );
};

const TableRow = ({row, columns, selected, onClick, actions}) => {
  return (  
    <tr className={`${selected ? "selected" : ""} ${row?.cerrada || row?.cerrado ? "closed" : "open"}`} onClick={onClick}>
      {columns.map(column => (
        column.field === "_actions_" 
          ? <TableActions key={`cell${column.field}${row.id}`} actions={actions({row, isSelected: selected})} row={row}/> 
          : <TableCell key={`cell${column.field}${row.id}`} column={column} row={row}/>
      ))}
    </tr>
  )
}