import { useMemo, useRef } from "react";
import { estadosFactura, estadosItem } from "../../config/constants";
import * as yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FormFacturaRechazo } from "../abm/facturas/FormFacturaRechazo";

const getSchema = () => yup.object().shape({
  id: yup.number().required(),
  estado: yup.string().label("Estado").required(), 
  motivo: yup.string().label("Motivo").required(), 
});

export const ModalFacturaRechazo = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, updateItem, id = selectedItem?.id}) => {

  const [show, setShow] = showState;
  const formRef = useRef();
  const schema = useMemo(() => getSchema(), []);

  const handleCancel = (event) => {
    setShow(false);
    event?.preventDefault();
    event?.stopPropagation();
  }
   
  const handleConfirm = (data) => {
    abmActions.update(data, id, "facturas/estado").then(result => {
      updateItem(id);
      setShow(false);
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return (
    <Dialog open={show} onClose={handleCancel} PaperProps={{sx: {overflow: "visible"}}}>
      <DialogTitle>Observar factura</DialogTitle>
      <DialogContent sx={{overflow: "visible"}}>
        <FormFacturaRechazo 
          ref={formRef}
          item={{
            id,
            estado: estadosFactura.OBSERVADA
          }}
          schema={schema} 
          onConfirm={handleConfirm} 
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
          Guardar
        </Button>      
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancelar
        </Button>   
      </DialogActions>
    </Dialog>
  )
}