import React, { useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { objectEmpty } from "../../utils/Helpers";

export const ControlWrapper = ({children, name, label, start, end, errors, className, errorPlacement = "right" }) => {
  const target = useRef();
  const error = errors.find(e => e.path === name);
  return (
    <>
      <div ref={target} className={`w-100 d-flex flex-row mb-1 ${className}`} style={{minHeight: 32}}>
        <label htmlFor={name} className="pe-2" style={{width: 90}}>{label}</label>
        <div className={`flex-grow-1 d-flex flex-row`}  style={{backgroundColor: "white"}}>
          {start}
          {children}
          {end}
        </div>
      </div>  
      <Overlay target={target.current} show={error} placement={errorPlacement}>
        {(props) => (
          <Tooltip {...{...props, style:{...props.style || objectEmpty, opacity:0.8}}}>
            {error?.message}
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}