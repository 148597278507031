import { useContext } from "react";
import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosItem, roles } from "../../../../config/constants";
import { useAbm } from "../../../../services/abm";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { useItemsCambiarEstado } from "../../../abm/items/useItemsCambiarEstado";
import { ActionButton } from "../../../helpers";
import { CheckIcon } from "../../../icons";
import { PrivateLayoutContext } from "../../../layout-mui/PrivateLayout";

export const PreAprobarItem = ({params}) => {

  const { showNotification } = useContext(PrivateLayoutContext);
  const { loadDetails, selectedItem } = useAbmContext();
  const abmActions = useAbm({entity: "items"});

  const updateItem = () => {
    loadDetails(selectedItem.id);
  }
  const fireCambiarEstado = useItemsCambiarEstado({selectedItem: {id: params.id}, abmActions, showNotification, updateItem});
  const { claims: { rol, centros } } = useAppContext();

  if (!authorize({rol, inRoles: [roles.DIRECTOR_CENTRO]})) {
    return <></>
  }
  const enabled = params.estado === estadosItem.PENDIENTE_APROBACION;
  const handleClick = () => {
    fireCambiarEstado({nuevoEstado: estadosItem.PRE_APROBADO, title: "Pre-aprobar Item", text: "pre-aprobar"});
  }
  
  return <ActionButton iconButton enabled={enabled} Icon={CheckIcon} onClick={handleClick }/>
}