import { useAbmContext } from "../../views/abm/useAbmComponent"
import { DeleteIcon, SaveIcon } from "../icons";
import { List } from "./List";

// abmActions: {getAll, create, update, remove, getOne, cancel, entity: abmFn.entity},
// keyState: [activeKey, (tab)=>{ 
//   const path = `${tab}${(selectedItem ? `/${selectedItem.id}` : '')}`;
//   toLog("click en la pestaña", {tab, selectedItem, path})
//   goTo(path); 
// }],
// searchState: [search, setSearch],
// tabs,
// loaded,
// form: {formData, formItem, schemaEdit, schemaAdd, FormComponent, handleEditConfirm, handleAddConfirm, handleFormCancel},
// selectedItem,
// updateItem,
// showNotification,
// loadDetails,
// updateItemFromId,
// itemDescription,
// detailsData,

export const AbmIndex = () => {
  const {
    selectedItem,
    table: {
      actions, 
      rows, 
      columns, 
      maxPages,
      SearchFormComponent, 
      TableComponent,
      handlePageChange, 
      handleRowSelect, 
      handleSearchChange
    }, 
    fetching,
    searchState: [search],
    smMode
  } = useAbmContext();
  return (
    <>
      {smMode
        ? <List
            selectedId={selectedItem?.id}
            onSearchChange={handleSearchChange}
            columns={columns} 
            rows={rows} 
            actions={actions} 
            onSelect={handleRowSelect} 
            currentPage={search.page} 
            maxPages={maxPages} 
            onPage={handlePageChange}
            isFetching={fetching}
            SearchFormComponent={SearchFormComponent}
            search={search}
        /> 
        : <TableComponent 
            selectedId={selectedItem?.id}
            onSearchChange={handleSearchChange}
            columns={columns} 
            rows={rows} 
            actions={actions} 
            onSelect={handleRowSelect} 
            currentPage={search.page} 
            maxPages={maxPages} 
            onPage={handlePageChange}
            isFetching={fetching}
            SearchFormComponent={SearchFormComponent}
            search={search}
        />
      }
    </>
  )
}