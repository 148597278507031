import React, { createContext, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import * as immutable from 'object-path-immutable'
import { Alert, Card, CardContent, Grid, Typography, Box, Paper, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useAppContext } from "../../App";
import { useAbm } from "../../services";
import Swal from "sweetalert2";

const AbmFormContext = createContext({clearErrors: ()=>{}, entityErrors: []});
export const useAbmFormContext = () => useContext(AbmFormContext);

export const AbmForm = React.forwardRef(({
  submitId, 
  item = {}, 
  schema, 
  onSubmit, 
  onCancel, 
  confirmLabel = "Guardar", 
  cancelLabel = "Cancelar", 
  children, 
  title, 
  errorState = [false, ()=>false], 
  onChange, 
  actionsBottom = false
}, ref) => {

  const { smMode, writeFetching } = useAppContext();
  const [entity, setEntity] = useState(()=>item);
  const [error, setError] = errorState || [null, ()=>null];
  const [entityErrors, setEntityErrors] = useState([]);
  const { palette } = useTheme();
  const itemStr = useMemo(()=>item ? JSON.stringify(item) : "", [item]);
  const formRef = useRef(null);

  useEffect(()=>{
    //console.log({item, entity});
    setEntity(item);
  }, [itemStr])

  useEffect(()=>{
    if(entityErrors.length>0){
      const timer = setTimeout(()=>setEntityErrors([]), 8000);
      return ()=>clearTimeout(timer);
    }
  }, [entityErrors])
  
  const clearErrors = () => {
    setEntityErrors([]);
  }

  const handleSubmit = (e) => {
    //console.log(e)
    e.preventDefault();
    e.stopPropagation();
    if (writeFetching) return;
    schema.validate(entity, {
      strict: false,
      abortEarly: false
    })
    .then(response => {
      onSubmit && onSubmit(entity);
    })
    .catch(errors => {
      //console.log({errors})
      setEntityErrors(errors.inner.map(item => ({path: item.path, message: item.message})));
    })
  };


  const handleCancel = (e, item) => {
    e.preventDefault();
    if (writeFetching) return;
    onCancel && onCancel(e, item);
  };

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    //console.log({name, value});
    setEntity(entity => immutable.set(entity, name, value));
  }

  useEffect(()=>{
    if(error){
      const timer = setTimeout(()=>setError(null), 5000)
      return ()=>clearTimeout(timer);
    }
  }, [error])

  useEffect(()=>{
    if(entity && item !== entity) {
      onChange && onChange(entity);
    }
  }, [entity])

  useImperativeHandle(ref, () => {
    return {
      submit: (e) => {
        handleSubmit(e);
      },
      resetItem: (item) => {
        setEntity(item);
      }
    };
  }, [entity, onSubmit]);

  return (
    <AbmFormContext.Provider value={{clearErrors, entityErrors}}>
      <Card elevation={0} style={{ overflow: 'visible' }}>
        <CardContent>
          {title && 
            <Grid container flexDirection="row" padding={1}>
              <Grid item flex={1}>
                <Typography variant="h5" component="div">
                  {title}
                </Typography>
              </Grid>
            </Grid>
          }
          <Box>
              <form ref={formRef} onSubmit={handleSubmit} >
                <Grid container padding={smMode ? 0 : 1} spacing={1}>
                  {children && children({onControlChange: handleInputChange, entity, entityErrors})}
                  <Grid item xs={12} display={error ? "block" : "none"}>
                    <Alert severity="warning" onClose={()=>{setError(null)}}>
                      {error}
                    </Alert>
                  </Grid>
                  {actionsBottom &&
                    <Grid xs={12} item paddingTop={2} display="flex" alignItems="center" alignSelf="end" >
                      <Grid item flex={1}></Grid>
                      <Actions {...{item, onSubmit, confirmLabel, handleSubmit, onCancel: handleCancel, cancelLabel}} />
                    </Grid>
                  }
                  <input id={submitId} type="submit" value="" style={{display: "none"}}/>
                </Grid>
              </form>
          </Box>
        </CardContent>
      </Card>
    </AbmFormContext.Provider>
  );
})

const Actions = ({item, onSubmit, confirmLabel, handleSubmit, onCancel, cancelLabel}) => {


  return (
    <>
      {((onSubmit && confirmLabel) || (onCancel && cancelLabel)) && 
        <div style={{width: "unset"}}>
          {onSubmit && confirmLabel && 
            <Button variant="contained" color="success" onClick={handleSubmit} type="submit" style={{marginRight: 16}}>
              {confirmLabel}
            </Button>
          }
          {onCancel && cancelLabel &&          
            <Button variant="outline" color="error" onClick={event => onCancel({event, item})}>
              {cancelLabel}
            </Button>
          }
        </div>
      }
    </>
  )
}