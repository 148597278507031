import { Form } from "../../components/abm/facturas/Form";
import { SearchForm } from "../../components/abm/facturas/SearchForm";
import * as yup from 'yup';
import { Abm } from "./_Abm";
import { AbmContext, useAbmComponent } from "./useAbmComponent";
import { useMemo, useState } from "react";
import { ModalFacturaRechazo, ModalLiquidacionesPagarFactura } from "../../components/modals";
import { ActionButton } from "../../components/helpers";
import { useFacturasCambiarEstado } from "../../components/abm/facturas/useFacturasCambiarEstado";
import { estadosFactura, roles } from "../../config/constants";
import { CancelIcon, CheckIcon, CoinIcon, DeleteIcon, DetailsIcon, EditIcon } from "../../components/icons";
import { State } from "../../components/abm/State";

export const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    numero: yup.string().label("Número").required(), //lo carga el usuario
    fecha_documento: yup.date().label("Fecha del comprobante").required(), //lo carga el usuario
    concepto: yup.string().label("Concepto").required(), //lo carga el usuario
    id_perceptor: yup.number().label("Perceptor").required(), //selecciona de una lista
    id_moneda: yup.number().label("Moneda").required(), //selecciona de una lista
    id_items: yup.array().of(yup.number()).required().min(1), //lo selecciona de una lista (multiple)
    importe: yup.number().label("Importe").required().positive(), //lo calculo sumando los items
    comprobante: yup.object().label("Comprobante").nullable().shape({}).required(),
    constancia_fiscal: yup.object().label("Constancia Fiscal").nullable().shape({}).required(),
    constancia_cbu: yup.object().label("Constancia de CBU").nullable().shape({}).required()
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    importe: yup.number().label("Importe").required().positive(),
    numero: yup.string().label("Número").required(),
    concepto: yup.string().label("Concepto").required(),
    id_perceptor: yup.number().label("Perceptor").required(),
    id_moneda: yup.number().label("Moneda").required(),
    fecha_documento: yup.date().label("Fecha del comprobante").required(),
    comprobante: yup.object().label("Comprobante").nullable().shape({}).required(),
    constancia_fiscal: yup.object().label("Constancia Fiscal").nullable().shape({}).required(),
    constancia_cbu: yup.object().label("Constancia de CBU").nullable().shape({}).required()
  })
});

const entity = "facturas";
const titleTable = "Facturas";
const itemName = "Factura";
const itemDescription = item => `${itemName} ${item?.numero || ''}`;
const columns = [
  {field: "perceptor", caption: "Perceptor"},
  {field: "fecha_documento", caption: "Fecha", width: 120},
  {field: "numero", caption: "Número", width: 120},
  {field: "importe", caption: "Importe", width: 120, align: "right"},
  {field: "estado", caption: "Estado", width: 260, align: "center", render: (row) => <State className={`facturas-${row.estado.replaceAll(" ", "_")}`}>{row.estado}</State> },
  {field: "_actions_", caption: "Acciones", width: 120}
];

export const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "perceptores/options")
    .then( data => {
      resolve({...data, name: "perceptores"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, `items/options_aprobados/${id || 0}`)
    .then( data => {
      resolve({...data, name: "items"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "monedas/options")
    .then( data => {
      resolve({...data, name: "monedas"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "designaciones/options")
    .then( data => {
      resolve({...data, name: "designaciones"});
    })
    .catch(reason => {
      reject(reason);
    })
  })
];

export const Facturas = ({abmKey}) => {

  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);

  const [showLiquidacionesPagarFactura, setShowLiquidacionesPagarFactura] = useState(false);
  const [showFacturaRechazo, setShowFacturaRechazo] = useState(false);

  const fnActions = ({goTo, handleActionRemove, getPermissions, claims}) => ({row, isSelected}) => {
    const {liquidar, details, edit, del, preaprobar, aprobar, rechazar} = getPermissions({row});
    const accionesLiq = claims.rol === roles.LIQUIDADOR 
      ? [
        {name: "aprobar", hide: !aprobar, caption: "", icon: <ActionButton Icon={CheckIcon} enabled={aprobar && isSelected} onClick={()=>fireCambiarEstado({nuevoEstado: "APROBADA", title: "Aprobar Factura", text: "aprobar"})}/>},
        {name: "rechazar", hide: !rechazar, caption: "", icon: <ActionButton Icon={CancelIcon} enabled={rechazar && isSelected} onClick={()=>setShowFacturaRechazo(true)}/>},
        {name: "liquidar", hide: !liquidar, caption: "", icon: <ActionButton Icon={CoinIcon} enabled={liquidar && isSelected} onClick={()=>setShowLiquidacionesPagarFactura(true)}/>},
      ]
      : [];
    const accionesAdmC = claims.rol === roles.ADMINISTRATIVO_CENTRAL
      ? [
        {name: "preaprobar", hide: !preaprobar, caption: "", icon: <ActionButton Icon={CheckIcon} enabled={preaprobar && isSelected} onClick={()=>fireCambiarEstado({nuevoEstado: "PRE-APROBADA", title: "Pre-aprobar Factura", text: "pre-aprobar"})}/>},
        {name: "rechazar", hide: !rechazar, caption: "", icon: <ActionButton Icon={CancelIcon} enabled={rechazar && isSelected} onClick={()=>setShowFacturaRechazo(true)}/>},
      ]
      : [];
    return [
      ...accionesLiq,
      ...accionesAdmC,
      {name: "details", hide: !details, caption: "", icon: <ActionButton Icon={DetailsIcon} enabled={details && isSelected} onClick={()=>goTo(`details/${row.id}`)}/>},
      {name: "edit", hide: !edit, caption: "", icon: <ActionButton Icon={EditIcon} enabled={edit && isSelected} onClick={()=>goTo(`edit/${row.id}`)}/>},
      {name: "del", hide: !del, caption: "", icon: <ActionButton Icon={DeleteIcon} colorEnabled="var(--bs-red)" enabled={del && isSelected} onClick={handleActionRemove}/>},    
    ]
  }  

  const abmComponent = useAbmComponent({fnActions, abmKey, loads, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, SearchFormComponent: SearchForm})
  const { loadDetails, keyState, tabs, loaded, selectedItem, showNotification, abmActions, updateItem, updateItemFromId} = abmComponent;
  const handleUpdateItem = (item) => {
    updateItem(item);
    loadDetails(item.id);
  }
  const handleUpdateItemFromId = (id) => {
    updateItemFromId(id);
    loadDetails(id);
  }
  const fireCambiarEstado = useFacturasCambiarEstado({selectedItem, abmActions, showNotification, updateItem: handleUpdateItem});
   
  return (
    <AbmContext.Provider value={{
      ...abmComponent, 
      setShowLiquidacionesPagarFactura,
      setShowFacturaRechazo,
      fireCambiarEstado
    }}>
      <ModalFacturaRechazo 
        showState={[showFacturaRechazo, setShowFacturaRechazo]} 
        selectedItem={selectedItem} 
        abmActions={abmActions} 
        showNotification={showNotification} 
        updateItem={handleUpdateItemFromId}
      />
      <ModalLiquidacionesPagarFactura 
        showState={[showLiquidacionesPagarFactura, setShowLiquidacionesPagarFactura]} 
        selectedItem={selectedItem} 
        abmActions={abmActions} 
        showNotification={showNotification} 
        updateItem={handleUpdateItem}
      />
      <Abm 
        keyState={keyState}
        tabs={tabs}
        title={titleTable}
        loading={!loaded}
      />
    </AbmContext.Provider>
  );
}