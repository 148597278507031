import { useAbmContext } from "../../views/abm/useAbmComponent"
import { DeleteIcon, SaveIcon } from "../icons";

export const AbmFormAddComponent = () => {
  const {
    form: {
      formData, 
      formItem, 
      schemaAdd, 
      FormComponent, 
      handleAddConfirm, 
      handleFormCancel
    }, 
    itemName
  } = useAbmContext();
  return <FormComponent
    data={formData}
    item={formItem}
    schema={schemaAdd} 
    title={`Añadir ${itemName}`}
    onConfirm={handleAddConfirm} 
    onCancel={handleFormCancel} 
    confirmLabel={<><SaveIcon size={18} color="white"/>Guardar</>} 
    cancelLabel={<><DeleteIcon size={18} color="white"/>Cancelar</>} 
  />
}