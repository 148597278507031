import { useAppContext } from "../../../../App";
import { authorize } from "../../../../Core";
import { estadosDesignacion, roles } from "../../../../config/constants";
import { useAbmContext } from "../../../../views/abm/useAbmComponent";
import { ActionButton } from "../../../helpers";
import { CheckIcon } from "../../../icons";

export const EnviarRevisionDesignacion = ({params}) => {
  const { setShowDesignacionEnviarRevision, fireCambiarEstado } = useAbmContext();
  const { claims: { rol } } = useAppContext();

  if(!authorize({rol, inRoles: [roles.GESTOR, roles.LIQUIDADOR, roles.ADMINISTRATIVO, roles.ADMINISTRATIVO_CENTRAL]})){
    return <></>
  }
  const enabled = rol === roles.LIQUIDADOR || rol === roles.GESTOR || rol === roles.ADMINISTRATIVO || rol == roles.ADMINISTRATIVO_CENTRAL;

  const handleClick = () => {
    if(params.conObservacion){
      setShowDesignacionEnviarRevision(true);
    }else{
      fireCambiarEstado({nuevoEstado: estadosDesignacion.EN_REVISION, title: "Enviar a Revisión", text: "enviar a revisión"});
    }
  }
  return <ActionButton iconButton enabled={enabled} Icon={CheckIcon} onClick={handleClick}/>
}
