import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAbmContext } from "../../../views/abm/useAbmComponent";
import { ControlSelect, ControlText, Decorator, ControlTags, ControlCheckbox, ControlDatePicker, ControlTextArea } from "../../helpers";
import { ControlSelect2 } from "../../helpers/ControlSelect2";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { ModalActividadesCreateItem } from "../../modals/ModalActividadesCreateItem";
import { ModalPerceptorAdd } from "../../modals/ModalPerceptoresAdd";
import { AbmForm } from "../AbmForm";
/*
fecha_realizacion datetime 
importe decimal(14,2) 
concepto varchar(500) 
fecha_aprobacion datetime 
id_perceptor int 
*/

export const FormItemPresupuesto = React.forwardRef(({
    item, 
    schema, 
    onCancel, 
    onConfirm, 
    confirmLabel = "Guardar", 
    cancelLabel = "Cancelar", 
    title, 
    errorState, 
    data = {}, 
    loads}, ref) => {

  const [showPerceptorAdd, setShowPerceptorAdd] = useState(false)
  const { selectedItem, abmActions, showNotification } = useAbmContext();

  const handleAgregarPerceptor = (event) => {
    event.preventDefault();
    setShowPerceptorAdd(true);
  }
  

  return (
    <>
      <AbmForm ref={ref} title={title} actionsTop={false} actionsBottom={false} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
        {({onControlChange, entity, entityErrors}) => {

          const handleUpdate = (data) => {
            loads(data);
            onControlChange({target: {name: "id_perceptor", value: parseInt(data.id)}});
          }

          return (
            <>
              <ModalPerceptorAdd showState={[showPerceptorAdd, setShowPerceptorAdd]} selectedItem={selectedItem} abmActions={abmActions} showNotification={showNotification} updateItem={handleUpdate} />
              <Grid item xs={12}> 
                <ControlSelect2 name="id_perceptor" label="Perceptor" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} options={data.perceptores || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
                <div 
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse"
                  }}
                >
                  <a href="#" onClick={handleAgregarPerceptor}>agregar perceptor</a>
                </div>
              </Grid>  
              <Grid item xs={12}> 
                <ControlSelect2 name="id_item_general" label="Item Presup. General" errors={entityErrors} data={entity} onChange={onControlChange} options={data.items_generales || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
              </Grid>                
              <Grid item xs={12}> 
                <ControlText name="importe" label="Importe" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
              </Grid> 
              <Grid item xs={12}> 
                <ControlSelect2 name="id_moneda" label="Moneda" errors={entityErrors} data={entity} onChange={onControlChange} options={data.monedas || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
              </Grid>   
              <Grid item xs={12}> 
                <ControlTextArea name="concepto" label="Concepto" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
              </Grid>  
              {!!entity.id &&
                <Grid item xs={12}> 
                  <ControlTextArea name="aclaracion" label="Comentarios" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
                </Grid>  
              }

            </>
          )
        }}
      </AbmForm>
    </>
  );
})