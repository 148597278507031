import { Form } from "../../components/abm/planeamientos/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { SearchForm } from "../../components/abm/planeamientos/SearchForm";
import { useMemo } from "react";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción").optional(),
    cerrado: yup.bool().required().default(false).label("Cerrado"),
    id_periodo: yup.number().label("Período").required()
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción").optional(),
    cerrado: yup.bool().required().default(false).label("Cerrado"),
    id_periodo: yup.number().label("Período").required()
  })
});

const entity = "planeamientos";
const titleTable = "Planeamientos";
const itemName = "Planeamiento";
const itemDescription = item => `${itemName} ${item?.nombre || ''}`;
const columns = [
  {field: "nombre", caption: "Nombre"},
  {field: "periodo", caption: "Período"},
  {field: "descripcion", caption: "Descripción", width: 150},
  {field: "carrado", caption: "Cerrado", width: 150},
  {field: "importe_asignado", caption: "Importe asignado", width: 150},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "periodos/options")
    .then( data => {
      resolve({...data, name: "periodos"});
    })
    .catch(reason => {
      reject(reason);
    })
  })
];

export const Planeamientos = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({loads, abmKey, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, SearchFormComponent: SearchForm})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}