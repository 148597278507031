import { Form } from "../../components/abm/monedas/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { DeleteIcon, EditIcon } from "../../components/icons";
import { ActionButton } from "../../components/helpers";
import { roles } from "../../config/constants";
import { AbmWithContext } from "./_AbmWithContext";
import { useMemo } from "react";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().label("Nombre").required(),
    abreviatura: yup.string().label("Abreviatura").required(),
    simbolo: yup.string().label("Símbolo").required(),
    formato: yup.string().label("Formato"),
    cotizacion: yup.number().nullable().required().label("Cotización"),
    principal: yup.bool().nullable().required().label("Principal"),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().label("Nombre").required(),
    abreviatura: yup.string().label("Abreviatura").required(),
    simbolo: yup.string().label("Símbolo").required(),
    formato: yup.string().label("Formato"),
    cotizacion: yup.number().nullable().required().label("Cotización"),
    principal: yup.bool().nullable().required().label("Principal"),
  })
});

const entity = "monedas";
const titleTable = "Monedas";
const itemName = "Moneda";
const itemDescription = item => `${itemName} ${item?.numero || ''}`;
const columns = [
  {field: "nombre", caption: "Nombre"},
  //{field: "abrev.", caption: "Abreviatura", width: 80},
  //{field: "simbolo", caption: "Simbolo", width: 80},
  //{field: "formato", caption: "Formato", width: 80},
  {field: "cotizacion", caption: "Cotización en USD", width: 150},
  {field: "fecha_actualizacion", caption: "Actualización", width: 120},
  {field: "facturas", caption: "Facturas", width: 80, render: (row) => row.facturas.length},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const fnActions = ({goTo, handleActionRemove, getPermissions}) => ({row, isSelected}) => {
  const {edit, del} = getPermissions({row});
  return [
    {name: "edit", caption: "", icon: <ActionButton enabled={isSelected && edit} Icon={EditIcon} onClick={()=>goTo(`edit/${row.id}`)}/>},
    {name: "del", caption: "", icon: <ActionButton enabled={isSelected && del} Icon={DeleteIcon} colorEnabled="danger" onClick={handleActionRemove}/>},
  ];
}

export const Monedas = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, fnActions, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}