import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "../../components/abm/liquidaciones/Form";
import { loads, getSchemas } from "../../views/abm/Liquidaciones";
import { DeleteIcon, SaveIcon } from "../icons";


export const ModalLiquidacionesPagarFactura = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, updateItem}) => {
  const { schemaAdd } = useMemo(() => getSchemas(), []);
  const [ formData, setFormData ] = useState({});
  const [ show, setShow ] = showState;
  const formRef = useRef();

  const loadExtraData = (id) => new Promise((resolve, reject) => {
    !loads && resolve({});
    Promise.all(
      loads.map(loadFn => {
        loadFn(abmActions, id)
          .then(data => {
            setFormData(formData => ({...formData, [data.name]: data.results}));
          })
      })
    )
    .then(result => {
      resolve()
    })
    .catch(error=>{
      reject();
    })
  });

  useEffect(()=>{
    if(show){
      loadExtraData().then(()=>{
        formRef.current.resetItem({
          id_factura: selectedItem?.id,
        });
      });
    }
  }, [show]);

  const handleCancel = (event) => {
    setShow(false);
    event?.preventDefault();
    event?.stopPropagation();
  }
  
  const handleConfirm = (data) => {
    abmActions.create(data, "liquidaciones").then(result => {
      abmActions.getAll({id: selectedItem.id}, "facturas")
        .then(data => {
          if(data.results.length > 0){
            updateItem(data.results[0]);
          }
        })
      handleCancel();
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return (
    <Dialog open={show} onClose={handleCancel} PaperProps={{sx: {overflow: "visible"}}}>
      <DialogTitle>Liquidar factura</DialogTitle>
      <DialogContent sx={{overflow: "visible"}}>
        <Form 
          ref={formRef}
          item={{
            id_factura: selectedItem?.id,
          }}
          data={formData}
          schema={schemaAdd} 
          onConfirm={handleConfirm} 
          actionsBottom={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
          Guardar
        </Button>      
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancelar
        </Button>   
      </DialogActions>
    </Dialog>
  )
}