import { useEffect } from "react";
import { useAppContext } from "../App";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  useEffect(()=>{
    appContext.singOut();
    navigate("/", {replace: true});
  }, [])
  return (
    <></>
  )
}
