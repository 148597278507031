import { useRef } from "react";
import { estadosItem, roles } from "../../config/constants";
import { FormItemPresupuestoRechazo } from "../abm/actividades/FormItemPresupuestoRechazo";
import * as yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAppContext } from "../../App";

const schema = yup.object().shape({
  id: yup.number().required(),
  estado: yup.string().label("Estado").required(), 
  motivo_rechazo: yup.string().label("Motivo").required(), 
});

export const ModalItemPresupuestoRechazo = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, loadDetails, idItemPresupuesto}) => {

  const [show, setShow] = showState;
  const { claims } = useAppContext();
  const formRef = useRef();

  const handleCancel = (event) => {
    setShow(false);
    event?.preventDefault();
    event?.stopPropagation();
  }
   
  const handleConfirm = (data) => {
    abmActions.update(data, idItemPresupuesto, "items/estado").then(result => {
      loadDetails(selectedItem.id);
      setShow(false);
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return (
    <Dialog open={show} onClose={handleCancel} PaperProps={{sx: {overflow: "visible"}}}>
      <DialogTitle>Rechazar item de presupuesto</DialogTitle>
      <DialogContent sx={{overflow: "visible"}}>
        <FormItemPresupuestoRechazo 
          ref={formRef}
          item={{
            id: idItemPresupuesto,
            estado: claims.rol === roles.DIRECTOR_CENTRO ? estadosItem.OBSERVADO_LOCAL : estadosItem.OBSERVADO
          }}
          schema={schema} 
          onConfirm={handleConfirm} 
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
          Guardar
        </Button>      
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancelar
        </Button>   
      </DialogActions>
    </Dialog>
  )
}