import { Form } from "../../components/abm/sub_proyectos/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { SearchForm } from "../../components/abm/ambitos/SearchForm";
import { useMemo } from "react";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción"),
    id_proyecto: yup.number().label("Proyecto").required(),
    importe_asignado: yup.number().positive().label("Importe asignado").required(),
    importe_final: yup.number().positive().label("Importe final").required(),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().max(100).label("Nombre").required(), 
    descripcion: yup.string().max(500).label("Descripción"),
    id_proyecto: yup.number().label("Proyecto").required(),
    importe_asignado: yup.number().positive().label("Importe asignado").required(),
    importe_final: yup.number().positive().label("Importe final").required(),
  })
});
const entity = "sub_proyectos";
const titleTable = "Sub-Proyectos";
const itemName = "Sub-proyecto";
const itemDescription = item => `${itemName} ${item?.nombre || ''}`;
const columns = [
  {field: "nombre", caption: "Nombre"},
  {field: "descripcion", caption: "Descripción", width: 150},
  {field: "centro", caption: "Centro", width: 120},
  {field: "ambito", caption: "Ambito", width: 120},
  {field: "programa", caption: "Programa", width: 120},
  {field: "proyecto", caption: "Proyecto", width: 120},
  {field: "importe_asignado", caption: "Imp. asignado", width: 130},
  {field: "importe_final", caption: "Imp. final", width: 130},
  {field: "disponible", caption: "Imp. Disponible", width: 130},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "proyectos/options")
    .then( data => {
      resolve({...data, name: "proyectos"});
    })
    .catch(reason => {
      reject(reason);
    })
  })
];

export const SubProyectos = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, loads, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, SearchFormComponent: SearchForm})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}