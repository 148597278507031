import { useEffect, useMemo, useRef, useState } from "react";
import { getSchemas, loads } from "../../views/abm/Facturas";
import { estadosItem } from "../../config/constants";
import { Form } from "../abm/facturas/Form";
import * as yup from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const ModalActividadesCreateFactura = ({showState = [false, ()=>{}], selectedItem, abmActions, showNotification, loadDetails, params}) => {
  const { schemaAdd } = useMemo(() => getSchemas(), []);
  const [ show, setShow ] = showState;
  const [ formData, setFormData ] = useState(null);
  const formRef = useRef();

  const loadExtraData = (id) => new Promise((resolve, reject) => {
    !loads && resolve({});
    Promise.all(
      loads.map(loadFn => {
        loadFn(abmActions, id)
          .then(data => {
            setFormData(formData => ({...formData, [data.name]: data.results}));
          })
      })
    )
    .then(result => {
      resolve()
    })
    .catch(error=>{
      reject();
    })
  });

  useEffect(()=>{
    if(show){
      loadExtraData();
    }else{
      setFormData(null);
    }
  }, [show]);

  const handleCancel = (event) => {
    setShow(false);
    event?.preventDefault();
    event?.stopPropagation();
  }
   
  const handleConfirm = (data) => {
    abmActions.create(data, "facturas").then(result => {
      loadDetails(selectedItem.id);
      setShow(false);
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }

  return (
    <Dialog open={show} onClose={handleCancel} keepMounted={false} maxWidth="lg">
      <DialogTitle>Cargar factura para item de presupuesto</DialogTitle>
      <DialogContent>
        <Form 
          ref={formRef}
          item={{
            id_perceptor: params.id_perceptor,
            id_moneda: params.id_moneda,
            concepto: params.concepto,
            id_items: [params.id]
          }}
          data={formData}
          schema={schemaAdd} 
          onConfirm={handleConfirm}
          actionsBottom={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={e => formRef.current.submit(e)}>
          Guardar
        </Button>      
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancelar
        </Button>   
      </DialogActions>
    </Dialog>
  )
}