import { Grid } from "@mui/material";
import React from "react";
import { ControlCheckbox, ControlText } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";


export const Form = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState, data = {}}) => {

  return (
    <AbmForm title={title} actionsTop={false} actionsBottom={true} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <>
          <Grid item xs={12}>
            <ControlText name="nombre" label="Nombre" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>  
          <Grid item xs={12}>
            <ControlText name="abreviatura" label="Abreviatura" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>  
          <Grid item xs={12}>
            <ControlText name="simbolo" label="Símbolo" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>  
          <Grid item xs={12}>
            <ControlText name="formato" label="Formato" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
          <Grid item xs={12}> 
            <ControlText name="cotizacion" label="Cotización" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
          </Grid>
          <Grid item xs={12}>
            <ControlCheckbox name="principal" label="Principal" data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
          </Grid>
        </>
      )}
    </AbmForm>
  );
}