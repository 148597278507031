import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { ControlWrapper } from "./ControlWrapper";
const objectPath = require("object-path");

export const ControlSelect = ({name, label, placeholder, onChange, options, data, start, end, errors = [], Wrapper = ControlWrapper, ...moreOptions }) => {
  const value = data ? objectPath(data).get(name, "") : "";
  
  return (
    <Wrapper errors={errors} name={name} end={end} start={start} label={label}>
      <Form.Select id={name} name={name} {...moreOptions} onChange={onChange} value={value} className="flex-grow-1 h-100" style={{border: "0px solid white"}}>
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Wrapper>
  )
}