import { createContext, useContext, useEffect, useState } from "react"

export const Notifications = ({children}) => {

  const [ notification, setNotification ] = useState({type: "", message: ""})

  useEffect(()=>{
    if(notification.message){
      const timer = setTimeout(()=>setNotification({type: "", message: null}), 5000)
      return ()=>clearTimeout(timer);
    }
  }, [notification])

  const showNotification = ({type = "error", message = ""})=>{
    setNotification({type, message});
  }

  return (
    <NotificationsContext.Provider value={{showNotification}}>
      {notification.message && 
        <div className={`notification-message ${notification.type}`}>{notification.message}</div>
      }
      {children}
    </NotificationsContext.Provider>
  )
}

const NotificationsContext = createContext();
export const useNotificationsContext = () => useContext(NotificationsContext);