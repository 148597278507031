import { useCallback } from "react";
import Swal from "sweetalert2";

export const useDesignacionesCambiarEstado = ({selectedItem, abmActions, showNotification, updateItem}) => {



  const handleConfirm = useCallback((data) => {
    abmActions.update(data, selectedItem.id, "designaciones/estado").then(result => {
      abmActions.getAll({id: selectedItem.id}, "designaciones")
        .then(data => {
          if(data.results.length > 0){
            updateItem(data.results[0]);
          }
        })
    }).catch(error=>{
      error.json().then(result => {
        showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error creando registro"});
      })
    });
  }, [selectedItem?.id, updateItem, showNotification]);

  const fire = useCallback(({nuevoEstado, title, text}) => {
    Swal.fire({
      icon: 'question',
      title,
      html: `Realmente desea <b>${text}</b> la designación.<br/>Esta operación no podrá deshacerse.`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirm({id: selectedItem.id, estado: nuevoEstado});
      }
    }) 
  }, [selectedItem?.id, handleConfirm]);

  return fire;
}