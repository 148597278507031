import React, { useEffect, useRef } from "react";
import { ControlWrapper } from "./ControlWrapper";
const objectPath = require("object-path");

export const ControlTextArea = ({name, label, placeholder, onChange, data, start, end, errors = [], autoFocus, Wrapper = ControlWrapper, rows = 5, errorPlacement = "right", ...options }) => {
  const inputRef = useRef();
  const value = data ? objectPath(data).get(name, "") : "";

  useEffect(()=>{
    if(autoFocus){
      var timer = setTimeout(()=>{
        inputRef.current.focus();
      }, 200);
      return ()=>clearTimeout(timer);
    }
  }, [autoFocus]);

  return (
    <Wrapper name={name} errors={errors} end={end} start={start} label={label} errorPlacement={errorPlacement}>
      <textarea ref={inputRef} id={name} placeholder={placeholder} name={name} value={value} rows={rows} onChange={onChange} className="flex-grow-1 h-100 px-2" style={{border: "0px solid white", width: "100%", maxWidth: "100%"}} {...options}/>
    </Wrapper>
  )
}