import React, { useContext, useState } from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { useThemeProviderContext } from "../../mui/Theme";
import { DarkModeIcon, LightModeIcon, MenuIcon, PaletteIcon, PersonIcon } from "../icons";
import { ThemePicker, PrivateLayoutContext, UserProfile } from "./";

export const Header = () => {

  const [ showThemePicker, setShowThemePicker ] = useState(null);
  const [ showUserProfile, setShowUserProfile ] = useState(null);
  const { headerContent, toggleMenu, openMenu } = useContext(PrivateLayoutContext);
  const { selectThemeMode, themeMode, selectThemeColor, themeColor } = useThemeProviderContext();

  const handleUserProfile = (event) => {
    setShowUserProfile(event.target);
  }

  const handleUserProfileClose = () => {
    setShowUserProfile(null)
  }

  const handleToggleThemeMode = () => {
    if(themeMode === "dark"){
      selectThemeMode("light");
    }
    if(themeMode === "light"){
      selectThemeMode("dark");
    }
  }

  const handleShowSelectionColor = (event) => {
    setShowThemePicker(event.target);
  }

  const handleThemePickerConfirm = (theme) => {
    selectThemeColor(theme);
    setShowThemePicker(null);
  }

  const handleThemePickerCancel = () => {
    setShowThemePicker(null);
  }

  return (
    <>
        <AppBar position="absolute" open={openMenu} style={{zIndex: openMenu ? 999 : 1001}}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
            style={{
              paddingLeft: openMenu ? '260px' : undefined
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleMenu}
              sx={{
                marginRight: '36px',
                ...(openMenu && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {headerContent}
            </Typography>
            <IconButton color="inherit" onClick={handleShowSelectionColor}>
              <PaletteIcon htmlColor={themeColor.color}/>
            </IconButton>
            <IconButton color="inherit" onClick={handleToggleThemeMode}>
              {themeMode === "dark" 
                ? <LightModeIcon />
                : <DarkModeIcon />
              }
            </IconButton>
            <IconButton color="inherit" onClick={handleUserProfile}>
              <PersonIcon />
            </IconButton>
            <ThemePicker anchorEl={showThemePicker} onConfirm={handleThemePickerConfirm} onCancel={handleThemePickerCancel} />
            <UserProfile anchorEl={showUserProfile} onClose={handleUserProfileClose} />
          </Toolbar>
        </AppBar>
    </>
  )
}