import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { toLog } from "../../utils/Helpers";

export const TableActions = ({actions, row}) => {

  const visibleActions = useMemo(() => actions.filter(a => !a.hide), [actions])
  return (
    <td>
      <Grid container flexDirection="row" justifyContent="end" flexWrap="nowrap" paddingX={1}>
        {visibleActions.map(({name, onClick, caption, icon}) => (
          <a 
            key={`action${name}${row.id}`}
            name={`action${row.id}`} 
            className="action"
            href={name}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onClick && onClick({event, row});
            }}
            style={{display: "flex", alignItems: "center"}}
          >
            {icon} <span style={{paddingLeft: 6}}>{caption}</span>
          </a>                      
        ))}
      </Grid>
    </td>
  )
}