import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAbm } from "../../../services/abm";
import { ControlSelect2, ControlText } from "../../helpers";
import { SearchFormWrapper } from "../SearchFormWrapper";
import { objectEmpty } from "../../../utils/Helpers";
import { getQueryData } from "../../../Core";

export const SearchForm = ({initSearch = objectEmpty, onSearchChange}) => {
  const [data, setData] = useState({perceptores: []}); 
  const { getAll } = useAbm({entity: ""});

  const [searchParams] = useSearchParams(); 
  const [searchData, setSearchData] = useState(() => (getQueryData(searchParams))); 

  //console.log(searchData)
  useEffect(()=>{
    setSearchData(initSearch)
  }, [initSearch])
  
  useEffect(()=>{
    getAll({}, "periodos/options")
      .then(response => {
        setData(data => ({...data, periodos: response.results}));
      })
  }, [getAll])
  
  const handleControlChange = ({target: {name, value}}) => {
    const newData = {...searchData, [name]: value};
    setSearchData(newData)
    onSearchChange(newData);
  }

  return (
    <>
      <ControlText name="term" label="" placeholder="Buscar" errors={[]} data={searchData} onChange={handleControlChange} Wrapper={SearchFormWrapper}  />
      <ControlSelect2 name="id_periodo" placeholder="Período" data={searchData} onChange={handleControlChange} options={data?.periodos} isMulti={false} closeMenuOnSelect={true}  Wrapper={({children})=><div style={{width:200}}>{children}</div>}/>    
    </>
  )
}