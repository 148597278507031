import { Form } from "../../components/abm/periodos/Form";
import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { useMemo } from "react";

const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    nombre: yup.string().max(100).label("Nombre").required(), 
    cerrado: yup.bool().required().default(false).label("Cerrado")
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    nombre: yup.string().max(100).label("Nombre").required(), 
    cerrado: yup.bool().required().default(false).label("Cerrado")
  })
});

const entity = "periodos";
const titleTable = "Períodos";
const itemName = "Periodo";
const itemDescription = item => `${itemName} ${item?.nombre || ''}`;
const columns = [
  {field: "nombre", caption: "Nombre"},
  {field: "cerrado", caption: "Cerrado", width: 150},
  {field: "_actions_", caption: "Acciones", width: 80}
];

const loads = [
];

export const Periodos = ({abmKey}) => {
  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({loads, abmKey, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form})
  return (
    <>
      <AbmWithContext abmComponent={abmComponent}      />
    </>
  );
}