import { Form } from "../../components/abm/designaciones/Form";

import * as yup from 'yup';
import { useAbmComponent } from "./useAbmComponent";
import { AbmWithContext } from "./_AbmWithContext";
import { useEffect, useMemo, useState } from "react";
import { useDesignacionesCambiarEstado } from "../../components/abm/designaciones/useDesignacionesCambiarEstado";
import { ModalDesignacionEnviarRevision, ModalDesignacionRechazo } from "../../components/modals";
import { SearchForm } from "../../components/abm/designaciones/SearchForm";

export const getSchemas = () => ({
  schemaAdd: yup.object().shape({
    id_perceptor: yup.number().label("Perceptor").required().min(1, "${label}: Debe seleccionar un item."),
    id_actividad: yup.number().label("Actividad").required().min(1, "${label}: Debe seleccionar un item."),
    cuit: yup.string().max(45).label("CUIT").required(),
    fecha: yup.date().label("Fecha del documento").required(),
    objeto: yup.string().max(180).label("Objeto"),
    fechas: yup.string().max(60).label("Fechas"),
    lugar: yup.string().max(100).label("Lugar"),
    items_designacion: yup.array().required().min(1).max(4).label("Items"),
    retencion: yup.number().label("Retencion"),
    maximo_avion: yup.number().label("Importe máximo en concepto de avión").required().min(0),
    maximo_alojamiento: yup.number().label("Importe máximo en concepto de alojamiento").required().min(0),
    maximo_taxi: yup.number().label("Importe máximo en concepto de taxi").required().min(0),
    maximo_manutencion: yup.number().label("Importe máximo en concepto de manutención").required().min(0),
  }),
  schemaEdit: yup.object().shape({
    id: yup.number().required(),
    id_perceptor: yup.string().label("Perceptor").required(),
    id_actividad: yup.string().label("Actividad").required(),
    cuit: yup.string().max(45).label("CUIT").required(),
    fecha: yup.date().label("Fecha del documento").required(),
    objeto: yup.string().max(180).label("Objeto"),
    fechas: yup.string().max(60).label("Fechas"),
    lugar: yup.string().max(100).label("Lugar"),
    items_designacion: yup.array().required().min(1).max(4).label("Items"),
    retencion: yup.number().label("Retencion"),
    maximo_avion: yup.number().label("Importe máximo en concepto de avión").required().min(0),
    maximo_alojamiento: yup.number().label("Importe máximo en concepto de alojamiento").required().min(0),
    maximo_taxi: yup.number().label("Importe máximo en concepto de taxi").required().min(0),
    maximo_manutencion: yup.number().label("Importe máximo en concepto de manutención").required().min(0),
  })
});

const entity = "designaciones";
const titleTable = "Designaciones";
const itemName = "Designación";
const itemDescription = item => `${itemName} ${item?.objeto || ''}`;
const columns = [
  {field: "perceptor", caption: "Perceptor", width: 120},
  {field: "objeto", caption: "Objeto", width: 120},
  {field: "actividad", caption: "Actividad"},
  {field: "total", caption: "Total", width: 80},
  {field: "estado", caption: "Estado", width: 80},
  {field: "_actions_", caption: "Acciones", width: 80}
];

export const loads = [
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, "actividades/options")
    .then( data => {
      resolve({...data, name: "actividades"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
  ({getAll}, id) => new Promise((resolve, reject) => {
    getAll({}, `perceptores/options`)
    .then( data => {
      resolve({...data, name: "perceptores"});
    })
    .catch(reason => {
      reject(reason);
    })
  }),
];

export const Designaciones = ({abmKey}) => {

  const { schemaAdd, schemaEdit } = useMemo(() => getSchemas(), []);
  const abmComponent = useAbmComponent({abmKey, loads, schemaAdd, schemaEdit, entity, titleTable, itemName, itemDescription, columns, FormComponent: Form, SearchFormComponent: SearchForm});
  const [showDesignacionRechazo, setShowDesignacionRechazo] = useState(false);
  const [showDesignacionEnviarRevision, setShowDesignacionEnviarRevision] = useState(false);

  const { loadDetails, selectedItem, showNotification, abmActions, updateItem, updateItemFromId} = abmComponent;

  useEffect(()=>{
    console.log(selectedItem)
  }, [selectedItem])
  const handleUpdateItem = (item) => {
    updateItem(item);
    loadDetails(item.id);
  }
  const handleUpdateItemFromId = (id) => {
    updateItemFromId(id);
    loadDetails(id);
  }
  const fireCambiarEstado = useDesignacionesCambiarEstado({selectedItem, abmActions, showNotification, updateItem: handleUpdateItem});
  
  return (
    <>
      <AbmWithContext abmComponent={{
        ...abmComponent,
        fireCambiarEstado,
        setShowDesignacionRechazo,
        setShowDesignacionEnviarRevision,
      }}>
        <ModalDesignacionEnviarRevision
          showState={[showDesignacionEnviarRevision, setShowDesignacionEnviarRevision]} 
          selectedItem={selectedItem} 
          abmActions={abmActions} 
          showNotification={showNotification} 
          updateItem={handleUpdateItemFromId}
        />
        <ModalDesignacionRechazo
          showState={[showDesignacionRechazo, setShowDesignacionRechazo]} 
          selectedItem={selectedItem} 
          abmActions={abmActions} 
          showNotification={showNotification} 
          updateItem={handleUpdateItemFromId}
        />
      </AbmWithContext>

    </>
  );
}