import React from 'react';
import { Row, Col, Form, Container } from "react-bootstrap";
import { useState, useRef } from "react";
import { useAppContext } from "../App";
import { useLogin } from "../services/login";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useNotificationsContext } from '../components/layout-mui';

export const LoginSide = () => {
  
  const appContext = useAppContext();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const login = useLogin();
  const { showNotification } = useNotificationsContext();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = ({target: {name, value}}) => {
    setFormData(data => ({...data, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("hacer login")
    login.send(formData)
      .then(response => {
        appContext.singIn(response);
        navigate("home");
      })
      .catch(error => {
        console.log("error")
        error.json().then(result => {
          console.log(result)
          showNotification({message: result.messages.map(m => <div className="notification-item">{m}</div>) || "Error editando registro"});
        })
      });
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          /*backgroundImage: 'url(https://source.unsplash.com/random)',*/
          backgroundImage: 'url(/assets/images/bg-1.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Ingresar
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )

}